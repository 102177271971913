import React from "react";
import {ActionButton, Surface, Spacing} from '@able/react';
import InfoIcon from "../../images/icon-info-blue.svg";
import iconError from "../../images/icon-error.svg";
import * as analytics from '../../analytics-utils';
import * as constants from "../../presenters/Constants";
import { openInNewTab } from "../../utils.js";

let analyticsObj = null;

export default function BannerMessage(props) {
  const {icon, title, message, heroText, heroLink} = props.bannerMessage;
  const isError = isErrorIcon(icon);
  let bannerStatus = getBannerStatus(isError)
  let wrapperClassName = getWrapperClassName(isError);

  analyticsObj = props.analyticsObj;
  createEdgarBannerObjForOmniture(analyticsObj.orderDetails.referenceCode, title, message);

  return (
  <React.Fragment>
    <Surface className={wrapperClassName} variant="SurfaceHigh">
        <div className="banner-body-td">
          <div>{getIcon(isError)}</div>
          <Spacing left="spacing2x" />
          <div>
          {title && getBannerHeader(title)}
          {message && getBannerDescription(message)}
        </div>
        </div>
        <div>
        {heroText && getCtaButton(heroText, heroLink)}
        </div>
      </Surface>
  </React.Fragment>);
}

function createEdgarBannerObjForOmniture(referenceCode, bannerHeader, bannerDescription) {
  let eventObj = null;
  let bannerObj = null
  bannerObj = {
    "bannerHeader": bannerHeader,
    "bannerDescription": bannerDescription
  }
  if (referenceCode) 
    eventObj = analytics.createEventObj('error', 'Business', referenceCode, bannerHeader);
  analytics.createDateLayerObj(analyticsObj.pageObj, analyticsObj.applicationObj, eventObj, bannerObj);
}

function addClickEventToEdgarOmnitureObject(eventAction) {
  analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, eventAction);
}

function isErrorIcon(icon) {
  return icon !== 'BLUE';
}

function getCtaButton(heroText, heroLink) {
  // do not open new tab if herolink is falsy like ''.
  heroLink = heroLink ||  null; 

  return (
    <ActionButton
      className="banner-cta-btn"
      element="button"
      variant="LowEmphasis"
      onClick={e => {
        openInNewTab(e, heroLink)
        addClickEventToEdgarOmnitureObject(heroText)
      }}
      label={heroText}
      tabIndex="0"
    />
  );
}

function getBannerDescription(message) {
  return <p className="banner-subtext-td" tabIndex="0">{message}</p>;
}

function getBannerHeader(title) {
  return <h2 className="banner-message-content-header-td" tabIndex="0">{title}</h2>;
}

function getIcon(isError) {
  return (
    <div>
      {isError ? <img src={iconError} alt="error" style={{minWidth: 24+'px', minHeight: 24+'px'}}/> : <img src={InfoIcon} alt="information" style={{minWidth: 24+'px', minHeight: 24+'px'}}/>}
    </div>
  );
}

function getBannerStatus(isError) {
    return isError ? 'error' : 'info';
}

function getWrapperClassName(isError) {
  return `banner-content-td-${isError? 'red' : 'blue'}`;
}
