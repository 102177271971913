import React, { Component } from 'react'
import { ActionButton } from '@able/react';

import s from './styles'
import infoIcon from "../../images/icon-info-blue.svg";
class TimelineEvent extends Component {

  mergeNotificationStyle(iconColor, orientation) {
    const iconColorStyle = iconColor
      ? { ...s.eventType, ...{ color: iconColor, borderColor: iconColor } }
      : s.eventType
    const leftOrRight =
      orientation === 'right'
        ? { ...s['eventType--right'] }
        : { ...s['eventType--left'] }
    return { ...iconColorStyle, ...leftOrRight }
  }

  timeStyle() {
    return this.showAsCard() ? s.time : { ...s.time }
  }

  showAsCard() {
    const { container } = this.props
    return container === 'card'
  }

  containerStyle() {
    const { style } = this.props
    const containerStyle = { ...s.eventContainer, ...style }
    return this.showAsCard() ? { ...s.card, ...containerStyle } : containerStyle
  }

  toggleStyle() {
    const { container, cardHeaderStyle, collapsible } = this.props
    const messageStyle =
      container === 'card' ? { ...s.cardTitle, ...cardHeaderStyle } : {}
    return collapsible ? { ...s.toggleEnabled, ...messageStyle } : messageStyle
  }

  renderChildren() {
    const {orderCompleteBorder} = this.props;
    return (
      <div style={{ ...s.message, ...orderCompleteBorder}} className="timeline-content-td">
        {this.props.children}
        <div style={s.messageAfter} />
      </div>
    )
  }

  render() {
    const {
      createdAt,
      title,
      subtitle,
      iconStyle,
      buttons,
      icon,
      iconColor,
      subtitleStyle,
      orientation,
      onClick,
      onIconClick,
      classNameBody,
      lineStyle={},
      className = "",
      lineColor,
      nbnHealthCheckDetail,
      accessibleTitle,
    } = this.props
    const leftOrRightEventStyling =
      orientation === 'right'
        ? { ...s['event--right'] }
        : { ...s['event--left'] }
    const leftOrRightButtonStyling =
      orientation === 'left'
        ? { ...s['actionButtons--right'] }
        : { ...s['actionButtons--left'] }

    let lineAppearance = { ...s['containerBefore--left'] }
      lineAppearance = lineColor ? { ...lineAppearance, background: lineColor } : lineAppearance

    let a18yProps = {};
    if (accessibleTitle) {
      a18yProps = {
        tabIndex: "0",
        role: "subheading"
      }
    }
    return (
      <div style={{ ...s.event, ...leftOrRightEventStyling }} className={`order-timeline-event ${className}`}>
        <div style={{ ...s.containerBefore, ...lineAppearance, ...lineStyle }} className="timeline-progress-line"/>
        <div style={this.containerStyle()} {...{ onClick, className: classNameBody }}>
          <div style={s.eventContainerBefore} />
          <div style={this.toggleStyle()}>
            <h2 className="milestone-title" {...a18yProps}>{title}</h2>
          </div>
        </div>
        <div style={this.mergeNotificationStyle(iconColor, orientation)} className="order-timeline-icon">
          <span style={{ ...s.eventStatusIcon, ...iconStyle }} onClick={onIconClick}>{icon}</span>
        </div>
        <div style={this.containerStyle()} {...{ onClick, className: classNameBody }}>
          <div style={s.eventContainerBefore} />
          <div style={this.toggleStyle()}>
            {createdAt && <div style={this.timeStyle()} className="milestone-date">{createdAt}</div>}
            { nbnHealthCheckDetail?.nbnHealthCheckLinkLabel && 
              <div className="nbn-health-check-wrapper">
                <div className="nbn-health-check-info-icon">
                  <img src={infoIcon} alt="Info Icon" />
                </div>
                <div className="nbn-health-check-message">
                  <p>{nbnHealthCheckDetail.nbnHealthCheckMessage}</p>
                  <ActionButton
                    element="button"
                    variant="LowEmphasis"
                    label={nbnHealthCheckDetail.nbnHealthCheckLinkLabel}
                    onClick={() => {
                      window.open(nbnHealthCheckDetail.nbnHealthCheckUrl, '_blank')
                    }}
                  />
                </div>
              </div>
            }
            {subtitle && (
              <div style={{ ...s.subtitle, ...subtitleStyle }}>{subtitle}</div>
            )}
            <div style={{ ...s.actionButtons, ...leftOrRightButtonStyling }}>
              {buttons}
            </div>
          </div>
          {this.props.children && this.renderChildren()}
        </div>
        <div style={s.eventAfter} />
      </div>
    )
  }
}

export default TimelineEvent
