import React from "react";
import SiteHeader from '../components/SiteHeader';
import SiteFooter from '../components/SiteFooter';
import OrderDetailsTop from '../components/OrderDetailsTop';
import OrderDetailsBody from '../components/OrderDetailsBody';
import * as constants from "../../presenters/Constants";
import * as analytics from '../../analytics-utils';

export default class OrderDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = { orderDetails: this.props.orderDetails, accessToken: this.props.orderDetails.accessToken };
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(tabGroup) {
        this.setState((state) => {
            const newState = {...state};

            // remove current active tab and set new active tab
            newState.orderDetails.timelineGroups.forEach(timelineGroup => {
                if(timelineGroup.isSelected) timelineGroup.isSelected = false;
                if(timelineGroup.timelineGroupFamily === tabGroup.timelineGroupFamily) timelineGroup.isSelected = true;
            });
            return newState
        });
    }

    render() {
        const orderDetails = this.state.orderDetails;
        {this.addOmnitureObjectonPageLoad()}
        const analyticsObj = {
            pageObj: this.pageObj,
            applicationObj: this.applicationObj,
            orderDetails: orderDetails
        }
        return <div className="order-details-page">
            <SiteHeader accessToken={this.state.accessToken} orderDetails={orderDetails} location={this.props.location.pathname}/>
            <OrderDetailsTop orderDetails={orderDetails}/>
            <OrderDetailsBody orderDetails={orderDetails} handleTabChange={this.handleTabChange} analyticsObj={analyticsObj}/>
            <SiteFooter />
        </div>
    }

    addOmnitureObjectonPageLoad() {
        const orderDetails = this.state.orderDetails;        
        let pageObj = null;
        let applicationObj = null;        
        let eventObj = null;

        pageObj = analytics.createPageObj(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS);
        
        applicationObj = this.setApplicationObjProps();
        this.pageObj = pageObj;
        this.applicationObj = applicationObj;

        eventObj = analytics.createEventObj(constants.ORDER_DETAILS_VIEWED_ANALYTICS, orderDetails.meta.orderId);
        analytics.createDateLayerObj(pageObj, applicationObj, eventObj);        
    }

    setApplicationObjProps() {
        const orderDetails = this.state.orderDetails;        
        const accessToken = this.state.accessToken;
        let applicationObj = analytics.createApplicationObj(orderDetails, accessToken);

        return applicationObj;    
    }
}
