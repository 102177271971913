import React from "react";
import MobileImageTD from '../../images/mobileTD.svg';
import MultilingualTD from '../../images/customer-testimonialsTD.svg';
import EmailSupportTD from '../../images/email-supportTD.svg';
import { getCompareFunc } from "../common/utils";

export default function StripBannerFooter({footers}) {
  footers = footers?.sort(getCompareFunc('displaySequence'));

  return (
    <div className='strip-banner responsive-width'> 
      {footers.map(({label, icon, subLabel, linkLabel, linkURL, displaySequence}) => {
        const {src, alt} = getIconDetails(icon);

        return (
          <div className='strip-banner-function' key={displaySequence}>
            <img src={src} alt={alt} />
            <span>
              <h3>{label}</h3>
              <p>{subLabel}</p>
              <a
                href={linkURL}
                target='_blank'
                className='alt-method-link'
              >
                {linkLabel}
              </a>
            </span>
          </div>
        )
      })}
    </div>
  );
}

function getIconDetails(icon) {
  switch(icon) {
    case "MTAPPICON":
      return {src: MobileImageTD, alt: 'telstraApp'};
    case "MSICON":
      return {src: MultilingualTD, alt: 'multiLingual'};
    case "SCICON":
      return {src: EmailSupportTD, alt: 'customerSupport'};
    default: 
      console.error(`${icon} not a supported icon name`);
      return {src: MobileImageTD, alt: 'telstraApp'};
  }  
}