import React from "react";
import ElectricityIcon from "../../images/electricity.svg";
import GasIcon from "../../images/gas.svg";
import ElectricityActiveIcon from "../../images/electricity-active.svg";
import GasActiveIcon from "../../images/gas-active.svg";
import { getCompareFunc } from "../common/utils";

const Icons = {
  'ELECTRICITYICON' : {
    inactive: ElectricityIcon,
    active: ElectricityActiveIcon,
  },
  'GASICON' : {
    inactive: GasIcon,
    active: GasActiveIcon,
  },
}

export default function TabsGroups({ tabsGroups, handleTabChange }) {
  tabsGroups = tabsGroups?.sort(getCompareFunc('displaySequence'));
  
  
  return (
    <React.Fragment>
      {
        (tabsGroups.length > 1) && (
          <div className="timeline-group-tabs responsive-width" id="timeline-group-tabs">
            <ul role="tablist">
              {tabsGroups.map(group => {
                const icon = Icons[group.timelineGroupIcon];
                return (
                  <li
                    key={group.timelineGroupFamily}
                    className={group.isSelected? 'active-group' : ''}
                    onClick={() => handleTabChange(group)}
                    tabIndex="0"
                    role="tab"
                    aria-selected={group.isSelected}
                    title={`${group.timelineGroupLabel},tab,${group.isSelected ? 'selected' : ''}`}
                  >
                    <img
                      aria-hidden="true"
                      src={group.isSelected ? icon.active : icon.inactive}
                      className="energy-tab-icon"
                    />
                    <span>{group.timelineGroupLabel}</span>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      }
    </React.Fragment>
  );
}