import React, { useState } from "react";
import ReactDOM from 'react-dom';
import {ActionButton, Surface, Spacing} from '@able/react';
import InfoIcon from "../../images/icon-info-blue.svg";
import iconError from "../../images/icon-error.svg";
import covidHeaderClose from "../../images/covid-header-close.svg";
import * as analytics from '../../analytics-utils';
import * as constants from "../../presenters/Constants";
import { isIpadDevice, isMobileDevice, goToSignIn, handleCtaLinkClick } from "../../utils.js";

let analyticsObj = null;
const LEAVE_MESSAGE = "You haven’t finished rescheduling your appointment. Are you sure you want to leave?"

export default function BannerMessage(props) {
  const [showAppt, setShowAppt] = useState(false);
  const {icon, title, message, heroText} = props.bannerMessage;
  const { orderDetails, accessToken, page } = props
  const isAuthenticated = (page.loggedIn && accessToken) || window.env.REACT_APP_DEV_ENV === 'true'
  const isError = isErrorIcon(icon);
  let wrapperClassName = getWrapperClassName(isError);

  analyticsObj = props.analyticsObj;
  createEdgarBannerObjForOmniture(analyticsObj.orderDetails.referenceCode, title, message);

  return (
  <React.Fragment>
    {
      props.bannerMessage && Object.keys(props.bannerMessage).length !== 0 && 
      <Surface className={wrapperClassName} variant="SurfaceHigh">
        <div className="banner-body-td">
          <div>{getIcon(isError)}</div>
          <Spacing left="spacing2x" />
          <div>
          {title && getBannerHeader(title)}
          {message && getBannerDescription(message, props.bannerMessage, orderDetails)}
          {showAppt && renderApptManager(isAuthenticated, orderDetails, showAppt, setShowAppt)}
        </div>
        </div>
        <div>
        {heroText && getCtaButton(props.bannerMessage, orderDetails, showAppt, setShowAppt)}
        </div>
      </Surface>
    }
  </React.Fragment>);
}

function createEdgarBannerObjForOmniture(referenceCode, bannerHeader, bannerDescription) {
  let eventObj = null;
  let bannerObj = null
  bannerObj = {
    "bannerHeader": bannerHeader,
    "bannerDescription": bannerDescription
  }
  if (referenceCode) 
    eventObj = analytics.createEventObj('error', 'Business', referenceCode, bannerHeader);
  analytics.createDateLayerObj(analyticsObj.pageObj, analyticsObj.applicationObj, eventObj, bannerObj);
}

function addClickEventToEdgarOmnitureObject(eventAction) {
  analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, eventAction);
}

function isErrorIcon(icon) {
  return icon !== 'BLUE';
}

function renderApptManager(isAuthenticated, orderDetails, showAppt, setShowAppt) {
  if(!isAuthenticated) {
    let redirectUrl = window.env.REACT_APP_HOST_NAME + '/orderdetails'
    goToSignIn('', redirectUrl, false)
    return
  }

  const orderId = orderDetails?.header?.orderNumber
  const planName = orderDetails?.header?.orderDescription
  let iframeUrl = window.env.REACT_APP_API_APPT_URL + "/appointments?orderId=" + orderId + "&callbackURL=ORDER_STATUS_TRACKER&planName=" + planName;

  function handleClose() {
    if(window.confirm(LEAVE_MESSAGE)) setShowAppt(!showAppt)
  }

  return ReactDOM.createPortal(
    <div className="iframe-container">
      <div className="iframe-parent">
        <iframe
            id="IframePopup"
            className="iframe-popup-td"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            src={iframeUrl}/>
      </div>
      <div id="closeBtn" className="close-btn-td" onClick={() => handleClose()}>
        <img className="close-appt-manager-btn" src={covidHeaderClose}
              alt="COVID 19 MESSAGE"/>
      </div>
      <div id="popupBack" className="popup-td" onClick={() => handleClose()}/>
    </div>,
    document.getElementById('iframe-portal')
  )
}

function handleRescheduleApptClick(e, banner, orderDetails, showAppt, setShowAppt) {
  let { heroMobileLink } = banner
  const orderId = orderDetails?.header?.orderNumber

  // redirect inplace for mobile, for desktop show in iframe
  if ((isMobileDevice() || isIpadDevice()) && heroMobileLink) { 
    window.location.href = window.env.REACT_APP_API_APPT_URL + "/appointments?orderId="+ orderId + "&callbackURL=" + process.env.REACT_APP_API_APPT_CALLBACK_URL
  } else {
    setShowAppt(!showAppt)
  }
}

function getCtaButton(banner, orderDetails, showAppt, setShowAppt) {
  let {heroText, heroLink, heroMobileLink, chatGroupTags} = banner

  // do not open new tab if herolink is falsy like ''.
  heroLink = heroLink ||  null; 

  return (
    <ActionButton
      className="banner-cta-btn"
      element="button"
      variant="LowEmphasis"
      onClick={e => {
        if(heroLink == constants.RESCHEDULE_APPT_BANNER_LINK) {
          handleRescheduleApptClick(e, banner, orderDetails, showAppt, setShowAppt)
        } else {
          handleCtaLinkClick(e, heroLink, heroMobileLink, chatGroupTags, orderDetails?.header)
        }
        addClickEventToEdgarOmnitureObject(heroText)
      }}
      label={heroText}
      tabIndex="0"
    />
  );
}


function getEmbeddedUrl(banner, copyText, orderDetails) {
  let { embeddedCtaLink, embeddedCtaMobileLink, chatGroupTags } = banner
  return <a id="embedded-cta" onClick={(e) => handleCtaLinkClick(e, embeddedCtaLink, embeddedCtaMobileLink, chatGroupTags, orderDetails?.header)}>{copyText}</a>
}

function getMailToLink(orderDetails) {
  const orderId = orderDetails?.header?.orderNumber
  let subject = `${constants.MOVES_EMAIL_SUBJECT}${orderId}`
  let body = constants.MOVES_EMAIL_BODY
  body += `Order Number: ${orderId}%0d%0d`
  body += constants.MOVES_EMAIL_BODY_PROVIDE_DETAILS
  let email = `mailto:MovingWithTelstra@team.telstra.com?subject=${subject}&body=${body}`

  return <a href={email}>email us</a>
}

function getBannerDescription(message, banner, orderDetails) {
  const updatedBodyElements = []

  // update trademark symbol
  if(message && message.includes(constants.TRADEMARK_SYMB)) {
    message = message.split(constants.TRADEMARK_SYMB).join('™')
  }

  if(message && message.includes('<message us>')) {
    const parts = message.split('<message us>')
    updatedBodyElements.push(parts[0])
    updatedBodyElements.push(getEmbeddedUrl(banner, 'message us', orderDetails))
    updatedBodyElements.push(parts[1] || '')
  } else if(message && message.includes('#MOVES_EMAIL#')) {
    const parts = message.split('#MOVES_EMAIL#')
    updatedBodyElements.push(parts[0])
    updatedBodyElements.push(getMailToLink(orderDetails))
    updatedBodyElements.push(parts[1] || '')
  } else if(message && message.includes('<contact us>')){
    const parts = message.split('<contact us>')
    updatedBodyElements.push(parts[0])
    updatedBodyElements.push(getEmbeddedUrl(banner, 'contact us', orderDetails))
    updatedBodyElements.push(parts[1] || '')
  } else {
    updatedBodyElements.push(message)
  }

  return <p className="banner-subtext-td" tabIndex="0">{updatedBodyElements}</p>;
}

function getBannerHeader(title) {
  return <h2 className="banner-message-content-header-td" tabIndex="0">{title}</h2>;
}

function getIcon(isError) {
  return (
    <div>
      {isError ? <img src={iconError} alt="error" style={{minWidth: 24+'px', minHeight: 24+'px'}}/> : <img src={InfoIcon} alt="information" style={{minWidth: 24+'px', minHeight: 24+'px'}}/>}
    </div>
  );
}

function getWrapperClassName(isError) {
  return `banner-content-td-${isError? 'red' : 'blue'}`;
}
