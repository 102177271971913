import React, { Component } from "react";
import StatusIconTD from "./StatusIconsTD";
import * as constants from "../Constants";
import TimelineMilestone from './TimelineMilestonesTD'
import errorIcon from '../../images/icon-error.svg'

class HardwareReturn extends Component {
  isOrderComplete() {
    let { orderStatus } = this.props;
    switch (String(orderStatus).toLowerCase()) {
      case constants.COMPLETE:
      case constants.COMPLETED:
        return true;
      default:
        return false;
    }
  }

  render() {
    let {
      displayStatus,
      shipmentType,
      iconStatus,
      hardwareShipmentDate,
    } = this.props.hardwareDeliveryData;

    return <TimelineMilestone
       title="Device return"
       icon={<StatusIconTD uniqueIdentifier="Hardware-Return" className="hardware-return-icon" status={iconStatus} />}
       lineColor={this.isOrderComplete() ? '#0064D2' : iconStatus && iconStatus.toLowerCase() !== 'completed' ? '#d8d8d8': '#0064D2'}
       createdAt={hardwareShipmentDate}
      >
          {((iconStatus && iconStatus.toLowerCase() === 'problem') ||
          (iconStatus && iconStatus.toLowerCase() === 'error') ||
          (iconStatus && iconStatus.toLowerCase() === 'failed')) &&
          <img src={errorIcon} className="small-icon-td-error" alt="Info Icon" />}
        {displayStatus}
      </TimelineMilestone>    
  }

  getNodes(equalizerComponent, equalizerElement) {
    return [this.refs.node1, this.refs.node2];
  }
}

export default HardwareReturn;
