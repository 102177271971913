import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Animation from '../Animation';
import SpinnerJson from './assets/SpinnerData.json';

/**
 * The brand specific animated loading spinner
 *
 * @since 1.4.0
 *
 * Updates:
 *
 - `2.2.0` updated the colouring to match new branding
 */
const Spinner = props => {
  const componentClass = 'mt-spinner';
  const classes = classnames(
    componentClass,
    `${componentClass}--${props.size}`
  );
  return <Animation className={classes} data={SpinnerJson} loop={true} />;
};

Spinner.propTypes = {
  /**
   * Size of spinner
   */
  size: PropTypes.oneOf(['medium', 'large', 'small', 'mini']).isRequired,
};

Spinner.defaultProps = {
  size: 'medium',
};

export default Spinner;
