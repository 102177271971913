import React, { Component } from "react";
import { openInNewTab, isMobileDevice, isIpadDevice, openChat } from "../../utils";
import StatusIconTD from "./StatusIconsTD";
import AddToCalendar from './AddToCalendar';
import * as constants from "../Constants";
import { isMobile } from 'react-device-detect';
import FileSaver from 'file-saver';
import covidHeaderClose from "../../images/covid-header-close.svg";
import TimelineMilestone from './TimelineMilestonesTD'
import { ActionButton } from '@able/react';
import infoIcon from '../../images/icon-info-blue.svg';
import * as analytics from '../../analytics-utils';
import * as utils from '../../utils';
import ReactDOM from 'react-dom';

class NbnAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = { showApptPopup: false };
        this.isReschedulable = true;
    }

    render() {
        const { nbnAppointment, orderCategory } = this.props;
        return orderCategory.toLowerCase() === constants.FIXED ? this.renderFixedNbnAppointment(nbnAppointment) : this.renderNbnAppointment(nbnAppointment);
    }

    renderFixedNbnAppointment(nbnAppointment) {
        if (nbnAppointment) {
            return (<TimelineMilestone
                title={constants.FIXED_NBN_DETAILS_TITLE}
                lineColor={nbnAppointment.iconStatus && nbnAppointment.iconStatus.toLowerCase() !== 'completed' ? '#d8d8d8' : '#0064D2'}
                createdAt={
                    <>
                        {nbnAppointment.date && <div className="milestone-date">{nbnAppointment.date}</div>}
                        {nbnAppointment.slot && nbnAppointment.status && nbnAppointment.status.toLowerCase() === constants.IN_PROGRESS && <div className="milestone-date">{nbnAppointment.slot.toLowerCase()}</div>}
                    </>}
                icon={this.renderStatusIcon()}>
                {nbnAppointment.displayStatus && <div id="NBN-Appointment-Status">{nbnAppointment.displayStatus}</div>}
                {nbnAppointment.typeText && nbnAppointment.status && nbnAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                    <div id = "NBN-Appointment-Type-Container">
                        <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                        <div id="NBN-Appointment-Type" className="attendance-label appointment-type">{nbnAppointment.typeText}</div>
                    </div>}
                {nbnAppointment.nbnAppointmentLink && nbnAppointment.status && nbnAppointment.status.toLowerCase() !== constants.COMPLETED && this.renderRescheduleAppointmentModule()}
                {nbnAppointment.calendarEvent && this.renderAddToCalendar(nbnAppointment.calendarEvent)}
                {nbnAppointment.status && nbnAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                    <div id="NBN-Appointment-Static-Heading">{constants.FIXED_NBN_APPT_STATIC_HEADING}</div>}
                {nbnAppointment.status && nbnAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                    <div id="NBN-Appointment-Static-Body">{constants.FIXED_NBN_APPT_STATIC_BODY}</div>}
                {this.renderAppointmentComponents()}
                {nbnAppointment.nbnAppointmentLink && nbnAppointment.status && nbnAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                    <div>
                        <ActionButton variant="LowEmphasis" label="Learn more" element="a" onClick={(e) => this.onClickOfHowToPrepareForYourNbnAppt(e)}/>

                    </div>}
                {nbnAppointment.showModemInstallText && nbnAppointment.status.toLowerCase() !== constants.COMPLETED &&
                    <p>Please plug in your modem to start using your nbn <sup style={{ fontSize: '10px' }}>TM</sup> service.</p>}
                {nbnAppointment.status && nbnAppointment.status.toLowerCase() === constants.COMPLETED && <p />}
            </TimelineMilestone>
            )
        }
        return null;
    }

    renderNbnAppointment(nbnAppointment) {
        if (nbnAppointment) {
            return (<TimelineMilestone
                title={constants.NBN_DETAILS_TITLE}
                createdAt={nbnAppointment.date != null || nbnAppointment.slot != null ? (
                    nbnAppointment.slot != null && nbnAppointment.status.toLowerCase() !== constants.COMPLETED ? (nbnAppointment.slot.toLowerCase() + ' ' + nbnAppointment.date) : (nbnAppointment.date)
                ) : ''}
                lineColor={nbnAppointment.iconStatus && nbnAppointment.iconStatus.toLowerCase() !== 'completed' ? '#d8d8d8' : '#0064D2'}
                icon={this.renderStatusIcon()}>
                {nbnAppointment.typeText &&
                    <div id="NBN-Appointment-Type" className="attendance-label appointment-type">{nbnAppointment.typeText}</div>}
                {nbnAppointment.calendarEvent && this.renderAddToCalendar(nbnAppointment.calendarEvent)}
                {this.renderRescheduleAppointmentModule()}
                {this.renderAppointmentComponents()}
                {nbnAppointment.nbnAppointmentLink && nbnAppointment.status && nbnAppointment.status.toLowerCase() !== constants.COMPLETED &&
                    <div>
                        <ActionButton variant="LowEmphasis" label="How to prepare for your nbn co appointment" element="a" onClick={(e) => this.onClickOfHowToPrepareForYourNbnAppt(e)}/>
                    </div>}
                {nbnAppointment.showModemInstallText && nbnAppointment.status.toLowerCase() !== constants.COMPLETED &&
                    <p>Please plug in your modem to start using your nbn <sup style={{ fontSize: '10px' }}>TM</sup> service.</p>}
                {nbnAppointment.status && nbnAppointment.status.toLowerCase() === constants.COMPLETED && <p />}
            </TimelineMilestone>
            )
        }
        return null;
    }

    onClickOfHowToPrepareForYourNbnAppt(e) {
        const {nbnAppointment} = this.props;
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.HOW_TO_PREPARE_NBN_ANALYTICS);
        openInNewTab(e, nbnAppointment.nbnAppointmentLink);
    }
    confirmAlert() {
        if (window.confirm("You haven’t finished rescheduling your appointment. Are you sure you want to leave?")) {
            this.togglePopup();
        }
    }

    togglePopup() {
        this.props.closeLightbox();
        this.setState({ showApptPopup: !this.state.showApptPopup });
    }

    renderStatusIcon() {
        const { nbnAppointment, reasonForUnHealthyData } = this.props;
        let customAction;
        if (reasonForUnHealthyData && reasonForUnHealthyData.unhealthyCustomAction) {
            customAction = reasonForUnHealthyData.unhealthyCustomAction;
        }
        return nbnAppointment.iconStatus ?
            <StatusIconTD uniqueIdentifier="NBN-Appointment" status={nbnAppointment.iconStatus} customAction={customAction}/> : '';
    }


    renderAddToCalendar(event) {
        let icon = { textOnly: 'none' };
        let calendarForMobile = null;

        if (isMobile) {
            return (
                <div id="Add-To-Calender" className="flex-row add-to-calender-td">
                    <a href="javascript:window.location.reload(false)" onClick={(e) => {
                        var icsCalendar = "BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nURL:" + e + "\nDTSTART:" + event.startTime + "Z" + "\nDTEND:" + event.endTime + "Z" + "\nLOCATION:" + event.location + "\nSUMMARY:" + event.title + "\nDESCRIPTION:" + event.description + "\nEND:VEVENT\nEND:VCALENDAR";
                        var blob = new Blob([icsCalendar], { type: 'text/plain;charset=utf-8' })
                        FileSaver.saveAs(blob, 'Appointment.ics')
                    }}>
                        <AddToCalendar listItems={calendarForMobile} />
                    </a>
                </div>
            );
        }
        return (
            <div id="Add-To-Calender" className="flex-row add-to-calender-td">
                <AddToCalendar event={event} buttonLabel="Add to your calendar" buttonTemplate={icon} />
            </div>
        );
    }


    renderRescheduleAppointmentModule() {
        //TODO: Remove else block once OSTK-138 feature is fully enabled
        if(utils.isApptMgrEnabledForNBNCategory()) {
            const {nbnAppointment, orderCategory} = this.props;
            const {orderDetails: {orderSummary: {enableManageAppointments = false}}} = this.props;
            if ((orderCategory.toLowerCase() === constants.HEADLESS ||
                orderCategory.toLowerCase() === constants.HEADLESS_PREORDER ||
                orderCategory.toLowerCase() === constants.NEXTGEN_OSM ||
                orderCategory.toLowerCase() === constants.FIXED ||
                orderCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN) &&
                enableManageAppointments) {
                return this.renderAppointments();
            } else if (nbnAppointment.type &&
                nbnAppointment.status &&
                nbnAppointment.status.toLowerCase() !== constants.COMPLETED) {
                return this.renderTAMRescheduleLink();
            }
        } else {
            const { nbnAppointment, orderCategory } = this.props;
            if ((orderCategory.toLowerCase() === constants.HEADLESS ||
                orderCategory.toLowerCase() === constants.HEADLESS_PREORDER ||
                orderCategory.toLowerCase() === constants.NEXTGEN_OSM ||
                orderCategory.toLowerCase() === constants.FIXED) &&
                nbnAppointment.status &&
                (nbnAppointment.status.toLowerCase() !== constants.COMPLETED &&
                    nbnAppointment.status.toLowerCase() !== constants.CANCELLED))
                return this.renderAppointments();
            else if (nbnAppointment.type &&
                nbnAppointment.type === constants.EU &&
                nbnAppointment.status &&
                nbnAppointment.status.toLowerCase() !== constants.COMPLETED)
                return this.renderTAMRescheduleLink();
        }
    }

    renderAppointments() {
        const { nbnAppointment, orderId } = this.props;
        let appointmentType = nbnAppointment.appointmentCategory ? nbnAppointment.appointmentCategory : null;
        let appointmentId = nbnAppointment.appointmentId ? nbnAppointment.appointmentId : null;
        localStorage.setItem('nbnAppointmentIdOst', appointmentId);
        localStorage.setItem('orderIdOst',orderId );
        if (window.env.REACT_APP_DEV_ENV === 'true')
            return this.renderWithoutLogin();
        else
            return this.renderAppointmentRescheduleLink(appointmentId, appointmentType);
    }


    renderTAMRescheduleLink() {
        const { nbnAppointment, orderId, isUnauthorized ,contactUsUrl,orderDetails} = this.props;
        return  !isUnauthorized && nbnAppointment.reschedulable ? (
            <div className="appointment-box-td flex-row message-flex">
                <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                <span>
                <p style={{ margin: '0' }}>{constants.NBN_RESCHEDULE_APPT_MSG}</p>
                <ActionButton
                    variant="LowEmphasis"
                    onClick={(e) => this.onTAMRescheduleApptClick(e)}
                    element="button"
                    label={constants.RESCHEDULE_APPT}
                />
            </span>
            </div>
        ) : (
            <div className="appointment-box-td reschedule-message">
                <div id="Nbn-Appointment-Unable-To-Reschedule-Text" className="progress-label">Unable to reschedule</div>
                <div id="Unable-To-Reschedule-Appointment-Message" className="reschedule-message-content">
                    {isUnauthorized && nbnAppointment.reschedulable ? (
                        <span style={{ display: 'flex', marginBottom: '0.75rem'}}>
                            <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                            <div>
                                <p style={{ margin: 0 }}>This order number is not linked to your account OR you are not authorised to make changes to this order.</p>
                                <p style={{ margin: 0 }}>Please contact us using the link below for more information.</p>
                                <ActionButton
                                    variant="LowEmphasis"
                                    onClick={(e) => this.onTAMRescheduleApptClickError(e, contactUsUrl)}
                                    element="button"
                                    label="Message us"
                                />
                            </div>
                        </span>
                    ) : (
                        orderDetails.orderSummary.orderCategory === "NBN" && orderDetails.orderSummary.planName.includes("Move") ? (
                            <span style={{ display: 'flex', marginBottom: '0.75rem'}}>
                                <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                                <p style={{ margin: 0 }}>
                                Unfortunately we’re unable to reschedule your appointment online. Please call us on <a href="tel:1800834273">1800 834 273</a> and follow the Check Order prompt. We’re available from 7am to 9pm AEST.
                                </p>
                            </span>
                        ) : (
                            <span style={{ display: 'flex', marginBottom: '0.75rem'}}>
                                <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                                <div>
                                    <p style={{ margin: 0 }}>To change your appointment, please contact us using the link below</p>
                                    {isMobileDevice() || isIpadDevice() ? (
                                        <ActionButton
                                            variant="LowEmphasis"
                                            onClick={(e) => this.onTAMRescheduleApptClickError(e, contactUsUrl)}
                                            element="button"
                                            label={`Message us`}
                                        />
                                    ) :
                                    (
                                        <ActionButton
                                            variant="LowEmphasis"
                                            onClick={() => openChat('message us', ["telstra", "dtq", "gbs", "nbn", "activation", "ost"], orderDetails)}
                                            element="button"
                                            label={`Message us`}
                                        />
                                )}
                                </div>
                            </span>
                        )
                    )}
                </div>
            </div>
        )
    }

    onTAMRescheduleApptClick(e) {
        const { orderId } = this.props;
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.TAM_CLICK_ANALYTICS);
        openInNewTab(e, 'https://www.telstra.com.au/appointments#/?orderid=' + orderId);
    }

    onTAMRescheduleApptClickError(e, contactUsUrl) {
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.MESSAGE_US_ANALYTICS);
        openInNewTab(e, contactUsUrl);
    }

    renderWithoutLogin() {
        return this.renderUnauthenticatedLinksAndMessages();
    }

    renderAppointmentRescheduleLink(appointmentId, appointmentType) {
        localStorage.setItem('nbnAccessToken', this.props.accessToken);
        return this.renderAppointmentLinksAndMessages();

    }

    renderAppointmentLinksAndMessages() {
        const {isAuthenticated} = this.props;
        if(isAuthenticated)
            return this.renderAuthenticatedLinksAndMessages();
        else
            return this.renderUnauthenticatedLinksAndMessages();
    }

    renderUnauthenticatedLinksAndMessages() {
        const { nbnAppointment, loginEligibility, orderCategory } = this.props;
        this.isReschedulable = nbnAppointment.reschedulable;

        let showRescheduleLink = true;
        if (orderCategory.toLowerCase() === constants.HEADLESS || orderCategory.toLowerCase() === constants.HEADLESS_PREORDER || orderCategory.toLowerCase() === constants.NEXTGEN_OSM) {
            showRescheduleLink = loginEligibility;
        }

        if(orderCategory && window.env.REACT_APP_SHOW_RESCHEDULE_APPT === 'true') {
            if(orderCategory.toLowerCase()===constants.HEADLESS
                || orderCategory.toLowerCase()===constants.HEADLESS_PREORDER || orderCategory.toLowerCase()===constants.NEXTGEN_OSM) {
                if(showRescheduleLink && this.isReschedulable && !nbnAppointment.ponrreached) {
                    return this.renderUnAuthRescheduleButton();
                } else {
                    return this.returnErrorMessageForUnauthenticatedFlow();
                }
            } else {
                if(this.isReschedulable && !nbnAppointment.ponrreached) {
                    return this.renderUnAuthRescheduleButton();
                } else {
                    return this.returnErrorMessageForUnauthenticatedFlow();
                }
            }
        } else {
            return this.returnErrorMessageForUnauthenticatedFlow();
        }
    }

    renderUnAuthRescheduleButton() {
        return(
            <ActionButton
                variant="LowEmphasis"
                id="Reschedule-Appt-For-Dev-Orders"
                onClick={(e) => this.subscribeBeforeLogin(e)}
                element="button"
                label="Reschedule appointment"
            />
        );
    }

    subscribeBeforeLogin(e) {
        utils.handleSignInSignOut(e, this.props.location.pathname);
    }

    renderAuthenticatedLinksAndMessages() {
        const { orderCategory} = this.props;
        if(orderCategory && orderCategory.toLowerCase()===constants.FIXED)
            return this.renderAuthenticatedLinksAndMessagesForFixed();
        else if (orderCategory.toLowerCase()===constants.HEADLESS
            || orderCategory.toLowerCase()===constants.HEADLESS_PREORDER || orderCategory.toLowerCase()===constants.NEXTGEN_OSM) {
            return this.renderAuthenticatedLinksAndMessagesForHeadlessOrders();
        }
        else
            return this.renderAuthenticatedLinksAndMessagesForRestOrders();
    }

    renderAuthenticatedLinksAndMessagesForFixed = () => {
        const { nbnAppointment, isUnauthorized } = this.props;
        this.isReschedulable = nbnAppointment.reschedulable;
        if(!isUnauthorized && this.isReschedulable && !nbnAppointment.ponrreached  && window.env.REACT_APP_SHOW_RESCHEDULE_APPT === 'true') {
            return (
                <div>
                    {(isMobileDevice() || isIpadDevice()) ?
                        <ActionButton
                            id="Reschedule-Appt-For-Fixed-Orders-Mobile"
                            onClick={(event) => this.showApptDetailsMobile()}
                            variant="LowEmphasis"
                            element="button"
                            label="Reschedule appointment"
                        />
                        :
                        <ActionButton element="button" id="Reschedule-Appt-For-Fixed-Orders" variant="LowEmphasis" onClick={this.togglePopup.bind(this)} label="Reschedule appointment" />}
                </div>
            )
        }
        else {
            return (
                <div className="appointment-box-td reschedule-message">
                    <div id="Unable-To-Reschedule-Appointment-Label" className="progress-label">
                        {constants.FIXED_UNABLE_TO_RESCHEDULE}
                    </div>
                    <div id="Unable-To-Reschedule-Appointment-Message" className="reschedule-message-content">
                        {this.renderFixedFlow()}
                    </div>
                </div>
            );
        }
    }

    renderAuthenticatedLinksAndMessagesForHeadlessOrders = () => {
        const { nbnAppointment, isUnauthorized, loginEligibility, orderCategory } = this.props;
        this.isReschedulable = nbnAppointment.reschedulable;
        let showRescheduleLink = true;
        if (orderCategory.toLowerCase() === constants.HEADLESS || orderCategory.toLowerCase() === constants.HEADLESS_PREORDER || orderCategory.toLowerCase() === constants.NEXTGEN_OSM) {
            showRescheduleLink = loginEligibility;
        }
        if(!isUnauthorized && this.isReschedulable && !nbnAppointment.ponrreached && showRescheduleLink) {
            return (
                <div>
                    {(window.env.REACT_APP_SHOW_RESCHEDULE_APPT === 'true') ? (
                        <div>
                            {(isMobileDevice() || isIpadDevice()) ?
                                <ActionButton element="button" id="Reschedule-Appt-For-Headless-Orders-Mobile" variant="LowEmphasis" onClick={(event) => this.showApptDetailsMobile()} label="Reschedule appointment" />
                                :
                                <ActionButton element="button" id="Reschedule-Appt-For-Headless-Orders" variant="LowEmphasis" onClick={this.togglePopup.bind(this)} label="Reschedule appointment" />}
                        </div>
                    ) : (
                        <div className="appointment-box-td flex-row">
                            <div id="Reschedule-Appointment-Message-Headless" className="appointment-info progress-details">
                                {constants.HEADLESS_RESCHEDULE_APPT}
                            </div>
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div className="appointment-box-td reschedule-message">
                    <div id="Unable-To-Reschedule-Appointment-Label" className="progress-label">
                        {constants.UNABLE_TO_RESCHEDULE}
                    </div>
                    <div id="Unable-To-Reschedule-Appointment-Message" style={{ display: 'flex', marginBottom: '0.75rem' }}>
                        <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                        {isUnauthorized ? (
                            <p style={{ margin: 0 }}>
                                This order number is not linked to your account OR you need to be the authorised account holder to make changes, please call <a href="tel:1800931877">1800 931 877</a> and follow the nbn activation prompt. We’re available from Monday to Friday 9:00am to 9:00pm and Saturday 9:00am to 5:00pm AEST.
                            </p>
                        ) : (<p style={{ margin: 0 }}>
                                To reschedule, please call <a href="tel:1800931877">1800 931 877</a> and follow the nbn activation prompt. We’re available from Monday to Friday 9:00am to 9:00pm and Saturday 9:00am to 5:00pm AEST.
                            </p>
                        )}
                    </div>
                </div>
            )
        }
    }

    renderAuthenticatedLinksAndMessagesForRestOrders = () => {
        const { nbnAppointment, isUnauthorized, contactUsUrl, orderDetails, orderCategory } = this.props;
        this.isReschedulable = nbnAppointment.reschedulable;
        if(!isUnauthorized && this.isReschedulable) {
            return (
                <div>
                    {(window.env.REACT_APP_SHOW_RESCHEDULE_APPT === 'true') ? (
                            <div>
                                {(isMobileDevice() || isIpadDevice()) ?
                                    <ActionButton element="button" variant="LowEmphasis" onClick={(event) => this.showApptDetailsMobile()} label="Reschedule appointment" />
                                    :
                                    <ActionButton element="button" variant="LowEmphasis" onClick={this.togglePopup.bind(this)} label="Reschedule appointment" />}
                            </div>
                    ) : (
                        <div className="appointment-box-td flex-row">
                            <div id="Reschedule-Appointment-Message-Headless" className="appointment-info progress-details">
                                {constants.HEADLESS_RESCHEDULE_APPT}
                            </div>
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div className="appointment-box-td reschedule-message">
                    <div id="Unable-To-Reschedule-Appointment-Label" className="progress-label">
                        {constants.UNABLE_TO_RESCHEDULE}
                    </div>
                    <div id="Unable-To-Reschedule-Appointment-Message" style={{ display: 'flex', marginBottom: '0.75rem' }}>
                        <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                        {isUnauthorized ? (
                            <p style={{ margin: 0 }}>
                                This order number is not linked to your account OR you need to be the authorised account holder to make changes, please call <a href="tel:1800931877">1800 931 877</a> and follow the nbn activation prompt. We’re available from Monday to Friday 9:00am to 9:00pm and Saturday 9:00am to 5:00pm AEST.
                            </p>
                        ) : ( orderCategory && orderCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN ?
                            (<div>
                                <div> To change your appointment, please contact us using the link below</div>
                                {isMobileDevice() || isIpadDevice() ? (<ActionButton element="button" variant="LowEmphasis" label="Message us" onClick={(e) => openInNewTab(e, contactUsUrl)} />) :
                                (<ActionButton element="button" variant="LowEmphasis" label="Message us" onClick={() => openChat('message us', ["telstra", "dtq", "gbs", "nbn", "activation", "ost"], orderDetails)} />)}
                            </div>
                        ) : (<p style={{ margin: 0 }}>
                                To reschedule, please call <a href="tel:1800931877">1800 931 877</a> and follow the nbn activation prompt. We’re available from Monday to Friday 9:00am to 9:00pm and Saturday 9:00am to 5:00pm AEST.
                            </p>
                        )
                        )}
                    </div>
                </div>
            )
        }
    }

    renderFixedFlow = () => (
        <React.Fragment>
            <span style={{ display: 'flex', marginBottom: '0.75rem'}}>
                <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                <div>
                    <p style={{ margin: 0 }}>To change your appointment, please contact us using the link below</p>
                    <ActionButton element="button" variant="LowEmphasis" onClick={(e) => this.onMessageUsClickForFixedAppts(e)} label="Message us"/>
                </div>
            </span>
        </React.Fragment>
    )

    onMessageUsClickForFixedAppts(e) {
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.MESSAGE_US_CLICK_FOR_FIXED_APPTS_ANALYTICS);
        openInNewTab( e, "https://www.telstra.com.au/mytelstra");
    }
    returnErrorMessageForUnauthenticatedFlow(){
        const { orderCategory, contactUsUrl, orderDetails }= this.props;
        return (
            <div className="appointment-box-td reschedule-message">
                {orderDetails.nbnAppointment.status.toLowerCase() !== constants.COMPLETED && <div>
                <div id="Unable-To-Reschedule-Appointment-Label" className="progress-label">
                    {orderCategory && ( orderCategory.toLowerCase() === constants.FIXED || orderCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN) ? constants.FIXED_UNABLE_TO_RESCHEDULE : constants.UNABLE_TO_RESCHEDULE}
                </div>
                <div id="Unable-To-Reschedule-Appointment-Message" className="reschedule-message-content">
                    {orderCategory && orderCategory.toLowerCase() === constants.FIXED ? (
                        this.renderFixedFlow()
                    ) : ( orderCategory && orderCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN ? (
                        <span style={{ display: 'flex', marginBottom: '0.75rem'}}>
                            <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                            <div>
                                <p style={{ margin: 0 }}>To change your appointment, please contact us using the link below</p>
                                {isMobileDevice() || isIpadDevice() ? (<ActionButton element="button" variant="LowEmphasis" label="Message us" onClick={(e) => openInNewTab(e, contactUsUrl)} />) :
                                (<ActionButton element="button" variant="LowEmphasis" label="Message us" onClick={() => openChat('message us', ["telstra", "dtq", "gbs", "nbn", "activation", "ost"], orderDetails)} />)}
                            </div>
                        </span>
                    ) : (
                        <span style={{ display: 'flex', marginBottom: '0.75rem' }}>
                            <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                            <p style={{ margin: 0 }}>
                                To reschedule, please call <a href="tel:1800931877">1800 931 877</a> and follow the nbn activation prompt. We’re available from Monday to Friday 9:00am to 9:00pm and Saturday 9:00am to 5:00pm AEST.
                            </p>
                        </span>
                     ))}
                </div>
                </div>}
            </div>
        )
    }

    renderAppointmentComponents() {
        const {orderId, planName} = this.props;
        let iframeUrl = window.env.REACT_APP_API_APPT_URL + "/appointments?orderId=" + orderId + "&callbackURL=ORDER_STATUS_TRACKER&planName="+ planName;
        if (this.state.showApptPopup) {
            analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.MANAGE_YOUR_APPOINTMENTS_ANALYTICS);
            if(this.props.isAuthenticated) {
                return ReactDOM.createPortal(
                    <div className="iframe-container">
                        <div className="iframe-parent">
                            <iframe
                                id="IframePopup"
                                className="iframe-popup-td"
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                src={iframeUrl}/>
                        </div>
                        <div id="closeBtn" className="close-btn-td" onClick={this.confirmAlert.bind(this)}>
                            <img className="close-appt-manager-btn" src={covidHeaderClose}
                                 alt="COVID 19 MESSAGE"/>
                        </div>
                        <div id="popupBack" className="popup-td" onClick={this.confirmAlert.bind(this)}/>
                    </div>,
                    document.getElementById('iframe-portal')
                )
            } else {
                let redirectUrl = window.env.REACT_APP_HOST_NAME + '/orderdetails';
                utils.goToSignIn('', redirectUrl, false);
            }
        } else {
            return null;
        }
    }
    showApptDetailsMobile() {
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.MANAGE_YOUR_APPOINTMENTS_ANALYTICS);
        const {orderId} = this.props;
        window.location.href = window.env.REACT_APP_API_APPT_URL + "/appointments?orderId="+ orderId + "&callbackURL=" + process.env.REACT_APP_API_APPT_CALLBACK_URL;
    }


}

export default NbnAppointment;
