import {connect} from "react-redux";
import Dashboard from "../presenters/components/DashboardTD";
import {fetchOrders} from "../modules/orders";

function mapStateToProps(state) {
    return {
        dashboard: state.dashboard
    }
}
function mapDispatchToProps(dispatch) {
    return {
        fetchOrders: (accessToken) => dispatch(fetchOrders(accessToken))
    };
}

const ConnectedDashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default ConnectedDashboard;
