import React from "react";
import FooterWrapper from '../../presenters/components/FooterWrapperTD';
import {BackToTopTD, FeedbackComponent} from "../../presenters/components/OrderSearchComponentsTD";

export default class SiteFooter extends React.Component {
    constructor(props) {
        super(props);
        this.pageUp = React.createRef();
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    scrollToTop() {
        window.scrollTo(0, this.pageUp.offsetTop);
    }

    render() {
        return (
            <div>
                <FeedbackComponent />
                <BackToTopTD scrollToTop={this.scrollToTop} className="docked-btn-container responsive-width"/>
                <FooterWrapper />
            </div>
        );
    }

}