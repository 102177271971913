import React from "react";
import TimelineMilestone from './TimelineMilestonesTD'
import StatusIconTD from "./StatusIconsTD"
import infoIcon from "../../images/icon-info-blue.svg";
import alertIcon from "../../images/icon-alert-small.svg";
import { COMPLETED } from "../Constants";

export default function ChownTimelineTD({chownTimelineDetail}) {
  let timelineText = [];
  if (chownTimelineDetail.chownTimelineText) {
    timelineText = chownTimelineDetail.chownTimelineText.split(":");
  }

  if (chownTimelineDetail && chownTimelineDetail.showCHOWNTimeline) {
    const timelineStatus = chownTimelineDetail.chownTimelineStatus === 'INPROGRESS' ? 'In progress' : 'Completed'
    return (
      <TimelineMilestone
        title={chownTimelineDetail.chownTimelineLabel}
        icon={
          <StatusIconTD 
            uniqueIdentifier="ChownTimeline" 
            status={timelineStatus}
          />
        }
        lineColor={
          chownTimelineDetail.chownTimelineStatus && 
          chownTimelineDetail.chownTimelineStatusLabel.toLowerCase() !== COMPLETED ? '#d8d8d8' : '#0064D2'
        }
      >
        { 
          chownTimelineDetail.chownTimelineStatusLabel &&
          <div id="chown-timeline-status">
            <div>{chownTimelineDetail.chownTimelineStatusLabel}</div>
            <p>{chownTimelineDetail.chownTimelineStatusSubLabel}</p>
          </div>
        }
        { 
          chownTimelineDetail.chownTimelineText &&
          <div id="chown-timeline-text" style={{ marginBottom: 15 }}>
            <div>{`${timelineText[0]}:`}</div>
            <div>{timelineText[1]}</div> 
          </div>
        }
        {
          chownTimelineDetail.chownTimelineMessage &&
          <div className="flex-row message-flex">
            <img 
              src={chownTimelineDetail.chownTimelineMessageICON === 'INFO' ? infoIcon: alertIcon}
              className="small-icon-td" 
              alt="Info Icon" 
            />
            <span id="chown-timeline-status-message">
              {chownTimelineDetail.chownTimelineMessage }
            </span>
          </div>
        }
      </TimelineMilestone>
    )
  } 
  return null;
}