import { RETRIEVE_ERRORS } from '../constants';
import * as client from "../adapters/client";
import * as constants from "../presenters/Constants";
import { route } from "../utils";
import {
    RECEIVE_ORDER_DETAILS,
    RECEIVE_ORDER_DETAILS_TOKEN_EXPIRED,
    RECEIVE_ORDER_DETAILS_UNAUTHORIZED,
    RECEIVE_ORDER_DETAILS_UNSUPPORTED,
    requestOrderDetails,
    telstraAppointmentProfessionalInstallLink,
    nbnAppointmentCompleteSelfInstallLink,
    nbnAppointmentHowToPrepareForNbnAppointmentLink
} from "../modules/orderDetails"

export function setStatusDownstreamError(orderId) {
    return {
        type: RETRIEVE_ERRORS.DOWNSTREAM_ERROR,
        orderId: orderId
    };
}

export function setStatusNotFoundError(orderId) {
    return {
        type: RETRIEVE_ERRORS.NOT_FOUND_ERROR,
        orderId: orderId
    };
}

export function receiveOrderDetails(json) {
    let orderId = localStorage.getItem('searchOrderId') && localStorage.getItem('searchOrderId') !== undefined
        ? localStorage.getItem('searchOrderId')
        : json.data && json.data.orderId
            ? json.data.orderId
            : null;

    if (orderId) {
        route(undefined, `/orderdetails/${orderId}`)
    }
    localStorage.removeItem('searchOrderId');
    
    let actionObj = {
        type: RECEIVE_ORDER_DETAILS,
        orderSummary: {
            orderId: json.data.orderId,
            externalOrderId: json.data.externalOrderId,
            orderCategory: json.data.orderCategory,
            orderHeaderData: json.data.orderHeaderData,
            installType: json.data.installType,
            planName: json.data.orderDescription,
            orderStatus: json.data.orderStatus,
            orderSubStatus: json.data.orderSubStatus,
            showLogin: json.data.showLogin,
            contactUsUrl: json.data.contactUsUrl,
            showViewAllOrders: json.data.showViewAllOrders,
            internetConnectionDate: json.data.internetConnectionDate,
            orderSummaryDate: json.data.orderSummaryDate,
            orderSummaryDateLabel:json.data.orderSummaryDateLabel,
            changeDateLabel:json.data.changeDateLabel,
            changeDateURL:json.data.changeDateURL,
            hideExpectedConnectionDate: json.data.hideExpectedConnectionDate,
            isFoxtelAvailable: json.data.isFoxtelAvailable,
            queryOrderId: json.data.queryOrderId,
            illustration: json.data.illustration,
        },
        orderProcessedDate: json.data.orderCreatedDate,
        orderFlowSequence: json.data.orderFlowSequence,
        orderCompletionDate: json.data.completionDate,
        internetConnectionDate: json.data.internetConnectionDate,
        overallOrderHealth: json.data.overallOrderHealth,
        reasonForUnHealthy: json.data.reasonForUnHealthy,
        footer: json.data.footer
    }

    if (json.data.reasonForUnHealthyData) {

        actionObj.reasonForUnHealthyData = {
            unHealthyHeader: json.data.reasonForUnHealthyData.unHealthyHeader,
            unHealthyMessage: json.data.reasonForUnHealthyData.unHealthyMessage,
            unHealthyColorCode: json.data.reasonForUnHealthyData.unHealthyColorCode,
            unhealthyCustomAction: json.data.reasonForUnHealthyData.unhealthyCustomAction,
            unhealthyErrorCode: json.data.reasonForUnHealthyData.unhealthyErrorCode,
            unhealthyMessageUrl: json.data.reasonForUnHealthyData.unhealthyMessageUrl,
            contactUsUrl: json.data.reasonForUnHealthyData.contactUsUrl,
            bannerLinkText: json.data.reasonForUnHealthyData.bannerLinkText,
            bannerLinkUrl: json.data.reasonForUnHealthyData.bannerLinkUrl

        }
    }

    if (json.data.address) {
        actionObj.orderSummary.connectionAddress = {
            addressLine1: json.data.address.addressLine1,
            addressLine2: json.data.address.addressLine2,
            addressLine3: json.data.address.addressLine3
        }
    }

    if (json.data.shipmentDetails) {
        let selfInstallLink;
        actionObj.hardwareDelivery = json.data.shipmentDetails;
        // selfInstallLink: selfInstallLink,
        actionObj.hardwareDelivery.map(function (element) {
            element.selfInstallLink = json.data.customerInstructionUrl ? json.data.customerInstructionUrl : '';
            return element;
        });
    }

    if (json.data.appointments) {
        let professionalInstallLink = telstraAppointmentProfessionalInstallLink(json);
        let appointments = json.data.appointments;
        let telstraApptList = [];
        appointments.map(function (appointmentElement) {
            if (appointmentElement.appointmentCategory && appointmentElement.appointmentCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN) {
                actionObj.nbnAppointment = appointmentElement;
                actionObj.nbnAppointment.selfInstallLink = nbnAppointmentCompleteSelfInstallLink(appointmentElement);;
                actionObj.nbnAppointment.nbnAppointmentLink = nbnAppointmentHowToPrepareForNbnAppointmentLink(appointmentElement);
            } else if (
                appointmentElement.appointmentCategory &&
                appointmentElement.appointmentCategory.toLowerCase() === constants.APPT_CATEGORY_ASIK_TELSTRA
            ) {
                actionObj.asikTelstraAppointment = appointmentElement;
            } else {
                telstraApptList.push(appointmentElement);
            }
        });
        actionObj.telstraAppointment = telstraApptList;
        //  set professional link for all objects in the telstraAppointment array
        actionObj.telstraAppointment.map(function (element) {
            element.professionalInstallLink = professionalInstallLink;
            return element;
        });

    }

    if (json.data.orderTimeline) {
        actionObj.orderTimeline = json.data.orderTimeline;
    }

    if (json.data.disconnectionDetails) {
        actionObj.disconnect = {
            date: json.data.disconnectionDetails.date,
            status: json.data.disconnectionDetails.status,
            iconStatus: json.data.disconnectionDetails.iconStatus
        }
    }

    if (json.data.orderCategory && (json.data.orderCategory.toLowerCase() === constants.ORDER_CATEGORY_SEMI_SUPPORTED
        || json.data.orderCategory.toLowerCase().startsWith(constants.ORDER_CATEGORY_SEMI_SUPPORTED_NBNSIEBEL_PREFIX))) {
        actionObj.isSemiSupported = true;
    }

    if (json.data.footer) {
        actionObj.footer = json.data.footer;
    }

    return actionObj;
}

export function receiveOrderDetailsError(status, orderId) {
    switch (status) {
        case 401:
            return { type: RECEIVE_ORDER_DETAILS_TOKEN_EXPIRED }
        case 403:
            return { type: RECEIVE_ORDER_DETAILS_UNAUTHORIZED, orderId: orderId }
        case 500:
        case undefined:
            return setStatusDownstreamError(orderId)
        case 400:
        case 404:
        case 304:
            return setStatusNotFoundError(orderId)
        case 422:
            return { type: RECEIVE_ORDER_DETAILS_UNSUPPORTED }
        default:
            return { type: null };
    }
}

export function fetchAuthOrderDetails(orderNumber, accessToken, fetcher = client.fetchAuthOrderDetails) {
    return (dispatch) => {
        dispatch(requestOrderDetails());
        fetcher(dispatch, receiveOrderDetails, receiveOrderDetailsError, orderNumber, accessToken);
    }
}

export function fetchUnauthOrderDetails(orderNumber, fetcher = client.fetchUnauthOrderDetails) {
    return (dispatch) => {
        dispatch(requestOrderDetails());
        fetcher(dispatch, receiveOrderDetails, receiveOrderDetailsError, orderNumber);
    }
}
