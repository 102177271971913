import React from "react";
import { connect } from "react-redux";
import OrderStatusTrackerTD from "../presenters/components/OrderStatusTrackerTD";
import { fetchAuthOrderDetails, fetchUnauthOrderDetails } from "../modules/orderDetails";
import OrderDetailsPage from "../edgar/pages/OrderDetails";
import OrderDetailsDynamicPage from "../dynamic/pages/OrderDetails";

function mapStateToProps(state) {
  return {
    orderDetails: state.orderDetails
  }
}
function mapDispatchToProps(dispatch) {
  return {
    fetchUnauthOrderDetails: (orderNumber) => dispatch(fetchUnauthOrderDetails(orderNumber)),
    fetchAuthOrderDetails: (orderNumber, accessToken, bypassCache, overrideAppointment) => dispatch(fetchAuthOrderDetails(orderNumber, accessToken, bypassCache, overrideAppointment))
  };
}

function OrderDetailsPageRouter(props) {
  const { orderDetails } = props;
  if(orderDetails.isDynamicOrder) {
    return <OrderDetailsDynamicPage {...props}/>;
  } else {
    return orderDetails.isEnergyOrder ? <OrderDetailsPage {...props}/> : <OrderStatusTrackerTD {...props}/>;
  }
}

const ConnectedOrderStatusTrackerTD = connect(mapStateToProps, mapDispatchToProps)(OrderDetailsPageRouter);

export default ConnectedOrderStatusTrackerTD;
