import React, {Component} from "react";
import { Row , Column} from "react-foundation-components/lib/global/grid";
import iconPlanName from "../../images/icon-plan-name.svg";
import {extractDayWithSuffix,extractMonthYear, openInNewTab} from "../../utils";
import iconPlanNameChevron from "../../images/icon-plan-name-chevron.svg";
import * as constants from "../Constants";

class Order extends Component {    
	constructor(props) {
		super(props);
		this.onShowDetailClick = this.onShowDetailClick.bind(this);
	}

	render() {
		const {order} = this.props;

		let statusDay = '';
		let statusMonthYear = '';
		let orderSummaryDateLabel = '';
		let orderSummaryMonthYearLabel = '';
		let showOrderDate = true;

		if(order.orderCompletedDate && order.orderStatus.toLowerCase() === "complete"){
			orderSummaryDateLabel = 'Order';
			orderSummaryMonthYearLabel = 'completed date:';
			statusDay = extractDayWithSuffix(order.orderCompletedDate); 
			statusMonthYear = extractMonthYear(order.orderCompletedDate);
		} else if(order.orderCompletedDate && order.orderStatus.toLowerCase() === "cancelled"){
			orderSummaryDateLabel = 'Order';
			orderSummaryMonthYearLabel = 'cancelled on:';
			statusDay = extractDayWithSuffix(order.orderCompletedDate); 
			statusMonthYear = extractMonthYear(order.orderCompletedDate);
		} else if(order.telstraCommittedDate){
			orderSummaryDateLabel = 'Expected';
			orderSummaryMonthYearLabel = 'connection date:';
			statusDay = extractDayWithSuffix(order.telstraCommittedDate); 
			statusMonthYear = extractMonthYear(order.telstraCommittedDate);
		} else if(order.orderCreatedDate){
			orderSummaryDateLabel = 'Order';
			orderSummaryMonthYearLabel = 'created date:';
			statusDay = extractDayWithSuffix(order.orderCreatedDate); 
			statusMonthYear = extractMonthYear(order.orderCreatedDate);
		} else {
			showOrderDate = false;
		}

		const orderNumber = order.eBPMRefNumber ? order.eBPMRefNumber : order.orderId;
		let planStatusClassName = 'plan-status-td';
		if (order.orderStatus.toLowerCase().includes('complete')) {
			planStatusClassName += ' completed';
		} else if (order.orderStatus.toLowerCase().includes('cancel')) {
			planStatusClassName += ' cancelled';
		}

		return (
			<div className="order">
				<Row collapse expanded>
					<Column>
						<h2 className="plan-name-td">
							{order.orderDescription}
						</h2>
						<div className={planStatusClassName}>
							{order.orderStatus}
						</div>
					</Column>
				</Row>
				<Row collapse expanded>
					<Column className="plan-detail-item-td">
						<div>
							Order number:
						</div>
						<div>
							{orderNumber}
						</div>
					</Column>
				</Row>
				<Row collapse expanded>
					{
						showOrderDate ? (
							<Column className="plan-detail-item-td">
									<div>
										{orderSummaryDateLabel} {orderSummaryMonthYearLabel}
									</div>
									<div>
											{statusDay} {statusMonthYear}
									</div>
							</Column>
						) : null
					}
				</Row>
				<div>
					<a
						onClick={this.onShowDetailClick(orderNumber)}
						className="plan-show-details-link-td"						
					>
						<span>Show details</span>
						<span className='right-arrow'></span>
					</a>
				</div>
			</div>
		);
	}

	onShowDetailClick(orderNumber) {
		return function(e) {
			const link = window.env.REACT_APP_HOST_NAME + `/orderdetails/${orderNumber}`;
			openInNewTab(e, link);
		}
	}
}
	
export default Order;
