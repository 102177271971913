import * as client from "../adapters/client";
const REQUEST_ORDERS = 'REQUEST_ORDERS';
const RECEIVE_ORDERS = 'RECEIVE_ORDERS';
const RECEIVE_ORDERS_ERROR = 'RECEIVE_ORDERS_ERROR';
const RECEIVE_ORDERS_TOKEN_EXPIRED = 'RECEIVE_ORDERS_TOKEN_EXPIRED';

export function requestOrders() {
    return {
        type: REQUEST_ORDERS
    }
}

export function receiveOrders(json) {
   
    return {
        type: RECEIVE_ORDERS,
        orders: json.data.orders,
        isDevAuthByPass: json.data.isAuthBypass
    }
}

export function receiveOrdersError(status) {
     return {
        type: status === 401 ? RECEIVE_ORDERS_TOKEN_EXPIRED : RECEIVE_ORDERS_ERROR,
    }
}

export function fetchOrders(accessToken = null, fetcher = client.fetchOrders) {
    return (dispatch) => {
        dispatch(requestOrders());
        fetcher(dispatch, receiveOrders, receiveOrdersError, accessToken);
    }
}

export default function reducer(state = {}, action) {
    switch (action.type) {
        case REQUEST_ORDERS:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                isExpired: false
            });
        case RECEIVE_ORDERS:
            return Object.assign({}, state, {
                isFetching: false,
                orders: action.orders,
                isDevAuthByPass: action.isDevAuthByPass
            });
       case RECEIVE_ORDERS_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true
            });
        case RECEIVE_ORDERS_TOKEN_EXPIRED:
        return Object.assign({}, state, {
                isFetching: false,
                isExpired: true
            });    
        default:
            return state
    }
}