import React, { Component } from "react";
import StatusIconTD from "./StatusIconsTD";
import TimelineMilestone from "./TimelineMilestonesTD";
import iconInfo from "../../images/icon-info-blue.svg";
import * as constants from "../Constants";

export default class ChangePlanTD extends Component {
  render() {
    const { updatePlanTimeline } = this.props;

    if (updatePlanTimeline) {
      return (
        <TimelineMilestone
          title={updatePlanTimeline.label}
          icon={
            updatePlanTimeline.updatePlanTimelineStatus && (
              <StatusIconTD
                uniqueIdentifier="Hardware-Delivery"
                status={updatePlanTimeline.updatePlanTimelineStatus}
              />
            )
          }
          lineColor={
            updatePlanTimeline.updatePlanTimelineStatus &&
            updatePlanTimeline.updatePlanTimelineStatus.toLowerCase() !==
              "complete"
              ? "#d8d8d8"
              : "#0064D2"
          }
          subtitleStyle={{ "paddingTop": "10px", "paddingBottom": "5px" }}
          subtitle={
            updatePlanTimeline.label &&
            updatePlanTimeline.label.toLowerCase() ===
              constants.PLAN_UPGRADE ? (
              <div className="progress-label">
                <div>
                  {updatePlanTimeline.newPlanText
                    ? updatePlanTimeline.newPlanText
                    : ""}
                </div>

                <div>
                  {updatePlanTimeline.oldPlanText
                    ? updatePlanTimeline.oldPlanText
                    : ""}
                </div>
              </div>
            ) : updatePlanTimeline.label &&
              updatePlanTimeline.label.toLowerCase() ===
                constants.PLAN_DOWNGRADE ? (
              <div className="progress-label">
                <div>
                  {updatePlanTimeline.oldPlanText
                    ? updatePlanTimeline.oldPlanText
                    : ""}
                </div>

                <div>
                  {updatePlanTimeline.newPlanText
                    ? updatePlanTimeline.newPlanText
                    : ""}
                </div>
              </div>
            ) : (
              ""
            )
          }
        >
          {updatePlanTimeline.updatePlanTimelineStatus && updatePlanTimeline.updatePlanTimelineStatus.toLowerCase() ===
            constants.IN_PROGRESS &&
          updatePlanTimeline.updatePlanTimelineFooterText ? (
            <>
              <img src={iconInfo} alt="info" />
              {updatePlanTimeline.updatePlanTimelineFooterText}
            </>
          ) : (
            <></>
          )}
        </TimelineMilestone>
      );
    }
  }
}
