import React from 'react';
import telstraLogo from '../../images/telstra-logo.svg';
import chevronRight from '../../images/ChevronRightTD.svg';
import { handleSignInSignOut, route } from "../../utils";
import chevronLeft from '../../images/Chevron left@1.5xTD.svg'
import crossIconTD from '../../images/crossIconTD.svg'
import mobileMenuIconTD from '../../images/Menu@1.5xTD.svg';
import * as utils from '../../utils';

export const Breadcrumbs = () => (
  <div className='module-navigation-breadcrumb responsive-width'>
    <nav aria-label="Breadcrumb">
      <ul>
        <li className='breadcrumb-wrap'>
          <span className='separator back' aria-hidden="true">
            <img src={chevronLeft} alt='chevronLeft' className="breadcrumb-chevron-left" />
          </span>
          <span className='breadcrumb'>
            <a href='https://www.telstra.com.au/' tabIndex={0}>Home</a>
          </span>
        </li>
        <li className='breadcrumb-wrap' aria-current="page">
          <span className='separator' aria-hidden="true">
            <img src={chevronRight} alt='chevronRight' />
          </span>
          <span className='breadcrumb' tabIndex={0}>
            Track an order
          </span>
        </li>
      </ul>
    </nav>
  </div>
);

const Navbar_Segment1 = () => (
  <nav className='nav-segment nav-1' aria-label="main">
    <ul className='segmented-nav-list'>
      <li className='icons-logo-blue'>
        <a href=' https://www.telstra.com.au/' className='telstra-logo'>
          <img src={telstraLogo} alt='Telstra Logo' />
        </a>
      </li>
      <li>
        <a href='https://www.telstra.com.au/'>Personal</a>
      </li>
      <li>
        <a href='https://www.telstra.com.au/small-business'>Business</a>
      </li>
      <li>
        <a href='https://www.telstra.com.au/business-enterprise'>Enterprise</a>
      </li>
    </ul>
  </nav>
);

export const Navbar_Segment2 = ({ accessToken, location }) => (
  <nav className='nav-segment nav-2' aria-label="Admin or Site Tools">
    <ul className='segmented-nav-list'>
      <li>
        <a href='https://www.telstra.com.au/plus'>
          Telstra Plus
        </a>
      </li>
      <li>
        <a href='https://www.telstra.com.au/support'>Help</a>
      </li>
      <li>
        <a href='#' onClick={(e) => handleSignInSignOut(e, location, accessToken)}>
          {
            accessToken === null || accessToken === undefined ? 'Sign in' : 'Sign out'
          }
        </a>
      </li>
    </ul>
  </nav>
)


const NavbarDesktop = ({ accessToken, location  }) =>
  <div className='parent-container desktop-navbar'>
    <Navbar_Segment1 />
    <Navbar_Segment2 accessToken={accessToken} location={location}/>
  </div>


const MobileOverlay = () =>
  <ul className="mobile-overlay">
    <li>
      <a href='https://www.telstra.com.au/'>Personal</a>
    </li>
    <li>
      <a href='https://www.telstra.com.au/small-business'>Business</a>
    </li>
    <li>
      <a href='https://www.telstra.com.au/business-enterprise'>Enterprise</a>
    </li>
    <li>
      <a href='https://www.telstra.com.au/plus'>
        Telstra Plus
      </a>
    </li>
    <li>
      <a href='https://www.telstra.com.au/support'>Help</a>
    </li>
  </ul>



const NavbarMobile = ({ accessToken, location }) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  React.useEffect(() => {
    const body = document.getElementsByTagName("BODY")[0]
    if (menuOpen)
      body.style.overflowY = "hidden"
    else
      body.style.overflowY = "unset"
  }, [menuOpen])
  
  return (
    <React.Fragment>
      <div className="mobile-navbar" style={menuOpen ? { backgroundColor: "#F4F4F4" } : {}}>
        <a href=' https://www.telstra.com.au/' className='mobile-telstra-logo'>
          <img src={telstraLogo} alt='Telstra Logo' />
        </a>
        <div className="right-functions">
          {!menuOpen &&
            <a href='#' onClick={(e) => handleSignInSignOut(e, location, accessToken)}>
              {
                accessToken === null || accessToken === undefined ? 'Sign in' : 'Sign out'
              }
            </a>
          }
          <img src={menuOpen ? crossIconTD : mobileMenuIconTD} alt="menuIcon" className="mobile-menu-icon" onClick={() => setMenuOpen(s => !s)} style={menuOpen ? { cursor: 'pointer' } : {}} />
        </div>
      </div>
      {menuOpen && <MobileOverlay />}
    </React.Fragment>
  )
}


export default class Navbar_Container extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll() {
    const sticky = document.querySelector('.sticky');
    if (sticky && sticky.style.position !== 'sticky') {
      window.scrollY >= 80
        ? sticky.classList.add('fixed')
        : sticky.classList.remove('fixed');
    }
  }
  
  render() {
    return (
      <div className='color-gradient sticky'>
        <NavbarDesktop accessToken={this.props.accessToken} location={this.props.location}/>
        <NavbarMobile accessToken={this.props.accessToken} location={this.props.location}/>
      </div>
    );
  }
}
