import React, {Component} from "react";
import {openInNewTab} from "../../utils";
import StatusIconTD from "./StatusIconsTD";
import startrackIcon from "../../images/Star Track.svg";
import auspostIcon from "../../images/icon-auspost.svg";
import * as constants from "../Constants";
import TimelineMilestone from './TimelineMilestonesTD'
import { ActionButton } from "@able/react";
import errorIcon from '../../images/icon-error.svg'
import * as analytics from '../../analytics-utils';
import infoIcon from '../../images/icon-info-blue.svg';

class HardwareDelivery extends Component {
    render() {
        const {hardwareDelivery, orderCategory} = this.props;
        this.renderLogisticDetails = this.renderLogisticDetails.bind(this);
        this.renderStatusIcon = this.renderStatusIcon.bind(this);
console.log('hardwareDelivery', hardwareDelivery);
        if(hardwareDelivery) {
        return (<TimelineMilestone
            title= {hardwareDelivery.shipmentLabel}
            icon={ hardwareDelivery.iconStatus &&
                <StatusIconTD uniqueIdentifier="Hardware-Delivery" status={hardwareDelivery.iconStatus}/>
            }
            lineColor={hardwareDelivery.iconStatus && hardwareDelivery.iconStatus.toLowerCase() !== constants.COMPLETED && hardwareDelivery.iconStatus.toLowerCase() !== constants.COMPLETE ? '#d8d8d8': '#0064D2'}
            createdAt={
            <>
                {hardwareDelivery.displayStatus &&  (
                <>
                    {((hardwareDelivery.iconStatus.toLowerCase() === 'problem') ||
                    (hardwareDelivery.iconStatus.toLowerCase() === 'error') ||
                    (hardwareDelivery.iconStatus.toLowerCase() === 'failed')) &&
                    <img src={errorIcon} className="small-icon-td-error" alt="Info Icon" />}
                    <span id="Shipment-Status-Text">{hardwareDelivery.displayStatus}</span>
                </>
                )}
                {hardwareDelivery.hardwareStatusText && (
                    hardwareDelivery.hardwareShipmentDate && (
                    <>
                        <br/>
                        <span> {hardwareDelivery.hardwareStatusText}: </span>
                        <span> {hardwareDelivery.hardwareShipmentDate}</span>
                    </>
                    )
                )}
            </>}
            >
              {hardwareDelivery.shipmentHardwareDetails && (
                  <div className="progress-details hardware-items">
                    <ul>{this.renderProductNames(hardwareDelivery.shipmentHardwareDetails)}</ul>
                  </div>)}
                  
                {hardwareDelivery.storePickupLabel  && hardwareDelivery.iconStatus && hardwareDelivery.iconStatus.toLowerCase() !== constants.COMPLETED  ?
                    <div className="store-pickup-label"><span> {hardwareDelivery.storePickupLabel} </span></div>
                     :  hardwareDelivery.storeName && this.renderStoreAddress()
                }

                {this.renderShipmentDisplayMessage()}

              {hardwareDelivery.shipmentHardwareDetails && hardwareDelivery.shipmentHardwareDetails[0].deliveryAddressLine1 && (
                  <div id="Hardware-Delivery-Label"className="progress-label">To be delivered to
                  {hardwareDelivery.shipmentHardwareDetails && (
                        <div className="progress-details hardware-items">
                          <span>{this.renderDeliveryAddress(hardwareDelivery)}</span>
                       </div>
                  )}
                  </div>)}
              {this.renderLogisticDetails()}

              {hardwareDelivery.showHardwareStatusErrorMessage === true && (
                 <div id ="hardware-status-error" className="flex-row message-flex">
                        <img src={infoIcon} className="shipment-message-icon small-icon-td-error" id="hardware-status-error-icon" alt="Error Icon" />
                        <span id="hardware-status-error-message">We’re unable to find the tracking information for your order at this time, please check again later. For further support please use the Get Help section in the My Telstra app.</span>
                 </div>
              )}

              {orderCategory.toLowerCase()!==constants.ORDER_CATEGORY_LOYALTY && hardwareDelivery.selfInstallLink && (
                  <div className="self-install">
                    <ActionButton element="button" variant="LowEmphasis" onClick={e => this.onSIKClick(e)} label="Self install instructions" />
                  </div>)}
           </TimelineMilestone>
        
        )
    }
    return null;
 }
 

 renderStoreAddress() {
    const {hardwareDelivery} = this.props;    

    let storeCompleteAddress;
    storeCompleteAddress =  hardwareDelivery.storeStreetAddress ? hardwareDelivery.storeStreetAddress : "";
    storeCompleteAddress = hardwareDelivery.storeSuburbAddress ? storeCompleteAddress +  ", " +hardwareDelivery.storeSuburbAddress : storeCompleteAddress;
   
    let storeAddressLines = [];

    if(hardwareDelivery.storeName){
        storeAddressLines.push(<span id="Shipment-Store-StoreNameLabel">Store name:</span>);
        storeAddressLines.push(<p id="Shipment-StoreName">{hardwareDelivery.storeName}</p>);
    }    
    if(storeCompleteAddress){
        storeAddressLines.push(<span id="Shipment-Store-StreetAddressLabel">Store address:</span>);
        storeAddressLines.push(<p id="Shipment-Store-StreetAddress">{storeCompleteAddress}</p>);
    }
     return storeAddressLines;
}
 renderShipmentDisplayMessage() {
    const {hardwareDelivery} = this.props;
    let icon = infoIcon;
    if(hardwareDelivery.iconStatus && 
        (hardwareDelivery.iconStatus.toLowerCase() === 'problem' ||
        hardwareDelivery.iconStatus.toLowerCase() === 'error' ||
        hardwareDelivery.iconStatus.toLowerCase() === 'failed') ) {
            icon = errorIcon;
    }
    if(hardwareDelivery.displayMessage) {
        return <>    
            <div id ="Shipment-Message-Content" className="flex-row message-flex">        
                <img src={icon} className="shipment-message-icon small-icon-td-error shipment-message-icon" id="ShipmentMessageIcon" alt="Info Icon" />
                <span id="Shipment-Message">{hardwareDelivery.displayMessage}</span>
            </div>
        </>
    } 
    
  }
    onSIKClick(e) {
        const {hardwareDelivery, orderDetails} = this.props;
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.SIK_CLICK_ANALYTICS, orderDetails);        
        openInNewTab(e,hardwareDelivery.selfInstallLink);
    }    
    renderProductNames(shipmentHardwareDetails){
        var productNames = [];
        if(shipmentHardwareDetails){

        for (var i of shipmentHardwareDetails) {
            if(i.productName && i.productName !== undefined) {
                productNames.push(<li id="Hardware-Delivery-Item" key={i.productName}>{i.productName}</li>);
            }
          }
        }
          return productNames;
    }

    renderDeliveryAddress(hardwareDelivery){
        const {counter, telstraAppointment, orderId, isUnauthorized} = this.props;
        var addressLines = [];
        if(hardwareDelivery.shipmentHardwareDetails){
            if(isUnauthorized!=true)
            {
                if(!hardwareDelivery.shipmentTrackingURL)
                {
                    if(hardwareDelivery.shipmentHardwareDetails[0].deliveryAddressLine1)
                    addressLines.push(<p id="Hardware-Delivery-Item">{hardwareDelivery.shipmentHardwareDetails[0].deliveryAddressLine1}</p>);
                    if(hardwareDelivery.shipmentHardwareDetails[0].deliveryAddressLine2)
                    addressLines.push(<p id="Hardware-Delivery-Item">{hardwareDelivery.shipmentHardwareDetails[0].deliveryAddressLine2}</p>);
                    if(hardwareDelivery.shipmentHardwareDetails[0].deliveryAddressLine3)
                    addressLines.push(<p id="Hardware-Delivery-Item">{hardwareDelivery.shipmentHardwareDetails[0].deliveryAddressLine3}</p>);
                }
            }
        }
        return addressLines;
    }

    renderLogisticDetails() {
        const {hardwareDelivery} = this.props;
        const consignment = hardwareDelivery.consignmentId;

        if(!consignment){
            return '';
        }

        if (hardwareDelivery.showStarTrack) {
            return (
            <div className="hardware-delivery-startrack">
                {hardwareDelivery.status && hardwareDelivery.status.toLowerCase() !== constants.COMPLETED && this.props.orderCategory && this.props.orderCategory.toLowerCase() === "nbn"?
                <div id="authorityForDeliveryImgDiv">
                    <span className="hardware-delivery-text-td">This delivery has authority to be left at your delivery address</span>
                </div> : '' }
                <div className="logistic-panel-td">
                    <div className="logistic-img-box">
                        <img id="Star-Track-Icon" className="logistic-img-td" src={startrackIcon} alt="Startrack Icon"/>
                    </div>
                    <div className="flex-column">
                        <h3 id="Start-Track-Text" className="logistic-label-td">{constants.STARTRACK}</h3>
                        <a
                          id="Star-Track-Link"
                          href={hardwareDelivery.shipmentTrackingURL}
                          className="logistic-link-td"
                          onClick={(e)=>this.onTrackDeliveryClick(e)}>
                            <span id="Tracking-Url" className="tracking-url">{constants.TRACK_DELIVERY}</span>
                        </a>
                    </div>
                </div>
            </div>
            )
        }else if(hardwareDelivery.showAusPostIcon){
            return (
                <div className="logistic-panel-td">
                        <div className="logistic-img-box">
                            <img id="Australia-Post-Icon" className="logistic-img-td" src={auspostIcon} alt="Australia Post Icon"/>
                        </div>
                        <div className="flex-column">
                            <span id="Australia-Post-Text" className="logistic-label-td">{constants.AUS_POST}</span>
                            <a
                              id="Australia-Post-Link"
                              href={hardwareDelivery.shipmentTrackingURL}
                              className="logistic-link-td" onClick={(e)=>this.onTrackDeliveryClick(e)}>
                                <span id="Tracking-Url" className="tracking-url">{constants.TRACK_DELIVERY}</span>
                            </a>
                        </div>
                   </div>
            );
        }else{
            return '';
        }
    }

    getNodes(equalizerComponent, equalizerElement) {
        return [
          this.refs.node1,
          this.refs.node2
        ]
    }

    renderStatusIcon(){
        const {hardwareDelivery} = this.props;
        return hardwareDelivery.iconStatus ?
        <div id="Hardware-Delivery-State" className={hardwareDelivery.status.toLowerCase() === constants.IN_PROGRESS ? 'progress-state-big' : 'progress-state-normal'}>
            <StatusIconTD uniqueIdentifier="Hardware-Delivery" status={hardwareDelivery.iconStatus}/>
        </div> : '';
    }
    renderShipmentDisplayStatus() {
        const {hardwareDelivery} = this.props;
        return <div className="progress-details" id="Display-Status-Text">
                {hardwareDelivery.displayStatus ?
                <div id="Shipment-Display-Status-Text">{hardwareDelivery.displayStatus}<br />
                </div> : ''}
            </div>
    }

    onTrackDeliveryClick(e) {
        const {hardwareDelivery, orderDetails} = this.props;
        console.log('orderDetails in h/w event', orderDetails)
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.TRACK_YOUR_DELIVERY_CLICK_ANALYTICS, orderDetails);        
        openInNewTab(e, hardwareDelivery.shipmentTrackingURL)
    }
}

export default HardwareDelivery;
