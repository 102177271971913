import React, {Component} from "react";
import * as ReactDOM from "react-dom";

const usabillaWidgetId = () => {
    return window.env.REACT_APP_USABILLA_WIDGET_ID || '59faa692da00da6fa743ac8f';
};

class UsabillaWidget extends Component {
    componentDidMount() {
        ReactDOM.findDOMNode(this.usabilla).setAttribute('ub-in-page', usabillaWidgetId());
    }

    render() {
        return (
            <div className="app-content">
                <div ref={(ref) => this.usabilla = ref} className="feedbackmy"/>
            </div>    
        );
    }
};

export default UsabillaWidget;
