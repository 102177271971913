import React from 'react';
import chevronRight from '../../images/ChevronRightTD.svg';
import chevronLeft from '../../images/Chevron left@1.5xTD.svg'

const Breadcrumbs = () => (
  <div className="inner-container">
    <div className='module-navigation-breadcrumb responsive-width'>
      <nav aria-label="Breadcrumb">
        <ul>
          <li className='breadcrumb-wrap'>
            <span className='separator back' aria-hidden="true">
              <img src={chevronLeft} alt='breadcrumb-chevron-left' className="breadcrumb-chevron-left" />
            </span>
            <span className='breadcrumb'>
              <a href='https://www.telstra.com.au/' tabIndex={0}>Home</a>
            </span>
          </li>
          <li className='breadcrumb-wrap' aria-current="page">
            <span className='separator' aria-hidden="true">
              <img src={chevronRight} alt='chevronRight' />
            </span>
            <span className='breadcrumb' tabIndex={0}>
              Dashboard
            </span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
);

export default Breadcrumbs;
