import React from 'react';
import { browserHistory } from 'react-router';
import IdleTimer from 'react-idle-timer';
import { ActionButton } from '@able/react';
import { SESSION_ACTIVITY_TIMEOUT, SESSION_USER_INPUT_WINDOW_TIMEOUT } from '../Constants';
import { handleSignInSignOut, logout } from '../../utils';

const initialState = {isVisible: false, remaining: null}

export default class SessionTimeout extends React.Component {

  constructor(props) {
    super(props);
    this.activityTimeout = SESSION_ACTIVITY_TIMEOUT * 60;
    this.userInputWindowTimeout = SESSION_USER_INPUT_WINDOW_TIMEOUT * 60; // in seconds
    this.idleTimer = null;

    this.state = initialState;

    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.handleContinueBtnClick = this.handleContinueBtnClick.bind(this);
    this.updateCountDown = this.updateCountDown.bind(this);
  }

  componentWillUnmount() {
    this.clearInterval();
  }

  clearInterval() {
    this.intervalCounter && clearInterval(this.intervalCounter);
  }

  reset() {
    this.idleTimer && this.idleTimer.reset();
    this.setState(initialState);
    this.clearInterval();
  }

  updateCountDown() {
    const {remaining} = this.state;

    // Continue btn not clicked in given interval. Automatically logout now.
    if(remaining <= 1) {
      this.reset();
      console.log('Logging out automatically.');
      logout();
    }

    this.setState({...this.state, remaining: remaining - 1});
  }

  handleOnIdle() {
    // Modal is visible and interval counter is already ticking.
    if(this.state.isVisible) return;

    console.log('Showing popup and setting up countdown timer.');
    this.setState({...this.state, isVisible: true, remaining: this.userInputWindowTimeout})
    this.intervalCounter = setInterval(this.updateCountDown, 1000);
  }

  handleContinueBtnClick() {
    // Continue btn clicked. Make a silent call to extend current session or login if required.
    this.reset();
    const currentLocationPath = browserHistory.getCurrentLocation().pathname;
    console.log(`Calling caiman to extend session or login if required. redirectURI: ${currentLocationPath}`);
    handleSignInSignOut(null, currentLocationPath, null, true);
  }

  render() {
    const {isVisible, remaining} = this.state;
    const minutes = remaining && parseInt(remaining / 60);
    const seconds = remaining && (remaining % 60);

    return (
      <React.Fragment>
        <IdleTimer 
          timeout={this.activityTimeout * 1000} // timeout in milli-seconds
          ref={ref => this.idleTimer = ref}
          onIdle={this.handleOnIdle}
        />

        {isVisible && (
          <div className="modal-container">
            <div className="session-timeout-popup">
              <h3>Your session appears to be inactive. For security reasons, your session will time out in:</h3>
              <h2><span>{minutes} minutes,</span> <span>{seconds} seconds</span></h2>
              <div>
                <ActionButton element="button" label="Continue your session" variant="MediumEmphasis" onClick={this.handleContinueBtnClick} />
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}
