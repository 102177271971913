import React from 'react';
// import queryString from "query-string";
import ClearInputTD from '../../images/ClearInputTD.svg';
import AlertIconTD from '../../images/Alert SmallTD.svg';
import { verifyOrderId, route } from '../../utils';
import { ERROR_MESSAGE_INCORRECT_ORDER_ID } from '../../constants'
import { ActionButton } from '@able/react';


export const invalidOrderFormatError = () => {
  return (
    <span className='invalid-order-message'>
      <p className='err-message-prompt-icon-container'>
        <img
          src={AlertIconTD}
          alt='alertIcon'
          className='err-message-prompt-icon'
        />
      </p>
      <p className='input-error-msg' role="alert">
        {ERROR_MESSAGE_INCORRECT_ORDER_ID}
      </p>
    </span>
  )
}

export default class TrackerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNumber: '',
      invalidOrderFormat: false,
      retrieveError: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    localStorage.removeItem('searchOrderId');   
    localStorage.removeItem('orderNumber');   
  }

  handleInputChange(e) {
    // e.persist();
    this.setState({ invalidOrderFormat: false, orderNumber: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.displayLoaderOnSearch(true);
    const orderNumber = this.state.orderNumber.toUpperCase().trim();
    localStorage.setItem('searchOrderId', orderNumber);    
    let result = verifyOrderId(orderNumber);    
    if (result) {
      this.setState((cs) => ({ ...cs, invalidOrderFormat: false }));
      //with TelstraAuth

      if (this.props.accessToken) {
        this.props.fetchAuthOrderDetails(orderNumber, this.props.accessToken);
      } else {       
          this.props.fetchUnauthOrderDetails(orderNumber);
        
      }

      // without TelstraAuth

      // this.props.subscribeToAuthEvent();
      // let hashParams = queryString.parse(this.props.location.hash);

      // if(hashParams && hashParams.access_token){
      //     this.props.fetchOrders(hashParams.access_token);
      //     this.firstTime = false;
      // }

      // route(undefined, "/orderdetails/" + orderNumber);
    }
    else {
      this.props.displayLoaderOnSearch(false);
      this.setState((cs) => ({ ...cs, invalidOrderFormat: true }));
    }
  }

  handleMouseDownEvent(event) {
    const root = document.documentElement
    let el = event.target, top = 0, left = 0;
    do {
      top += el.offsetTop;
      left += el.offsetLeft;
    } while (el = el.offsetParent);
    const x = (event.clientX - left) / event.target.offsetWidth;
    const y = (event.clientY - top) / event.target.offsetHeight;
    // const y = (event.clientY - el.offsetTop) / el.offsetHeight;
    root.style.setProperty("--ripple-x", x);
    root.style.setProperty("--ripple-y", y);
  }

  render() {
    const { invalidOrderFormat, orderNumber } = this.state;
    return (
      <div className='tracker-form p-btm-7 responsive-width'>
        <p className='m-btm-2'>
          Use your order number below to check the status of your order.
        </p>
        <form onSubmit={this.handleSubmit} style={{ position: 'relative' }}>
          <div className='group'>
            <label id="Order-Search-Textbox-Label" for="Order-Search-Textbox" hidden={true}>Enter Order Number</label>
            <input
              type='text'
              className='group-input'
              id="Order-Search-TextBox"
              aria-labelledby="Order-Search-Textbox-Label"
              value={orderNumber}
              onChange={this.handleInputChange}
              required
            />
            <img
              src={ClearInputTD}
              alt='clearInput'
              className='clear-input'
              onClick={() =>
                this.setState({ orderNumber: '', invalidOrderFormat: false })
              }
            />
            <label className='group'>Enter order number</label>
          </div>
          {(invalidOrderFormat) && (
            invalidOrderFormatError()
          )}
          {/* <button type='submit' className='m-top-2 m-btm-3' onMouseDown={this.handleMouseDownEvent}>
            Track my order
          </button> */}
          <ActionButton element="button" variant="HighEmphasis" label="Track my order" type='submit' className='m-top-2 m-btm-3' id="Order-Search-Button" />
        </form>
        <div className='suggestions-block'>
          <h3>CAN'T FIND YOUR ORDER NUMBER?</h3>
          <p className='m-top-1'>
            It's on the order confirmation email or SMS you receive when we get
            your order.
          </p>
          <p>
            You can also find it, and track your order, through My Telstra.
          </p>
        </div>
      </div>
    );
  }
}

