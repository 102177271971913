import React, { Component } from "react";
import { Row, Column } from "react-foundation-components/lib/global/grid";
import covidHeaderClose from "../images/covid-header-close.svg";
import * as constants from "./Constants";
import { openInNewTab, isMobileDevice, isIpadDevice } from "../utils";

class CovidComponent extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.showCovidHeader()}
                {this.showCloseBtnForCovid()}
                {/* {this.showLinearGradientForCovidHeader()} */}
            </div>
        );
    }

    showCovidHeader() {

        return <section className="covid-section-main">

            <section className="covid-section">
                <div className="covid-parent-first">
                    {isMobileDevice() || isIpadDevice() ?
                       <div className="covid-text">{constants.COVID_MSG} <a className="tell-me-more-link" href={constants.COVID_TELL_ME_MORE} onClick={(e) => openInNewTab(e, constants.COVID_TELL_ME_MORE)}> Tell me more.</a></div>
                    :  <div className="covid-text">{constants.COVID_MSG}</div>
                    }
                </div>

            </section>
            {isMobileDevice() || isIpadDevice() ?
                ""
            :
            <section  className="covid-section">
                <div className="covid-parent-second">
                    <div className="covid-text">Regional orders may take longer. We’re doing everything we can to get your order to you quickly. <a className="tell-me-more-link" href={constants.COVID_TELL_ME_MORE} onClick={(e) => openInNewTab(e, constants.COVID_TELL_ME_MORE)}> Tell me more.</a></div>
                </div>
            </section>
            }
        </section>
    }

    // showLinearGradientForCovidHeader() {
    //     return <div className="covid-gradient"></div>
    // }

    showCloseBtnForCovid() {
        return <div className="close-covid-btn" onClick={this.props.closeCovidBanner}>
            <img className="close-covid-btn" src={covidHeaderClose} alt="Close Notification" />
        </div>
    }

}
export default CovidComponent;
