import React, { Component } from "react";
import HardwareDeliveryTD from "./HardwareDeliveryTD";
import TelstraAppointmentTD from "./TelstraAppointmentTD";
import OrderProcessedTD from "./OrderProcessedTD";
import NbnAppointmentTD from "./NbnAppointmentTD";
import UnitiAppointmentTD from "./UnitiAppointmentTD";
import ASIKAppointmentTD from './ASIKAppointmentTD';
import OrderCompleteTD from "./OrderCompleteTD";
import DisconnectTD from "./DisconnectTD";
import MobileOrderProgressTD from "./MobileOrderProgressTD";
import MilestoneTimelineTD from "./MilestoneTimelineTD";
import Timeline from './TimelineTD';
import HardwareReturnTD from "./HardwareReturnTD";
import * as constants from "../Constants";
import ChangePlanTD from "./ChangePlanTD";
import {MODEM_PLUGIN_DETAILS} from "../Constants";
import ModemPlugInTD from "./ModemPlugInTD";
import TransferRequestDetail from "./TransferRequestDetail";
import NumberTransferDetail from "./NumberTransferDetail";
import IDDPackTD from "./IDDPackTD";
import ServiceUpgradeTD from './ServiceUpgradeTD';
import ChownTimelineTD from './ChownTimelineTD';

class OrderProgress extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { orderDetails, isUnauthorized } = this.props;
        let orderCategoryValue = null;
        if (orderDetails && orderDetails.orderSummary && orderDetails.orderSummary.orderCategory) {
            orderCategoryValue = orderDetails.orderSummary.orderCategory.toLowerCase();
            if (orderDetails.orderSummary.orderCategory.toLowerCase().startsWith(constants.ORDER_CATEGORY_SEMI_SUPPORTED_NBNSIEBEL_PREFIX)) {
                orderCategoryValue = constants.NBN_SIEBEL_SEMISUPPORTED;
            }
        }
        
        if (orderCategoryValue) {
            switch (orderCategoryValue) {
                case constants.ORDER_CATEGORY_NBN:
                case constants.BROADBAND_CABLE:
                case constants.BROADBAND_ADSL:
                case constants.BROADBAND_VELOCITY:
                case constants.PSTN:
                case constants.PLATINUM:
                case constants.HARDWARE_ONLY:
                    return this.renderForNBNandTelstra();
                case constants.ORDER_CATEGORY_MOBILE_PREORDER:
                case constants.ORDER_CATEGORY_MOBILE_POSTPAID:
                    return <MobileOrderProgressTD
                              key="preOrder"
                              isAuthenticated={this.props.isAuthenticated}
                              isUnauthorized={isUnauthorized}
                              orderCategory={orderCategoryValue}
                              orderTimeline={orderDetails.orderTimeline}
                              hardwareDelivery={orderDetails.hardwareDelivery ? orderDetails.hardwareDelivery : null}
                              accessToken = {this.props.accessToken}
                              location={this.props.location}/>;
                case constants.ORDER_CATEGORY_PREPAID: {
                    return orderDetails.orderSummary.milestones
                    && orderDetails.orderSummary.milestones!=''
                    ?
                     this.renderForPrepaid()
                     :
                     this.renderForEOV()
                }
                case constants.ORDER_CATEGORY_SEMI_SUPPORTED_SUBSCRIPTION:
                case constants.ORDER_CATEGORY_SIM_SWAP_DISPATCH:
                case constants.ORDER_CATEGORY_MOBILE_SUBSCRIPTION:
                case constants.ORDER_CATEGORY_LOYALTY:
                case constants.ORDER_CATEGORY_LOYALTY_BAU:
                     return this.renderForEOV();
                case constants.HEADLESS:
                case constants.NEXTGEN_OSM:
                case constants.NEXTGEN_OSM_HDW:
                case constants.HEADLESS_PREORDER:
                case constants.FIXED:
                    return this.renderForHeadlessAndFixed();
                case constants.ORDER_CATEGORY_SEMI_SUPPORTED:
                    return this.renderForSemiSupported();
                case constants.NBN_SIEBEL_SEMISUPPORTED:
                default:
                    return null;
            }
        }

        
        return <React.Fragment></React.Fragment>

    }


    renderForPrepaid(){
        const { orderDetails } = this.props;
        this.renderOrderStepsForPrepaid = this.renderOrderStepsForPrepaid.bind(this);

        return (
          <Timeline>
            {this.renderOrderStepsForPrepaid()}
          </Timeline>
        );

    }

    renderForNBNandTelstra() {
      const { orderDetails } = this.props;
        this.renderOrderSteps = this.renderOrderSteps.bind(this);
        return (
            <Timeline style={{ width: '100%' }}>
              <OrderProcessedTD orderProcessedDate={orderDetails.orderProcessedDate} />
              {
                orderDetails.orderSummary
                && (orderDetails.orderSummary.orderStatus.toLowerCase() === constants.CANCELLED)
                    ? <></>
                    : this.renderOrderSteps()
              }
              <OrderCompleteTD
                  nbnHealthCheckDetail={orderDetails.nbnHealthCheckDetail}
                  guaranteeMessageDetail={orderDetails.guaranteeMessageDetail}
                  showFooter={orderDetails.showFooter}
                  orderCompletionDate={orderDetails.orderCompletionDate}
                  orderStatus={orderDetails.orderSummary ? orderDetails.orderSummary.orderStatus : ''}
                  orderCategory={orderDetails.orderSummary && orderDetails.orderSummary.orderCategory ? orderDetails.orderSummary.orderCategory : ''} />
            </Timeline>
        );
    }

    renderForTelstra() {
        const { orderDetails } = this.props;
        this.renderOrderStepsForTelstra = this.renderOrderStepsForTelstra.bind(this);

        return (
            <Timeline>
              <OrderProcessedTD orderProcessedDate={orderDetails.orderProcessedDate} />
              {orderDetails.orderSummary && orderDetails.orderSummary.orderStatus.toLowerCase() === constants.CANCELLED ? <></> : this.renderOrderStepsForTelstra()}
              <OrderCompleteTD
                  showFooter={orderDetails.showFooter}
                orderCompletionDate={orderDetails.orderCompletionDate}
                orderStatus={orderDetails.orderSummary ? orderDetails.orderSummary.orderStatus : ''}
                orderCategory={orderDetails.orderSummary.orderCategory ? orderDetails.orderSummary.orderCategory : ''} />
            </Timeline>
        );
    }

    renderForEOV() {
        const { orderDetails } = this.props;
        //this.renderOrderStepsForEOV = this.renderOrderStepsForEOV.bind(this);
        return (
          <Timeline>
            <OrderProcessedTD orderProcessedDate={orderDetails.orderProcessedDate} />
              {orderDetails.orderSummary && orderDetails.orderSummary.orderStatus.toLowerCase() === constants.CANCELLED ? <></> : this.renderOrderStepsForEOV()}
            <OrderCompleteTD
                showFooter={orderDetails.showFooter}
              orderCompletionDate={orderDetails.orderCompletionDate}
              orderStatus={orderDetails.orderSummary ? orderDetails.orderSummary.orderStatus : ''}
              orderCategory={orderDetails.orderSummary && orderDetails.orderSummary.orderCategory ? orderDetails.orderSummary.orderCategory : ''} />
          </Timeline>
        );
    }

    renderForHeadlessAndFixed() {
        const { orderDetails } = this.props;
        return (
          <Timeline>
            <OrderProcessedTD orderProcessedDate={orderDetails.orderProcessedDate} />
            {orderDetails.chownTimelineDetail ? <ChownTimelineTD chownTimelineDetail= {orderDetails.chownTimelineDetail} /> : <></>}
              {orderDetails.orderSummary && orderDetails.orderSummary.orderStatus && orderDetails.orderSummary.orderStatus.toLowerCase() === constants.CANCELLED ? <></> : this.renderOrderSteps()}
            <OrderCompleteTD
                nbnHealthCheckDetail={orderDetails.nbnHealthCheckDetail}
                guaranteeMessageDetail={orderDetails.guaranteeMessageDetail}
                showFooter={orderDetails.showFooter}
                orderCompletionDate={orderDetails.orderCompletionDate}
                orderStatus={orderDetails.orderSummary ? orderDetails.orderSummary.orderStatus : ''}
                orderCategory={orderDetails.orderSummary && orderDetails.orderSummary.orderCategory ? orderDetails.orderSummary.orderCategory : ''} />
          </Timeline>
        );
    }

    renderOrderSteps() {
        
        var closeApptPopup = this.props.closeApptPopup;
        var changeDetailsState = this.props.changeDetailsState;
        var closeLightbox = this.props.closeLightbox;
        var changeState = this.props.changeState;
        let { orderDetails, isUnauthorized } = this.props;

        if(orderDetails.orderCategory.toLowerCase()===constants.FIXED && orderDetails.updatePlanTimeline){
            const isEmpty = Object.values(orderDetails.updatePlanTimeline).every(x => (x === null || x === ''));
            if(!isEmpty && (orderDetails.serviceUpgradeDetail === null || orderDetails.serviceUpgradeDetail === undefined)){
                return <ChangePlanTD updatePlanTimeline={orderDetails.updatePlanTimeline}/>
            } else if (orderDetails.serviceUpgradeDetail) {
                return <ServiceUpgradeTD serviceUpgradeDetail= {orderDetails.serviceUpgradeDetail} />
            }
        }      
        
        if ((orderDetails.orderSummary && orderDetails.orderSummary.orderStatus.toLowerCase() === constants.COMPLETE) || orderDetails.orderCompletionDate) {
            if (orderDetails.telstraAppointment) {
                orderDetails.telstraAppointment.map(function (element) {
                    element.status = constants.COMPLETED;
                    return element;
                });
            }
            if (orderDetails.disconnect) orderDetails.disconnect.status = constants.COMPLETED;
        }

        let telstraAppointmentCounter = 0;
        let telstraAppointmentOriginal = orderDetails.telstraAppointment;
        let telstraAppointment = orderDetails.telstraAppointment && orderDetails.telstraAppointment !== undefined ? [...telstraAppointmentOriginal] : null;
        return orderDetails.orderFlowSequence ? (

            orderDetails.orderFlowSequence.map((step) => {
                let hardwareDelivery = orderDetails.hardwareDelivery;
                let hardwareDeliveryDate = orderDetails.hardwareDelivery && orderDetails.hardwareDelivery[0] && orderDetails.hardwareDelivery[0].customerRequestedDeliveryDate ? orderDetails.hardwareDelivery[0].customerRequestedDeliveryDate : null;
                if (step === constants.STEP_TELSTRA_APPT_DETAILS) {
                    telstraAppointmentCounter++;
                }
                

                switch (step) {
                    case constants.STEP_SHIPMENT_DETAILS:
                        let hardwareDeliveryData = [];

                        if (hardwareDelivery) {
                            for (let counter = 0; counter < hardwareDelivery.length; counter++) {
                                let hardwareDeliveryCurrent = hardwareDelivery[counter];
                                if(hardwareDeliveryCurrent.shipmentType === null || hardwareDeliveryCurrent.shipmentType === undefined || hardwareDeliveryCurrent.shipmentType && hardwareDeliveryCurrent.shipmentType.toLowerCase()!== constants.RETURN_LOGISTICS) {
                                    hardwareDeliveryData.push(
                                    <HardwareDeliveryTD
                                        key={"hardware-" + counter}
                                        hardwareDelivery={hardwareDeliveryCurrent}
                                        orderCategory={orderDetails.orderCategory}
                                        reasonForUnHealthy={orderDetails.reasonForUnHealthy}
                                        reasonForUnHealthyData={orderDetails.reasonForUnHealthyData}
                                        orderDetails={orderDetails} />
                                    );
                                }

                                if(hardwareDeliveryCurrent.shipmentType && hardwareDeliveryCurrent.shipmentType.toLowerCase() === constants.RETURN_LOGISTICS) {
                                    hardwareDeliveryData.push(<HardwareReturnTD hardwareDeliveryData={hardwareDeliveryCurrent} orderStatus={orderDetails.orderStatus}/>)
                                }
                            }
                        }
                        return hardwareDeliveryData;

                    case constants.STEP_TELSTRA_APPT_DETAILS:
                        let telstraAppointmentCurrent = telstraAppointment.shift();
                        return <TelstraAppointmentTD
                                  contactUsUrl={orderDetails.contactUsUrl}
                                  orderDetails ={orderDetails}
                                  loginEligibility={orderDetails.loginEligibility}
                                  isAuthenticated={this.props.isAuthenticated}
                                  key={telstraAppointmentCounter}
                                  hardwareDeliveryDate={hardwareDeliveryDate}
                                  counter={telstraAppointmentCounter}
                                  telstraAppointment={telstraAppointmentCurrent}
                                  orderId={orderDetails.orderSummary ? orderDetails.orderSummary.orderId : ''}
                                  reasonForUnHealthy={orderDetails.reasonForUnHealthy}
                                  isUnauthorized={isUnauthorized}
                                  reasonForUnHealthyData={orderDetails.reasonForUnHealthyData}
                                  orderCategory={orderDetails.orderSummary && orderDetails.orderSummary.orderCategory ? orderDetails.orderSummary.orderCategory : ''}
                                  accessToken={this.props.accessToken}
                                  location={this.props.location}
                                  isAuthenticated={this.props.isAuthenticated} closeLightbox={closeLightbox.bind(this)} />
                    case constants.STEP_NBN_APPT_DETAILS:
                        if(orderDetails.nbnAppointment && orderDetails.nbnAppointment.isUniti) {
                          return <UnitiAppointmentTD
                            contactUsUrl={orderDetails.contactUsUrl}
                            orderDetails ={orderDetails}
                            loginEligibility={orderDetails.loginEligibility}
                            key="nbnAppointment"
                            accessToken={this.props.accessToken}
                            bypassCache={this.props.bypassCache}
                            appointment={orderDetails.nbnAppointment}
                            orderId={orderDetails.orderSummary ? orderDetails.orderSummary.orderId : ''}
                            reasonForUnHealthy={orderDetails.reasonForUnHealthy}
                            isAuthenticated={this.props.isAuthenticated}
                            isUnauthorized={isUnauthorized}
                            reasonForUnHealthyData={orderDetails.reasonForUnHealthyData}
                            orderCategory={orderDetails.orderCategory ? orderDetails.orderCategory : ''}
                            closeLightbox={closeLightbox.bind(this)}
                            accessToken = {this.props.accessToken}
                            location={this.props.location}/>
                        }
                        return <NbnAppointmentTD
                                  contactUsUrl={orderDetails.contactUsUrl}
                                  orderDetails ={orderDetails}
                                  loginEligibility={orderDetails.loginEligibility}
                                  key="nbnAppointment"
                                  accessToken={this.props.accessToken}
                                  bypassCache={this.props.bypassCache}
                                  nbnAppointment={orderDetails.nbnAppointment}
                                  orderId={orderDetails.orderSummary ? orderDetails.orderSummary.orderId : ''}
                                  reasonForUnHealthy={orderDetails.reasonForUnHealthy}
                                  isAuthenticated={this.props.isAuthenticated}
                                  isUnauthorized={isUnauthorized}
                                  reasonForUnHealthyData={orderDetails.reasonForUnHealthyData}
                                  orderCategory={orderDetails.orderCategory ? orderDetails.orderCategory : ''}
                                  closeLightbox={closeLightbox.bind(this)} 
                                  accessToken = {this.props.accessToken}
                                  location={this.props.location}/>

                    case constants.STEP_ASIK_APPT_DETAILS:
                        return <ASIKAppointmentTD
                            telstraAppointment={orderDetails.asikTelstraAppointment}
                            key={telstraAppointmentCounter}
                        />
                    case constants.STEP_DISCONNECTION_DETAILS:
                        return <DisconnectTD key="disconnect" disconnect={orderDetails.disconnect} />
                    case constants.MODEM_PLUGIN_DETAILS:
                        return <ModemPlugInTD key="pluginModem" modemPlugInDetail={orderDetails.modemPlugInDetail} />
                    case constants.IDD_PACK_DETAILS:
                        return <IDDPackTD key="iddPack" iddPackDetail={orderDetails.iddPackDetail} />
                    case constants.STEP_NUMBER_TRANSFER_DETAILS:
                        return <NumberTransferDetail key="numberTransfer" isAuthenticated={this.props.isAuthenticated} numberTransferDetail={orderDetails.numberTransferDetail}/>
                    case constants.STEP_TRANSFER_REQUEST_DETAILS:
                     return <TransferRequestDetail key="transferRequest" transferRequestDetail={orderDetails.transferRequestDetail}/>
                    default:
                        return <></>;
                }
            })
        ) : <></>;
    }


renderOrderStepsForPrepaid(){
        let { orderDetails } = this.props;

        return orderDetails.orderSummary.milestones ? (

            orderDetails.orderSummary.milestones.map((step) => {

            let formattedDate  = step.subLabel ? step.subLabel :''

            switch (step.label.toLowerCase()) {
              case constants.ORDER_PROCESSED:
                return   <OrderProcessedTD
                          orderProcessedDate={ formattedDate}
                          status ={step.status}/>
              case constants.ORDER_COMPLETED:
              case  constants.ORDER_COMPLETE:
                return <OrderCompleteTD
                          showFooter={orderDetails.showFooter}
                          orderCompletionDate={ formattedDate}
                          belongsToMilestone={true}
                          orderStatus={step.status}/>
              default:
                return  <MilestoneTimelineTD
                          achievedDate ={formattedDate}
                          status={step.status}
                          label={step.label}/>
              }
            })
        ) : '';

    }
    renderOrderStepsForEOV() {
        
        let { orderDetails, isUnauthorized } = this.props;
        let hardwareDeliveryData = [];
        let hardwareDelivery = orderDetails.hardwareDelivery;
        if (hardwareDelivery) {
            for (let counter = 0; counter < hardwareDelivery.length; counter++) {
                if (hardwareDelivery[counter] && hardwareDelivery[counter].iconStatus) {
                    let hardwareDeliveryCurrent = hardwareDelivery[counter];

                    if(hardwareDeliveryCurrent.shipmentType === null || hardwareDeliveryCurrent.shipmentType === undefined || hardwareDeliveryCurrent.shipmentType && hardwareDeliveryCurrent.shipmentType.toLowerCase()!== constants.RETURN_LOGISTICS) {
                        hardwareDeliveryData.push(
                        <HardwareDeliveryTD
                            key={"hardware-" + counter}
                            hardwareDelivery={hardwareDeliveryCurrent}
                            orderCategory={orderDetails.orderCategory}
                            orderDetails={orderDetails} />
                        );
                    }

                    if(hardwareDeliveryCurrent.shipmentType && hardwareDeliveryCurrent.shipmentType.toLowerCase() === constants.RETURN_LOGISTICS) {
                        hardwareDeliveryData.push(<HardwareReturnTD hardwareDeliveryData={hardwareDeliveryCurrent} orderStatus={orderDetails.orderStatus}/>)
                    }
                }
            }
        }

        let showChangePlanTimeline =  false;
        if (orderDetails.updatePlanTimeline) {
          showChangePlanTimeline = !Object.values(orderDetails.updatePlanTimeline).every(x => (x === null || x === ''));
        }
        
        return <>
                <div>
                    {hardwareDeliveryData}
                </div>
                { showChangePlanTimeline && <ChangePlanTD updatePlanTimeline={orderDetails.updatePlanTimeline}/>}
                <div>
                    {orderDetails.chownTimelineDetail && <ChownTimelineTD chownTimelineDetail= {orderDetails.chownTimelineDetail} />}
                </div>
            </>

    }

    renderOrderStepsForTelstra() {
        let { orderDetails, isUnauthorized } = this.props;
        let telstraAppointmentDetailsData = [];
        let telstraAppointment = orderDetails.telstraAppointment;
        if (telstraAppointment) {
            for (let counter = 0; counter < telstraAppointment.length; counter++) {
                let telstraAppointmentCurrent = telstraAppointment[counter];
                telstraAppointmentDetailsData.push(
                  <TelstraAppointmentTD
                    counter={counter}
                    key={counter}
                    telstraAppointment={telstraAppointmentCurrent}
                    orderId={orderDetails.orderSummary ? orderDetails.orderSummary.orderId : ''}
                    reasonForUnHealthy={orderDetails.reasonForUnHealthy}
                    isUnauthorized={isUnauthorized}
                    installType={orderDetails.orderSummary.installType ? orderDetails.orderSummary.installType : ''}
                    orderCategory={orderDetails.orderSummary && orderDetails.orderSummary.orderCategory ? orderDetails.orderSummary.orderCategory : ''} />
                );
            }
        }
        let hardwareDeliveryData = [];
        let hardwareDelivery = orderDetails.hardwareDelivery;
        if (hardwareDelivery) {
            for (let counter = 0; counter < hardwareDelivery.length; counter++) {
                let hardwareDeliveryCurrent = hardwareDelivery[counter];

                if(hardwareDeliveryCurrent.shipmentType === null || hardwareDeliveryCurrent.shipmentType === undefined || hardwareDeliveryCurrent.shipmentType && hardwareDeliveryCurrent.shipmentType.toLowerCase()!== constants.RETURN_LOGISTICS) {                    
                    hardwareDeliveryData.push(
                    <HardwareDeliveryTD
                        key={"hardware-" + counter}
                        hardwareDelivery={hardwareDeliveryCurrent}
                        orderCategory={orderDetails.orderCategory}
                        reasonForUnHealthy={orderDetails.reasonForUnHealthy}
                        reasonForUnHealthyData={orderDetails.reasonForUnHealthyData}
                        orderDetails={orderDetails} />
                    );
                }

                if(hardwareDeliveryCurrent.shipmentType && hardwareDeliveryCurrent.shipmentType.toLowerCase() === constants.RETURN_LOGISTICS) {
                    hardwareDeliveryData.push(<HardwareReturnTD hardwareDeliveryData={hardwareDeliveryCurrent} orderStatus={orderDetails.orderStatus}/>)
                }
            }
        }
        return (
          <div>{hardwareDeliveryData}{telstraAppointmentDetailsData}
            {(orderDetails.disconnect && orderDetails.disconnect.status) ? <DisconnectTD key="disconnect" disconnect={orderDetails.disconnect} /> : null}
          </div>
        )
    }

    renderForSemiSupported() {
        const { orderDetails } = this.props;        
        return (
          <Timeline>
            <OrderProcessedTD orderProcessedDate={orderDetails.orderProcessedDate} />
              {orderDetails.orderSummary && orderDetails.orderSummary.orderStatus.toLowerCase() === constants.CANCELLED ? <></> : this.renderOrderStepsForEOV()}
            <OrderCompleteTD
              showFooter={orderDetails.showFooter}
              orderCompletionDate={orderDetails.orderCompletionDate}
              orderStatus={orderDetails.orderSummary ? orderDetails.orderSummary.orderStatus : ''}
              orderCategory={orderDetails.orderSummary && orderDetails.orderSummary.orderCategory ? orderDetails.orderSummary.orderCategory : ''} />
          </Timeline>
        );
    }
}

export default OrderProgress;
