import React from "react";
import Navbar from '../../presenters/components/HeaderTD';
import SiteWideAlert from './SiteWideAlert';


export default function SiteHeader({ accessToken, orderDetails, location }) {
    const { alertMessage, loggedIn } = orderDetails.page;

    return (
        <React.Fragment>
            {alertMessage && <SiteWideAlert alertMessage={alertMessage}/>}

            {/* Should be changed accordingly when we have visibily on BFF response for Edgar. */}
            <Navbar accessToken={(loggedIn && accessToken) ? accessToken : null} location={location}/>
        </React.Fragment>
    );
}

