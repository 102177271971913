import React, { Component } from "react";
import { Row, Column } from "react-foundation-components/lib/global/grid";
import { openInNewTab } from "../../utils";
import linkIcon from "../../images/icon-link.svg";
import MobileOrderSummary from "./MobileOrderSummaryTD";
import * as constants from "../Constants";
import { Header } from './OrderDetailsComponentsTD';

class OrderSummary extends Component {
    constructor(props) {
        super(props);
        this.renderConnectionAddress = this.renderConnectionAddress.bind(this);
        this.renderFoxtel = this.renderFoxtel.bind(this);
        this.renderOrderSummaryDateLabel = this.renderOrderSummaryDateLabel.bind(this);
        this.showChangeDateLebal = this.showChangeDateLebal.bind(this);
    }

    render() {
        const { orderSummary } = this.props;
        let orderCategoryValue = null;
        if (orderSummary && orderSummary.orderCategory) {
            orderCategoryValue = orderSummary.orderCategory.toLowerCase();
            if (orderSummary.orderCategory.toLowerCase().startsWith(constants.ORDER_CATEGORY_SEMI_SUPPORTED_NBNSIEBEL_PREFIX)) {
                orderCategoryValue = constants.NBN_SIEBEL_SEMISUPPORTED;
            }

            switch (orderCategoryValue) {
                case constants.ORDER_CATEGORY_NBN:
                case constants.BROADBAND_CABLE:
                case constants.BROADBAND_ADSL:
                case constants.BROADBAND_VELOCITY:
                case constants.ORDER_CATEGORY_SEMI_SUPPORTED:
                case constants.NBN_SIEBEL_SEMISUPPORTED:
                case constants.ORDER_CATEGORY_MOBILE_SUBSCRIPTION:
                case constants.ORDER_CATEGORY_SEMI_SUPPORTED_SUBSCRIPTION:
                case constants.PSTN:
                case constants.HEADLESS:
                case constants.NEXTGEN_OSM:
                case constants.NEXTGEN_OSM_HDW:
                case constants.PLATINUM:
                case constants.HEADLESS_PREORDER:
                case constants.ORDER_CATEGORY_PREPAID:
                case constants.ORDER_CATEGORY_SIM_SWAP_DISPATCH:
                case constants.FIXED:
                case constants.ORDER_CATEGORY_LOYALTY:
                case constants.ORDER_CATEGORY_LOYALTY_BAU:
                case constants.HARDWARE_ONLY:
                    return this.renderOrderSummary();
                case constants.ORDER_CATEGORY_MOBILE_PREORDER:
                case constants.ORDER_CATEGORY_MOBILE_POSTPAID:
                    return <MobileOrderSummary key="preOrder" orderSummary={orderSummary} />;
                default:
                    return null;
            }
        }
        
        return <React.Fragment></React.Fragment>
    }

    renderOrderSummary() {
        const { orderSummary} = this.props;
        const { orderCompletionDate } = this.props;
        let orderSummaryDate = orderSummary.orderSummaryDate;
        let hideExpectedConnectionDate = orderSummary.hideExpectedConnectionDate;
        console.log('*** regular order summary ***');

        let summaryClassName = 'responsive-width';
        if (orderSummary.orderCategory) {
            const category = orderSummary.orderCategory.toLowerCase();
            if (
                category === constants.ORDER_CATEGORY_SEMI_SUPPORTED
                || category.startsWith(constants.ORDER_CATEGORY_SEMI_SUPPORTED_NBNSIEBEL_PREFIX)
            ) {
                summaryClassName += " order-summary-content semi-supported-padding";
            }  else {
                summaryClassName += " order-summary-content";
            }
        }
        const showOrderDate = hideExpectedConnectionDate === false && orderSummaryDate && orderSummary.isErrorProvisioningOrder === false;
        let orderNumber = localStorage.getItem('orderNumber') ? localStorage.getItem('orderNumber') : orderSummary.orderId;       

        return orderSummary ? (
            <div>
                <Row collapse className="no-margin">
                    <Column>
                        <Header category={orderSummary.orderCategory} description={orderSummary.planName} />
                    </Column>
                </Row>
                <div className={summaryClassName}>
                    <Row collapse className="no-margin">
                        {
                            showOrderDate ? (
                                <Column medium={4}>
                                    <div>
                                        {this.renderOrderSummaryDateLabel(orderSummary, orderCompletionDate)}
                                    </div>
                                    <div id="Order-Summary-Day" className="order-summary-date">
                                        {orderSummaryDate}
                                        {this.showChangeDateLebal()}
                                    </div>
                                </Column>
                            ) : null
                        }
                        <Column medium={6}>
                            <div className="order-summary-right">
                                <span id="Order-Number-Label" className="field-label">
                                    Order number:
                                </span>
                                <span id="Order-Number" className="field-label">
                                    {orderNumber}
                                </span>
                            </div>
                            <div className="order-summary-right order-status-box">
                                <span id="Order-Status-Label" className="field-label">
                                    Order status:
                                </span>
                                <span id="Order-Status-Text" className="field-label">
                                    {orderSummary.orderStatus}
                                </span>
                            </div>
                            {
                                (orderSummary.orderCategory &&
                                orderSummary.orderCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN)
                                    ? this.renderConnectionAddress()
                                    : ''
                            }
                        </Column>
                    </Row>
                </div>
                {this.renderFoxtel()}
            </div>
        ) : null;
    }

    renderConnectionAddress() {
        const { orderSummary } = this.props;
        return orderSummary.connectionAddress ?
            <div className="order-summary-right connection-address">
                <span id="connection-address-label" className="field-label">
                    Connection Address:
                </span>
                <span id="Connection-address-text" className="field-label">
                    {orderSummary.connectionAddress.addressLine1}
                    <br />
                    {orderSummary.connectionAddress.addressLine2}
                    <br />
                    {orderSummary.connectionAddress.addressLine3}
                </span>
            </div> : ''
    }

showChangeDateLebal(){
    const { orderSummary} = this.props;
    let changeDateLabel = orderSummary.changeDateLabel;
    let changeDateURL = orderSummary.changeDateURL;
    if(changeDateLabel && changeDateURL){
        return <div>
        <a id="Change-Date-Link" href={changeDateURL} onClick={(e) => openInNewTab(e, changeDateURL)}>
        <span id="Change-Date-text">{changeDateLabel}</span>
        </a>
        </div>
    } else{
        return null;
    }
}
    renderFoxtel() {
        const { orderSummary } = this.props;
        return orderSummary.isFoxtelAvailable ?
            <div className="foxtel-box">
                <div className="foxtel-text">
                    <span id="Foxtel-Text-Label" >Foxtel from Telstra is included in your order.</span>
                </div>
                <div className="foxtel-more-link">
                    <a id="Foxtel-More-Link" href="https://www.telstra.com.au/support/category/entertainment/foxtel-from-telstra" onClick={(e) => openInNewTab(e, 'https://www.telstra.com.au/support/category/entertainment/foxtel-from-telstra')}>
                        <img id="Foxtel-Find-More-Icon" className="foxtel-more-icon" src={linkIcon} alt="link icon" /><span id="Foxtel-More-text">Find out more</span>
                    </a>
                </div>
            </div> : <></>;
    }

    renderOrderSummaryDateLabel(orderSummary, orderCompletionDate) {
        let orderSummaryDateLabel= orderSummary.orderSummaryDateLabel;
        if(orderSummary.orderCategory && orderSummary.orderCategory.toLowerCase() === constants.ORDER_CATEGORY_SEMI_SUPPORTED && orderSummaryDateLabel) {
            return <span id="Date-Label" className="field-label order-summary-date-label">{orderSummaryDateLabel}</span>;
        }
        else if (orderSummary.orderCategory && orderSummary.orderStatus &&
            (orderSummary.orderCategory.toLowerCase() === constants.ORDER_CATEGORY_SEMI_SUPPORTED || orderSummary.orderCategory.toLowerCase().startsWith(constants.ORDER_CATEGORY_SEMI_SUPPORTED_NBNSIEBEL_PREFIX))
            && (orderSummary.orderStatus.toLowerCase() === constants.ON_HOLD || orderSummary.orderStatus.toLowerCase() === constants.IN_PROGRESS || orderSummary.orderStatus.toLowerCase() === constants.CANCELLED)) {
            switch (orderSummary.orderStatus.toLowerCase()) {
                case constants.ON_HOLD:
                case constants.IN_PROGRESS:
                    return <span id="Date-Label" className="field-label order-summary-date-label">Order Creation Date</span>;
                case constants.CANCELLED:
                    {
                        if (orderCompletionDate)
                            return <span id="Date-Label" className="field-label order-summary-date-label">Order Cancelled On</span>;
                        else
                            return <span id="Date-Label" className="field-label order-summary-date-label">Order Creation Date</span>;
                    }
            }
        }
       else if (orderSummaryDateLabel){
            return <span id="Date-Label" className="field-label order-summary-date-label">{orderSummaryDateLabel}</span>;
        }
        else {
            switch (orderSummary.orderStatus.toLowerCase()) {
                case constants.COMPLETE:
                    return <span id="Date-Label" className="field-label order-summary-date-label">Order completed on</span>;
                case constants.CANCELLED:
                    return <span id="Date-Label" className="field-label order-summary-date-label">Order cancelled on</span>;
                case constants.QUEUED:
                    return <span id="Date-Label" className="field-label order-summary-date-label">Order scheduled for</span>;
                default:
                    return <span id="Date-Label" className="field-label order-summary-date-label">Expected connection date</span>;
            }
        }
    }

}
export default OrderSummary;
