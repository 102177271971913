import React, {Component} from "react";
import {openInNewTab} from "../../utils";
import startrackIcon from "../../images/Star Track.svg";
import linkIcon from "../../images/icon-link.svg";
import * as constants from "../Constants";
import StatusIconsTD from "./StatusIconsTD";


import TimelineMilestone from './TimelineMilestonesTD'
import { ActionButton } from "@able/react";

class OrderTimeline extends Component {

    getDeliveryAddressLines(hardwareDelivery){
        const { counter, telstraAppointment, orderId, isUnauthorized } = this.props;
        var addressLines = [];
        if(hardwareDelivery.shipmentHardwareDetails && Array.isArray(hardwareDelivery.shipmentHardwareDetails) && hardwareDelivery.shipmentHardwareDetails[0]) {
            const {
                deliveryAddressLine1,
                deliveryAddressLine2,
                deliveryAddressLine3,
            } = hardwareDelivery.shipmentHardwareDetails[0];
            if(deliveryAddressLine1) {
                addressLines.push(deliveryAddressLine1);
            }
            if(deliveryAddressLine2) {
                addressLines.push(deliveryAddressLine2);
            }
            if(deliveryAddressLine3) {
                addressLines.push(deliveryAddressLine3);
            }
        }
        return addressLines;
    }

    getDeliveryProduct = (hardwareDelivery) => {
        if(hardwareDelivery.shipmentHardwareDetails && Array.isArray(hardwareDelivery.shipmentHardwareDetails) && hardwareDelivery.shipmentHardwareDetails[0]) {
            const { productName } = hardwareDelivery.shipmentHardwareDetails[0];
            return productName;
        }
    }

    renderHardwareDeliverySegment = () => {
        const { hardwareDelivery, displayStatus, isUnauthorized } = this.props;
        if (displayStatus.toLowerCase() !== 'hardware delivery') {
            return null;
        }

        const hardwareDeliveryData = [];

        return (
            <div className="progress-details hardware-items">
                {
                    hardwareDelivery.length > 0 
                    && hardwareDelivery[0].displayStatus 
                    && this.renderHardwareStatusText(hardwareDelivery[0].displayStatus)
                }
                {
                    hardwareDelivery.map((hardwareDeliveryCurrent, i) => {
                        const addressLines = this.getDeliveryAddressLines(hardwareDeliveryCurrent);
                        const productName = this.getDeliveryProduct(hardwareDeliveryCurrent);
                        const { shipmentProviderName, shipmentTrackingURL } = hardwareDeliveryCurrent;
                        if (!productName) return null;
                        return (
                            <React.Fragment key={i.toString()}>
                                <ul>
                                    <li key={"Hardware-Delivery-Item"} id={"Hardware-Delivery-Item"} >{productName}</li>
                                </ul>
                                {
                                    (isUnauthorized === false) && (
                                        <div id="Hardware-Delivery-Label"className="progress-label">To be delivered to
                                            <div className="progress-details hardware-items">
                                                <span>
                                                    {addressLines.map((line, i) => {
                                                        return (
                                                            <p id="Hardware-Delivery-Item" key={i.toString()}>
                                                                {line}
                                                            </p>
                                                        ); 
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                                {this.renderLogisticDetails({ shipmentProviderName, shipmentTrackingURL })}
                            </React.Fragment>
                        )
                    })
                }
            </div>
        )
    }

    render() {
        const {displayStatus, progressStatus, timelineItemClass, statusIconStateClass, date, shipmentDetails, hardwareDelivery} = this.props;
        const lineStyle = {};
        const orderCompleteBorder = {};

        if (displayStatus.toLowerCase() === 'order complete' || displayStatus.toLowerCase() === 'order cancelled') {
            lineStyle.display = 'none';
            orderCompleteBorder.border = 'none';
        }
        return (
          <TimelineMilestone
          lineStyle={lineStyle}
          orderCompleteBorder={orderCompleteBorder}
          title={displayStatus}
          createdAt={date}
          icon={<StatusIconsTD uniqueIdentifier={displayStatus} status={progressStatus}/>}
          lineColor={progressStatus && progressStatus.toLowerCase() !== 'completed'  ? '#d8d8d8': '#0064D2'}
        >
            {this.renderHardwareDeliverySegment()}
            {this.renderDownloadTelstraLink(hardwareDelivery)}
        </TimelineMilestone>
        );
    }

    renderDownloadTelstraLink(hardwareDelivery){
        const {shipmentDetails} = this.props;

        if (!shipmentDetails) {
            return '';
        }
        if (!hardwareDelivery) {
            return '';
        }

        let downloadTelstraAppText = hardwareDelivery[0].downloadTelstraAppText;
        let downloadTelstraAppUrl = hardwareDelivery[0].downloadTelstraAppURL;
        if(downloadTelstraAppText && downloadTelstraAppUrl){
            return <div className="download-telstra-app">
                    <ActionButton variant="LowEmphasis" label={downloadTelstraAppText} element="button" onClick={(e)=>openInNewTab(e, downloadTelstraAppUrl)} />

                        {/* <a id="Download-Telstra-App-Link" href={downloadTelstraAppUrl} className="progress-link" onClick={(e)=>openInNewTab(e, downloadTelstraAppUrl)}>
                          <img id="Download-Link-Icon"  src={linkIcon} alt="link icon" />
                          <span id="Download-Telstra-App-Text" >{downloadTelstraAppText}</span>
                         </a> */}
                    </div>
        }
    }

    renderHardwareStatusText(status) {
        if(status) {
            // Reusing milestone-date class to show displayStatus of order here.
            return <div className="milestone-date">
                        <span> {status} </span>
                    </div> 
        }
    }

    renderLogisticDetails(shipmentDetail) {
        if (shipmentDetail.shipmentProviderName === constants.STARTRACK.toUpperCase()) {
            return <div className="logistic-panel-td">
                        <div className="logistic-img-box">
                            <img id="Star-Track-Icon" className="logistic-img-td" src={startrackIcon} alt="Startrack Icon"/>
                        </div>
                        <div className="flex-column">
                            <h3 id="Start-Track-Text" className="logistic-label-td">{constants.STARTRACK}</h3>
                                <a
                                  d="Star-Track-Link"
                                  href={shipmentDetail.shipmentTrackingURL}
                                  className="logistic-link-td"
                                  onClick={(e)=>openInNewTab(e, shipmentDetail.shipmentTrackingURL)}>
                                <span id="Tracking-Url" className="tracking-url">{constants.TRACK_DELIVERY}</span>
                            </a>
                        </div>
                    </div>
        }
        return null;
    }
    getNodes(equalizerComponent, equalizerElement) {
        return [
          this.refs.node1,
          this.refs.node2
        ]
  }
}

export default OrderTimeline;
