import * as analytics from './analytics-utils';
import * as constants from "./presenters/Constants";

let events = [];
export function getDefaultDataLayerObject() {

    let defaultObj = {
        "page": {},
        "event": [],
        "application": [],
        "version": '1.0' // W3C Digital Data Layer standard, fixed value
    }
    return defaultObj;
};

export const isValidDataLayerObject = inputObj => {
    // check object type
    if (!inputObj || typeof inputObj !== 'object') {
        return false;
    }

    // check mandatory properties
    if (!inputObj.page || typeof inputObj.page !== 'object') {
        return false;
    } else if (!inputObj.event || !Array.isArray(inputObj.event)) {
        return false;
    } else if (!inputObj.version || typeof inputObj.version !== 'string') {
        return false;
    }

    return true;
};

/**
 * UpdateDataLayerObjectTD - will merge the update object to the current data layer object
 *
 * @param {object} updateObj - the data layer update object to be merged into the current data layer object
 */
export function updateDataLayerObject(pageName) {
    let dataLayerObj = {
        "page": {
            "pageInfo": {
                "pageName": pageName,
                "referringURL": document.referrer,
                "sysEnv": window.env.REACT_APP_OMNITURE_DEPLOY_ENV,
                "deployEnv": window.env.REACT_APP_OMNITURE_DEPLOY_ENV
            },
            "category": {
                "primaryCategory": "Order-Tracker",
                "pageType": "service"
            },
            "attributes": {
                "division": "TD",
                "subDivision": "TR",
                "subDivisionUnit": "TR"
            }
        },
        "application": [],
        "event": [],
        "version": '1.0' // W3C Digital Data Layer standard, fixed value
    }

    // set default data layer if not already have one
    window.digitalData = window.digitalData || getDefaultDataLayerObject();

    // merge and update if valid
    const updatedDataLayerObj = Object.assign(window.digitalData, dataLayerObj);
    if (isValidDataLayerObject(updatedDataLayerObj)) {
        window.digitalData = updatedDataLayerObj;
    }
};

export function createPageObj(pageName) {
    let pageObj = null;
    let primaryCategory = "Order-Tracker";
  
    pageObj = {
        "pageInfo": {
            "pageName": pageName,
            "referringURL": document.referrer,
            "sysEnv": window.env.REACT_APP_OMNITURE_DEPLOY_ENV,
            "deployEnv": window.env.REACT_APP_OMNITURE_DEPLOY_ENV
        },
        "category": {
            "primaryCategory": primaryCategory,
            "pageType": "Service"
        },
        "attributes": {
            "division": "TD",
            "subDivision": "TR",
            "subDivisionUnit": "TR"
        }
    }
    return pageObj;
}

export function createErrorObj(errorObj) {
    let pageErrorObj = null;
    
    pageErrorObj = {
        "errorMessage": errorObj
    }
    return pageErrorObj;
}

export function createClickEventObj(eventAction) {
    let eventObj = null;
    eventObj = {
        "eventInfo":{
            "eventAction":"click",
            "eventType":"clickTrack",
            "eventCategory":"buttonClick",
            "eventName":eventAction
        },
        "attributes":{}
    }
    return eventObj;
}

export function addOmnitureObjectForEventClick(pageName, eventAction, orderDetails) {
    let pageObj = null;
    let eventObj = null;
    let applicationObj = null;
    pageObj = analytics.createPageObj(pageName);
    if(eventAction)
        eventObj = analytics.createClickEventObj(eventAction);
    applicationObj = analytics.createApplicationObj(orderDetails);
    analytics.createDateLayerObj(pageObj, applicationObj, eventObj);
}

export function createApplicationObj(orderDetails, accessToken) {
    let applicationObj = {
        "orderTracker":{}
    }
    if(orderDetails && orderDetails.isEnergyOrder) {
        applicationObj = analytics.populateApplicationObjForEnergy(orderDetails, accessToken);
    } else if(orderDetails && orderDetails.isDynamicOrder) {
        applicationObj = analytics.populateApplicationObjForDynamic(orderDetails, accessToken);
    } else {
        applicationObj = analytics.populateApplicationObjForTelecom(orderDetails, accessToken);
    }

    return applicationObj;
}

export function populateApplicationObjForTelecom(orderDetails, accessToken) {
    let applicationObj = {
        "orderTracker":{}
    }    
    let orderCategory = orderDetails && orderDetails.orderCategory ? orderDetails.orderCategory : null;
    let technologyType = orderDetails && orderDetails.technologyType ? orderDetails.technologyType : null;
    let isAuthenticated = accessToken ? true : false;
    let orderStatus = orderDetails && orderDetails.orderStatus ? orderDetails.orderStatus : null;
    let orderId = orderDetails && orderDetails.orderId ? orderDetails.orderId : null;

    applicationObj.orderTracker = {
        "orderCategory": orderCategory,
        "orderStatus": orderStatus,
        "isAuthenticated": isAuthenticated,
        "orderNumber": orderId,
        "techType": technologyType
    }

    return applicationObj;
}

export function populateApplicationObjForEnergy(orderDetails, accessToken) {
    let applicationObj = {
        "orderTracker":{}
    }    
    let orderCategory = orderDetails.meta?.orderCategory;    
    let isAuthenticated = accessToken ? true : false;
    let orderStatus = orderDetails.meta?.orderStatus;
    let orderId = orderDetails.meta?.orderId;
    let technologyType = orderDetails.meta?.techType;    

    applicationObj.orderTracker = {
        "orderCategory": orderCategory,
        "orderStatus": orderStatus,
        "isAuthenticated": isAuthenticated,
        "orderNumber": orderId,
        "techType": technologyType
    }

    return applicationObj;
}

export function populateApplicationObjForDynamic(orderDetails, accessToken) {
    let applicationObj = {
        "orderTracker":{}
    }
    let orderCategory = orderDetails.meta?.orderCategory;
    let isAuthenticated = accessToken ? true : false;
    let orderStatus = orderDetails.meta?.orderStatus;
    let orderId = orderDetails.meta?.orderId;
    let technologyType = orderDetails.meta?.techType;

    applicationObj.orderTracker = {
        "orderCategory": orderCategory,
        "orderStatus": orderStatus,
        "isAuthenticated": isAuthenticated,
        "orderNumber": orderId,
        "techType": technologyType
    }

    return applicationObj;
}

export function createBannerObj(pageObj, applicationObj, bannerMsgObj) {    
    let bannerObj = {
            "bannerObj":[bannerMsgObj]
        }

    analytics.createDateLayerObj(pageObj, applicationObj, null, bannerObj);
    
}

export function createDateLayerObj(pageObj, applicationObj = null, eventObj = null, bannerObj = null) {
    let dataLayerObj = {};
   
    dataLayerObj.page = pageObj;
    dataLayerObj.application = applicationObj;    

    if(Array.isArray(events)) {
        events.push(eventObj);
    }
    events = events.filter(function (el) {
        return el != null;
      });
    dataLayerObj.event = events;
    dataLayerObj.banner = [];

    if(bannerObj) {
        (dataLayerObj.banner).push(bannerObj);
    }
    
    // set default data layer if not already have one
    window.digitalData = window.digitalData || getDefaultDataLayerObject();

    // merge and update if valid
    const updatedDataLayerObj = Object.assign(window.digitalData, dataLayerObj);
    if (isValidDataLayerObject(updatedDataLayerObj)) {
        window.digitalData = updatedDataLayerObj;
    }

}

export function createEventObj(eventAction, eventType, eventCategory = null , eventName = null) {
    
    let eventObj = null;    
    eventObj = {
        "eventInfo":{
            "eventAction": eventAction,
            "eventType": eventType,
            "eventCategory":eventCategory,
            "eventName":eventName
        },
        "attributes":{}
    }
    return eventObj;   
}
