import React from 'react';
import { DETAILS_ILLUSTRATION_CANCELLED, DETAILS_ILLUSTRATION_COMPLETED, DETAILS_ILLUSTRATION_DELIVERY_DELAY, DETAILS_ILLUSTRATION_NBN_TECH, DETAILS_ILLUSTRATION_TELSTRA_TECH, ENERGY_DETAILS_ILLUSTRATION_IN_PROGRESS, ENERGY_DETAILS_ILLUSTRATION_ERROR, ILLUSTRATION_IN_PROGRESS_ALT_TEXT, ILLUSTRATION_ERROR_ALT_TEXT, ILLUSTRATION_COMPLETE_ALT_TEXT, ILLUSTRATION_CANCELLED_ALT_TEXT, ILLUSTRATION_TECHNICIAN_ALT_TEXT, ILLUSTRATION_DELIVERY_DELAY_ALT_TEXT, ILLUSTRATION_DEFAULT_ALT_TEXT } from '../Constants';

/* illustrations */

import defaultDesktop from '../../images/illustrations/default_desktop.svg';
import defaultMobile from '../../images/illustrations/default_mobile.svg';
import defaultTablet from '../../images/illustrations/default_tab.svg';

import delayDesktop from '../../images/illustrations/delay_desktop.svg';
import delayTablet from '../../images/illustrations/delay_tab.svg';
import delayMobile from '../../images/illustrations/delay_mobile.svg';

import technicianDesktop from '../../images/illustrations/technician_desktop.svg';
import technicianTablet from '../../images/illustrations/technician_tab.svg';
import technicianMobile from '../../images/illustrations/technician_mobile.svg';

import energyInProgressDesktop from '../../images/illustrations/in_progress_desktop.svg';
import energyInProgressTablet from '../../images/illustrations/in_progress_tab.svg';
import energyInProgressMobile from '../../images/illustrations/in_progress_mobile.svg';

import completedDesktop from '../../images/illustrations/completed_desktop.svg';
import completedTablet from '../../images/illustrations/completed_tab.svg';
import completedMobile from '../../images/illustrations/completed_mobile.svg';

import cancelledDesktop from '../../images/illustrations/cancelled_desktop.svg';
import cancelledTablet from '../../images/illustrations/cancelled_tab.svg';
import cancelledMobile from '../../images/illustrations/cancelled_mobile.svg';

import errorDesktop from '../../images/illustrations/error_desktop.svg';
import errorTablet from '../../images/illustrations/error_tab.svg';
import errorMobile from '../../images/illustrations/error_mobile.svg';

export const defaultIllustrations = {
  desktop: defaultDesktop,
  tabPort: defaultTablet,
  tabLand: defaultTablet,
  mobile: defaultMobile,
};

const OrderDetailsIllustrationTD = ({ orderDetails, defaultImages, defaultAltText }) => {
  const summary = orderDetails && orderDetails.orderSummary;
  const images = defaultImages;
  let altText = defaultAltText || '';

  if (summary && summary.illustration) {
    switch (summary.illustration.toLowerCase()) {
      case DETAILS_ILLUSTRATION_CANCELLED:
          images.desktop = cancelledDesktop;
          images.tabPort = cancelledTablet;
          images.tabLand = cancelledTablet;
          images.mobile = cancelledMobile;
          altText = ILLUSTRATION_CANCELLED_ALT_TEXT;
        break;
      case DETAILS_ILLUSTRATION_COMPLETED:
          images.desktop = completedDesktop;
          images.tabPort = completedTablet;
          images.tabLand = completedTablet;
          images.mobile = completedMobile;
          altText = ILLUSTRATION_COMPLETE_ALT_TEXT;
        break;
      case DETAILS_ILLUSTRATION_TELSTRA_TECH:
      case DETAILS_ILLUSTRATION_NBN_TECH:
        images.desktop = technicianDesktop;
        images.tabPort = technicianTablet;
        images.tabLand = technicianTablet;
        images.mobile = technicianMobile;
        altText = ILLUSTRATION_TECHNICIAN_ALT_TEXT;
        break;
      case DETAILS_ILLUSTRATION_DELIVERY_DELAY:
        images.desktop = delayDesktop;
        images.tabPort = delayTablet;
        images.tabLand = delayTablet;
        images.mobile = delayMobile;
        altText = ILLUSTRATION_DELIVERY_DELAY_ALT_TEXT;
        break;
      case ENERGY_DETAILS_ILLUSTRATION_IN_PROGRESS:
        images.desktop = energyInProgressDesktop;
        images.tabPort = energyInProgressTablet;
        images.tabLand = energyInProgressTablet;
        images.mobile = energyInProgressMobile;
        altText = ILLUSTRATION_IN_PROGRESS_ALT_TEXT;
        break;
      case ENERGY_DETAILS_ILLUSTRATION_ERROR:
        images.desktop = errorDesktop;
        images.tabPort = errorTablet;
        images.tabLand = errorTablet;
        images.mobile = errorMobile;
        altText = ILLUSTRATION_ERROR_ALT_TEXT;
        break;
      default:
        break;
    }
  }

  return (
    <div className="order-details-header-img-container">
      <img className="parent-container header-img-TD order-details-header-img-desktop" alt={altText} src={images.desktop} />
      <img className="parent-container header-img-TD order-details-header-img-tab-port" alt={altText} src={images.tabPort} />
      <img className="parent-container header-img-TD order-details-header-img-tab-land" alt={altText} src={images.tabLand} />
      <img className="parent-container header-img-TD order-details-header-img-mobile" alt={altText} src={images.mobile} />
    </div>
  );
};

OrderDetailsIllustrationTD.defaultProps = {
  defaultImages: defaultIllustrations
};

export default OrderDetailsIllustrationTD;
