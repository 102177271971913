import * as constants from '../constants';
import { produce } from 'immer';
import {
  RECEIVE_ORDER_DETAILS,
  RECEIVE_ORDER_DETAILS_ERROR  
} from "../modules/orderDetails"

export const initialState = {
  errorStatus: false,
  errorType: undefined,
};

const orderSearchReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case constants.RETRIEVE_ERRORS.DOWNSTREAM_ERROR:
        draft.errorStatus = true;
        draft.errorType = action.type;
        break;
      case RECEIVE_ORDER_DETAILS_ERROR:      
      case constants.RETRIEVE_ERRORS.NOT_FOUND_ERROR:
        draft.errorStatus = true;
        draft.errorType = action.type;
        break; 
      case RECEIVE_ORDER_DETAILS:
        return {
          errorStatus: false,
          errorType: undefined,
        };
      default:
        return state;
    }
  });
};

export default orderSearchReducer;
