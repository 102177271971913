import React, {Component} from "react";
import StatusIconTD from "./StatusIconsTD";
import * as constants from "../Constants";
import TimelineMilestone from './TimelineMilestonesTD'
import inProgress from '../../images/in-Progress@1.5x.svg';

class OrderComplete extends Component {

    constructor(props) {
      super(props);
      this.renderDate = this.renderDate.bind(this);
      this.renderStatusIcon = this.renderStatusIcon.bind(this);
    }
    renderDate(){
        if(this.props.orderCompletionDate){
             return this.props.orderCompletionDate
        }else return ''
    }

    renderStatusIcon(){
        if(this.props.orderCompletionDate && this.props.orderStatus.toLowerCase() === constants.COMPLETE ||  this.props.orderStatus.toLowerCase() === constants.COMPLETED){
            return <StatusIconTD uniqueIdentifier="Order-Complete" status="Completed"/>
        }
        else if(this.props.orderStatus.toLowerCase() === constants.CANCELLED){
            return <StatusIconTD uniqueIdentifier="Order-Complete" status="cancelled"/>
        }
        else if(this.props.belongsToMilestone && this.props.orderStatus.toLowerCase() === constants.IN_PROGRESS){
            return <StatusIconTD uniqueIdentifier="Order-Complete" status="in progress"/>
        }
        else{
            return <StatusIconTD uniqueIdentifier="Order-Complete" status="pending"/>
        }
    }

    componentDidMount() {
        const allIconsCount = document.getElementsByClassName('all-timeline-icon').length;
        const completedIconsCount = document.getElementsByClassName('complete-timeline-icon').length;
        if(allIconsCount - completedIconsCount === 1 && document.getElementsByClassName('pending-timeline-icon').length === 1) {
            document.getElementsByClassName('pending-timeline-icon')[0].setAttribute('src', inProgress);
        }
    }

    render() {
        return (
          <>
            <TimelineMilestone
              title={this.props.orderStatus.toLowerCase() === constants.CANCELLED ? constants.ORDER_CANCELLED_MILESTONE_LABEL : constants.ORDER_COMPLETE_MILESTONE_LABEL}
              createdAt={this.renderDate()}
              icon={this.renderStatusIcon()}
              lineStyle={{ display: 'none' }}
              className="order-complete-td"
              nbnHealthCheckDetail={this.props.nbnHealthCheckDetail}
            />
            {
              this.props.showFooter && this.props.guaranteeMessageDetail ?
                <div id="Guarantee-Header" className="guarantee-header guarantee-header-td">
                    <div id="Guarantee-Detail-Text" className="guarantee-detail">
                      <span> 
                        { this.props.guaranteeMessageDetail.guaranteeMessage }
                        <a target="_blank" className="nbn-guarantee-link-td" href={ this.props.guaranteeMessageDetail.guaranteeLinkURL }>
                          { this.props.guaranteeMessageDetail.guaranteeLinkLabel }
                        </a>.
                      </span>
                    </div>
                </div> : ''
            }
        </>
        );
    }
}

export default OrderComplete;
