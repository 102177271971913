export const NBN_DETAILS_TITLE = "nbn co technician appointment"
export const FIXED_NBN_DETAILS_TITLE = "nbn appointment"
export const ASIK_DETAILS_TITLE = "Telstra assisted installation appointment"
export const RESCHEDULE_APPT = "Reschedule appointment";
export const NBN_RESCHEDULE_APPT_MSG = "If you reschedule your appointment, it will take up to 24 hours for the new details to be shown here.";
export const RESCHEDULE_APPT_MSG = "If you reschedule your appointment, it will take up to 24 hours for the new details to be shown here.";
export const IN_PROGRESS = "in progress";
export const COMPLETED = "completed";
export const CANCELLED =  "cancelled";
export const FAILED ="Failed"
export const COMPLETE = "complete";
export const ON_HOLD = "on hold";
export const QUEUED = "queued";
export const DETAILS_ILLUSTRATION_CANCELLED = "cancelled";
export const DETAILS_ILLUSTRATION_COMPLETED = "completed";
export const DETAILS_ILLUSTRATION_TELSTRA_TECH = "telstra_technician";
export const DETAILS_ILLUSTRATION_NBN_TECH = "nbn_technician";
export const DETAILS_ILLUSTRATION_DELIVERY_DELAY = "delivery_delay";
export const ENERGY_DETAILS_ILLUSTRATION_IN_PROGRESS = 'energy_in_progress';
export const ENERGY_DETAILS_ILLUSTRATION_ERROR = 'energy_error';
export const UNABLE_TO_RESCHEDULE = "We're unable to reschedule this appointment online";
export const FIXED_UNABLE_TO_RESCHEDULE="Unable to reschedule";
export const STEP_SHIPMENT_DETAILS = "shipmentDetails";
export const STEP_NBN_APPT_DETAILS ="nbnAppointmentDetails";
export const STEP_ASIK_APPT_DETAILS ="asikAppointmentDetails";
export const STEP_TELSTRA_APPT_DETAILS ="appointments";
export const STEP_DISCONNECTION_DETAILS ="disconnectionDetails";
export const STEP_TRANSFER_REQUEST_DETAILS = "transferRequestDetails";
export const STEP_NUMBER_TRANSFER_DETAILS = "numberTransferDetails";
export const MODEM_PLUGIN_DETAILS = "modemPluginDetails";
export const IDD_PACK_DETAILS = "iddPackDetail";
export const MODEM_PLUGIN_STATUS_NBN_CONNECTED = "NBN Connected";
export const ASIK_ATTENDANCE_LABEL = 'Please be available to answer our call for a guided installation of your hardware. If you wish to reschedule your appointment, please call us on 1800 931 584. We’re available from 8am to 5pm AEST.';
export const UNAUTH_RESCHEDULABLE_MSG = 'This order number is not linked to your account OR you need to be the authorised account holder to make changes. Please call us on <a href="tel:1800834273">1800 834 273</a> and follow the Check Order prompt. We’re available from 7am to 9pm AEST.';
export const AUTH_RESCHEDULABLE_MSG = "Unfortunately we’re unable to reschedule your appointment online. Please call us on {<a href='tel:1800834273'>1800 834 273</a>} and follow the Check Order prompt. We’re available from 7am to 9pm AEST.";
export const EU = "EU";
export const NON_EU = "NON EU";
export const ORDER_CATEGORY_NBN = "nbn";
export const ORDER_CATEGORY_TELSTRA = "telstra";
export const ORDER_CATEGORY_MOBILE_PREORDER = "mobile_pre_order";
export const ORDER_CATEGORY_MOBILE_POSTPAID = "mobile postpaid online shop";
export const ORDER_CATEGORY_SEMI_SUPPORTED = "semi-supported";
export const ORDER_CATEGORY_SEMI_SUPPORTED_NBNSIEBEL_PREFIX = "nbn_";
export const ORDER_CATEGORY_MOBILE_SUBSCRIPTION = "mobile subscription";
export const ORDER_CATEGORY_LOYALTY = "loyalty";
export const ORDER_CATEGORY_PREPAID = "prepaid";
export const ORDER_CATEGORY_SEMI_SUPPORTED_SUBSCRIPTION = "semi supported subscription";
export const ORDER_CATEGORY_SIM_SWAP_DISPATCH = "sim swap dispatch";
export const ORDER_CATEGORY_LOYALTY_BAU = "loyalty_bau";
export const ORDER_CATEGORY_ENERGY = "energy";
export const TELSTRA_PROFESSIONAL_INSTALL = "Telstra professional installation appointment";
export const APPT_REQUIRED = "Remember, someone over 18 needs to be home.";
export const INSTALLATION_MSG = "How to prepare for your Telstra professional installation appointment";
export const CONNECTION_DATE_UNAVAILABLE = "Your connection date is yet to be determined.";
export const CALENDAR_LABEL = "Add to your calendar";
export const STARTRACK = "StarTrack";
export const AUS_POST = "Australia Post";
export const BROADBAND_ADSL = "broadband adsl";
export const BROADBAND_VELOCITY = "broadband velocity";
export const TELSTRA_ORDER_CATEGORIES_ARR = ["broadband adsl", "broadband velocity", "broadband cable", "telstra", "pstn","platinum"];
export const BROADBAND_CABLE = "broadband cable";
export const PSTN = "pstn";
export const PLATINUM = "platinum";
export const HEADLESS = "headless";
export const NEXTGEN_OSM = "nextgen osm";
export const NEXTGEN_OSM_HDW = "nextgen osm hdw";
export const HEADLESS_PREORDER = "headless preorder";
export const FIXED = "fixed";
export const HIDE_DATE_ACTION = "hide date";
export const TRACK_DELIVERY = "Track your delivery";
export const UNHEALTHY = "unhealthy";
export const TRADEMARK_SYMB = "(TM)";
export const RED = "red";
export const GREEN = "green";
export const AMBER = "amber";
export const BLUE = "blue";
export const HARDWARE_DELIVERY = "hardware delivery";
export const HARDWARE_ONLY = "hardware only";
export const ORDER_COMPLETE = "order complete";
export const ORDER_COMPLETED = "order completed";
export const ORDER_PROCESSED = "order created";
export const ORDER_CANCELLED = "order cancelled";
export const SHIPMENT_BACKORDERED = "backordered";
export const NBN_SIEBEL_SEMISUPPORTED = "NBN_SIEBEL_SEMISUPPORTED";
export const NBN_SIEBEL_SEMISUPPORTED_HEADER = "Your order is in progress";
export const SHIPMENT_ICONS_ARR = ["ok", "problem", "attention", "in progress"];
export const SSO_DEV_APP_NAME = "order tracker"
export const SHIPMENT_ATTENTION = "attention";
export const ORDER_COMPLETE_MILESTONE_LABEL = "Order complete";
export const ORDER_CANCELLED_MILESTONE_LABEL = "Order cancelled";
export const HEADLESS_RESCHEDULE_APPT = "Call 1800 931 877 to reschedule your appointment"
export const DEFAULT_APPOINTMENT_SLOTS = '4';
export const APPOINTMENT_RESCHEDULE_EU_MSG = "Someone age 18 or over must be home for this appointment";
export const APPOINTMENT_RESCHEDULE_NON_EU_MSG = "You don't need to be home for this appointment";
export const APPPOINMENT_RESCHEDULE_ERROR_MSG_1 = "Sorry we are unable to reschedule this appointment online appointment at this time.";
export const APPPOINMENT_RESCHEDULE_ERROR_MSG_2 = "If you still need to reschedule please call 13 XXX XXX";
export const APPT_DETAILS = "appointment_details";
export const APPT_SLOTS = "appointment_slots";
export const DEFAULT_ERROR_MAIN_MSG = "There was a technical problem. Please try again later.";
export const APPOINTMENT_MANAGER_ERROR_PAGE_BUTTON = "Close";
export const APPPOINMENT_RESCHEDULE_SUCCESS_HEADER = "We’ve rescheduled your appointment";
export const APPPOINMENT_RESCHEDULE_SUCCESS_MSG = "You’ll receive a notification confirming your new appointment details shortly.";
export const COLLECTION_BANNER_MSG = "Sorry we missed you. Check the delivery card we left or the StarTrack link below for pick up details.";
export const COLLECTION_BANNER_HEADER = "Hardware delivery update";
export const BACKORDERED_TYPE = "backordered";
export const COLLECTION_TYPE = "awaiting collection";
export const CARD_BANNER_MSG = "Our courier wasn't able to deliver your hardware but has left a card for it to be collected. Follow the StarTrack link below for pick up details.";
export const CARD_BANNER_HEADER = "Hardware delivery update";
export const CARDED = "delivery attempted";
export const ERROR_MESSAGE_1 = "Please ensure the order ID you have entered is correct and try again.";
export const ERROR_MESSAGE_2 = "If your order was placed in the last 24 hours, it may not yet be available in our systems.";
export const ERROR_MESSAGE_COVID = "Due to COVID-19 impacts we are experiencing order delays. We will notify you once your order has progressed. We apologise for the inconvenience.";
export const APPT_CATEGORY_NBN = "nbn";
export const APPT_CATEGORY_TELSTRA = "telstra";
export const APPT_CATEGORY_ASIK_TELSTRA = "telstraasik";
export const PIK_ONLY_PONR1 = "You can’t reschedule your Telstra technician appointment online as it is occurring within the next 24 hours. To reschedule call 1800 931 877";
export const PIK_ONLY_PONR2 = "You can’t reschedule your Telstra technician appointment online as it is occurring today. To get help call 1800 931 877";
export const PIK_ONLY_PONR3 = "Your Telstra technician appointment has already occurred.";
export const PIK_ONLY_PONR4 = "The appointment has been cancelled.";
export const VIEW_SLOTS_BTN = "view available appointments";
export const CLOSE_BTN = "close";
export const CHANGE_SECOND_APPT = "You may need to change the 2nd appointment if you change this.";
export const RESCHEDULE_TELSTRA_APPT_MSG = "This appointment must be rescheduled a minimum of 2 days after your nbn co technician appointment";
export const NBN_CO_TECHNICIAN_TEXT = "co technician";
export const APPT_RESCHEDULED_LISTENER = "appointment rescheduled";
export const APPT_OVERRIDDEN_LISTENER = "appointment overridden";
export const COVID_TELL_ME_MORE = "https://www.telstra.com.au/covid19?ti=TR:TR:17032020:persnew:news:notifbar:def:cvr:top:DCAHD-4921:cvd19";
export const SIGN_IN_TEXT = "Sign in  >";
export const SIGN_OUT_TEXT = "Sign out  >";
export const TOKEN_EXPIRED_LISTENER = "expired";
export const NUMBER_TRANSFER = "phone number transfer";
export const SERVICE_ACTIVATION = "service activation";
export const ORDER_SEARCH_PAGE_NAME_ANALYTICS = "Order Tracker";
export const RETURN_LOGISTICS="return logistics";
export const FORWARD_LOGISTICS="forward logistics";
export const AWAITING_DEVICE_RETURN="awaiting device return";
export const CONSIGNMENT_RECEIVED="consignment received";
export const DEVICE_RECEIVED="device received";
export const CURRENT_APPOINTMENTS="Current appointments";
export const CURRENT_APPOINTMENT="Current appointment";
export const CONFIRM_APPOINTMENT="Confirm appointment";
export const CONFIRM_APPOINTMENTS="Confirm appointments";
export const GO_BACK="go back";
export const CLOSE_APPT_LISTENER = "close appointment";
export const CLICK_LOGIN_ON_DETAILS = "click login on details";
export const ADDRESS_TO_BE_DISCONNECT_ON = "Your old address will be disconnected on: ";
export const ADDRESS_DISCONNECTED_ON = "Disconnected on: ";

export const COVID_MSG = "COVID-19 impacts are causing delays on some orders. Your order should arrive within 5 days if you’re in a metro location.";
export const ORDER_DETAILS_PAGE_NAME_ANALYTICS = "Order Details";
export const APPOINTMENT_DETAILS_PAGE_NAME_ANALYTICS = "Appointment Details";
export const APPOINTMENT_SLOTS_PAGE_NAME_ANALYTICS = "Appointment Slots";
export const SIK_CLICK_ANALYTICS = "Self install instructions";
export const TRACK_YOUR_DELIVERY_CLICK_ANALYTICS = "Track your delivery";
export const MESSAGE_US_CLICK_FOR_FIXED_APPTS_ANALYTICS = "Message Us for Fixed order appointments";
export const MANAGE_YOUR_NBN_APPOINTMENTS_ANALYTICS = "Manage your NBN appointments";
export const MANAGE_YOUR_TELSTRA_APPOINTMENTS_ANALYTICS = "Manage your Telstra appointments";
export const MANAGE_YOUR_APPOINTMENTS_ANALYTICS = "Reschedule appointment";
export const PIK_CLICK_ANALYTICS = "How to prepare for your Telstra appointment";
export const TAM_CLICK_ANALYTICS = "Reschedule Appointment via TAM";
export const SIGNIN_CLICK_ANALYTICS = "Sign in";
export const SIGNOUT_CLICK_ANALYTICS = "Sign out";
export const CONFIRM_APPOINTMENT_CLICK_ANALYTICS = "Confirm Appointment";
export const HOW_TO_PREPARE_NBN_ANALYTICS = "How to prepare for your nbn appointment";
//APPOINTMENT EVENTS
export const RSCHEDULE_APPT_ANALYTICS = "Reschedule appointment";

export const ORDER_DASHBOARD_PAGE_NAME_ANALYTICS = "Orders Dashboard"
export const OPEN_ORDERS_TAB_CLICK_ANALYTICS = "Open Orders tab selected"
export const PAST_ORDERS_TAB_CLICK_ANALYTICS = "Past Orders tab selected"
export const SSO_EXPIRED_TOKEN_FOR_LOGOUT_STAGE = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjgwNjI0Yzg5LWJiNzItNDFhZC1iZDhhLWE1NTkyNDlkNzIwMSJ9.eyJhdF9oYXNoIjoiTF9fQ1RZN1FvUHViaGpLNXpCTUhGdyIsInN1YiI6Im5OUHFUSG5CYTdQcjFnQ0Z3VjItUk9DU2NvaEl2V3NfUERDTkRzakVhVGsiLCJhdWQiOiJRcm1IUFd0UzYyVkpBdU5FWXVSWU5uVXBRY3dKYXhDcyIsImF1dGhfbGV2ZWwiOjI1MCwiaXNzIjoiaHR0cHM6XC9cL2FwaS50ZWxzdHJhLmNvbVwvc3NvIiwiZXhwIjoxNjAxNTMxMDM5LCJpYXQiOjE2MDE1MzAxNzAsIm5vbmNlIjoiMTU1ZDcwOTMzY2YwNDVkMDllODNlMmRjNjhkOGVjMzkifQ.XXInRiWftvevhPMyemxocEQiAdB3ZC3zNltX9V3InYDIiglYtcTZ5fpOaCDBiN6R-tA-cb1JnqeIR4uTtCJh6mPr2fKDSinpIKJmoIaYRYq7EJcALP__aDRAgN3TLq0VbgstPjXc_FakMnDuBfx4eK-d1AXBEOGbiu5bdfpDKsjHbGhdWoBufvev9iP7dd2khSpVeQ9DMjPvcgYK0M8s95TL0BLzSH5IFYJkOaiYLM9YdAxvRX-zDzfS4r8dSXah4vSILvSiVSdLQFDreeGny69f9uYJNgxMHMtagdBnutKZwaYPROfte71Gt9ORMUgyvhL5I7R4q-LzEUFaT7EXlg";
export const SSO_EXPIRED_TOKEN_FOR_LOGOUT_PROD = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjgwNjI0Yzg5LWJiNzItNDFhZC1iZDhhLWE1NTkyNDlkNzIwMSJ9.eyJhdF9oYXNoIjoidDVnYjVhenpWR2Y3V1A5dGNab1Y3dyIsInN1YiI6Im5OUHFUSG5CYTdQcjFnQ0Z3VjItUk9DU2NvaEl2V3NfUERDTkRzakVhVGsiLCJhdWQiOiJVTThxZEkzTFA5QUdHdzBrQWs1Vko5UklDWUFvaEV5ZCIsImF1dGhfbGV2ZWwiOjI1MCwiaXNzIjoiaHR0cHM6XC9cL2FwaS50ZWxzdHJhLmNvbVwvc3NvIiwiZXhwIjoxNjAzMjc1NTk3LCJpYXQiOjE2MDMyNzQ3Mjd9.U6jdqFC1HGrphH9yS6Ywh5x7S3ILOWqkjLtJKS1z3AGcmA_OLPbIIj9A3tHJXxmYKmgVGq6u1BPVxAPrR2I4LNdXdIX2GyRmrv8NVEyR1Jjy35GZJCujHlpDDqjkIx8Yoyh_9qSOgf_Sq_0q-ibwvCZmg9t1IWT96i_NQNcYP-Z1HFGiBN9oD0bFFduhCUiM5wDaekIgn0HykccPvPb8R27G52YlB2Nh36lEJCu2XRL_P2JLnlf39Je-IkLhsb3vUbPeJQxXFFgLqeRnCzMzJaaiBkQNdroBvjYkyFFhppEPKowRd44AwK6J6gYtDbntNZjFl2MEYhp-7jx2ZAWkhQ";
export const SSO_EXPIRED_TOKEN_FOR_LOGOUT_SIT = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjgwNjI0Yzg5LWJiNzItNDFhZC1iZDhhLWE1NTkyNDlkNzIwMSJ9.eyJhdF9oYXNoIjoibzJTZ0Jtb1lHMG1jRHN0ZjcyRU5UdyIsInN1YiI6ImE3ckg2RTVoT2JmT19wS2h1SDdrRVRwREdmdm1WQU82X1Y3R1NEYm0wTzQiLCJhdWQiOiJuOGVmbTJHSXh1dmdjSTBSbUNhR1VlNWxobU1ha1U1dSIsImF1dGhfbGV2ZWwiOjI1MCwiaXNzIjoiaHR0cHM6XC9cL2FwaS50ZWxzdHJhLmNvbVwvc3NvIiwiZXhwIjoxNjAzMjc2MzUzLCJpYXQiOjE2MDMyNzU0ODMsIm5vbmNlIjoiMzE3NjBkYmMzZjVhNDRmMGJmNDM1ZTEwYjNmNjgzYWEifQ.AEkLUjcrhsU6wC_ZS5d9vkQmkDYodW8s7RSJSmWfdFfMRRWY2l_LEd_AehmmgIGAtbvI9_XqrlRiwHxdUnmMiVuUnhChzzAv64bIBuXJ9S9GLfW7Gn_C19ef86MbJUPjlb3Voeu_W-vRhRSce_UolvFJCg37N2XiXnCO7FFAgu-aM9W3rwi1JGSFQt6OsUe4P65JxtJuGi-g-HQzyMitZ7UIUY5FhdgLv7kUUSdW7hqggs7Eo5sl1QYwvvfR9o2feMHAIzEI7AB_mkAcfm01o5hEAKjaRWFKahmDWrVGW4SvmWgvXUW-KLfqCWX531KjFjQc4tZgkpJ1C3vNd57LPQ&access_token=5bTT5YfaRWIEz7pzTZ1vshmM5LRE";

export const SIT_ENV = "sit";
export const PROD_ENV = "production";
export const STAGE_ENV = "stage";
export const PLAN_UPGRADE= "plan upgrade";
export const PLAN_DOWNGRADE= "plan downgrade";

export const BEING_PROCESSED = "Being Processed";
export const RESCHEDULE_APPT_BANNER_LINK= "#RescheduleAppointment";

export const MOVES_EMAIL_LINK = "#MOVES_EMAIL#";
export const MOVES_EMAIL_SUBJECT = 'Request for call -';
export const MOVES_EMAIL_BODY = 'To arrange for our dedicated Moves Team to contact you, please complete the below information. Our moves experts will respond as soon as possible, during Monday-Friday 8am-7pm.%0d%0dIf outside of these hours and your request is urgent please call 132200.%0d%0d';
export const MOVES_EMAIL_BODY_PROVIDE_DETAILS = 'Please provide below details: %0d%0d%E2%80%A2 Your full name:%0d%E2%80%A2 Preferred contact time: Dd/mm HH am/pm%0d%E2%80%A2 Preferred contact number: 0400 000 000%0d %E2%80%A2Additional information: Anything else you have to add'

export const MESSAGE_US_ANALYTICS = 'Message us';

// Session inactivity configs, time is in minutes.
export const SESSION_ACTIVITY_TIMEOUT = 13;
export const SESSION_USER_INPUT_WINDOW_TIMEOUT = 3;

export const ORDER_DETAILS_VIEWED_ANALYTICS = 'orderDetailsViewed';

export const TECH_TYPE_DUAL_FUEL_ENERGY_ORDER = "Dual Fuel";

export const ILLUSTRATION_DEFAULT_ALT_TEXT = "Order is in progress";
export const ILLUSTRATION_IN_PROGRESS_ALT_TEXT = "Order is in progress";
export const ILLUSTRATION_COMPLETE_ALT_TEXT = "Order is complete";
export const ILLUSTRATION_ERROR_ALT_TEXT = "Order has information";
export const ILLUSTRATION_CANCELLED_ALT_TEXT = "Order is cancelled";
export const ILLUSTRATION_DELIVERY_DELAY_ALT_TEXT = "Order is delayed";
export const ILLUSTRATION_TECHNICIAN_ALT_TEXT = "Order has nbn or telstra appointment";



export const FIXED_NBN_APPT_STATIC_HEADING = "What to expect?";
export const FIXED_NBN_APPT_STATIC_BODY = "The nbn technician will connect the cable from your property to the nbn network available in your area.";

export const TELSTRA_FIXED_APPT_TITLE = "Telstra appointment";
export const WHAT_TO_EXPECT_FIXED_TELSTRA_APPT_LABEL = "What to expect?";
export const WHAT_TO_EXPECT_FIXED_TELSTRA_APPT_TEXT ="The Telstra technician will visit to finalize internet setup to your place.";

