import React from "react";
import OrderDetailsBreadcrumbs from '../../presenters/components/OrderDetailBreadcrumbsTD';

export default function Breadcrums({ items }) {

  return (
    <div id="page-breadcrumbs">
      <OrderDetailsBreadcrumbs />
    </div>
  );
}