import React, { Component } from "react";
import { openInNewTab, openChat } from "../../utils";
import StatusIconTD from "./StatusIconsTD";
import AddToCalendar from './AddToCalendar';
import * as constants from "../Constants";
import { isMobile } from 'react-device-detect';
import FileSaver from 'file-saver';
import TimelineMilestone from './TimelineMilestonesTD'
import { ActionButton, InlineLink } from '@able/react';
import infoIcon from '../../images/icon-info-blue.svg';
import * as analytics from '../../analytics-utils';

class UnitiAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = { showApptPopup: false };
        this.isReschedulable = false;
        this.handleMessageUsClick = this.handleMessageUsClick.bind(this);
    }

    render() {
        const { appointment, orderCategory } = this.props;
        return orderCategory.toLowerCase() === constants.FIXED ? this.renderFixedUnitiAppointment(appointment) : null;
    }

    renderFixedUnitiAppointment(unitiAppointment) {
        if (unitiAppointment) {
            return (
            <TimelineMilestone
                title={unitiAppointment.title || constants.FIXED_UNITI_DETAILS_TITLE}
                lineColor={unitiAppointment.iconStatus && unitiAppointment.iconStatus.toLowerCase() !== 'completed' ? '#d8d8d8' : '#0064D2'}
                createdAt={
                    <>
                        {unitiAppointment.date && <div className="milestone-date">{unitiAppointment.date}</div>}
                        {unitiAppointment.slot && unitiAppointment.status && unitiAppointment.status.toLowerCase() === constants.IN_PROGRESS && <div className="milestone-date">{unitiAppointment.slot.toLowerCase()}</div>}
                    </>}
                icon={this.renderStatusIcon()}
                >
                  {unitiAppointment.displayStatus && <div id="Uniti-Appointment-Status">{unitiAppointment.displayStatus}</div>}
                  {unitiAppointment.typeText && unitiAppointment.status && unitiAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                      <div id = "Uniti-Appointment-Type-Container">
                          <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                          <div id="Uniti-Appointment-Type" className="attendance-label appointment-type">{unitiAppointment.typeText}</div>
                      </div>}
                  {unitiAppointment.status && unitiAppointment.status.toLowerCase() !== constants.COMPLETED && this.renderRescheduleAppointmentModule()}
                  {unitiAppointment.calendarEvent && this.renderAddToCalendar(unitiAppointment.calendarEvent)}

                  {unitiAppointment.status && unitiAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                      <div id="Uniti-Appointment-Static-Heading">{constants.FIXED_NBN_APPT_STATIC_HEADING}</div>}
                  {unitiAppointment.status && unitiAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                      <div id="Uniti-Appointment-Static-Body">{constants.FIXED_UNITI_APPT_STATIC_BODY}</div>}

                  {unitiAppointment.status && unitiAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                      <div>
                          <ActionButton variant="LowEmphasis" label="Learn more" onClick={(e) => this.onClickOfHowToPrepareForYourUnitiAppt(e)}/>
                      </div>}
            </TimelineMilestone>
            )
        }
        return null;
    }

    onClickOfHowToPrepareForYourUnitiAppt(e) {
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.HOW_TO_PREPARE_UNITI_ANALYTICS);
        openInNewTab(e, constants.T_DOT_COM);
    }

    renderStatusIcon() {
        const { appointment, reasonForUnHealthyData } = this.props;
        let customAction;
        if (reasonForUnHealthyData && reasonForUnHealthyData.unhealthyCustomAction) {
            customAction = reasonForUnHealthyData.unhealthyCustomAction;
        }
        return appointment.iconStatus ?
            <StatusIconTD uniqueIdentifier="Uniti-Appointment" status={appointment.iconStatus} customAction={customAction}/> : '';
    }

    renderAddToCalendar(event) {
        let icon = { textOnly: 'none' };
        let calendarForMobile = null;

        if (isMobile) {
            return (
                <div id="Uniti-Add-To-Calender" className="flex-row add-to-calender-td">
                    <a href="javascript:window.location.reload(false)" onClick={(e) => {
                        var icsCalendar = "BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nURL:" + e + "\nDTSTART:" + event.startTime + "Z" + "\nDTEND:" + event.endTime + "Z" + "\nLOCATION:" + event.location + "\nSUMMARY:" + event.title + "\nDESCRIPTION:" + event.description + "\nEND:VEVENT\nEND:VCALENDAR";
                        var blob = new Blob([icsCalendar], { type: 'text/plain;charset=utf-8' })
                        FileSaver.saveAs(blob, 'Appointment.ics')
                    }}>
                        <AddToCalendar listItems={calendarForMobile} />
                    </a>
                </div>
            );
        }
        return (
            <div id="Uniti-Add-To-Calender" className="flex-row add-to-calender-td">
                <AddToCalendar event={event} buttonLabel="Add to your calendar" buttonTemplate={icon} />
            </div>
        );
    }

    handleMessageUsClick(e) {
      e.preventDefault();
      openChat('message us', ["telstra", "gbs", "nbn-activation-issue", "dtq", "ost"], this.props.orderDetails)
    }

    renderRescheduleAppointmentModule() {
      return <div id="Uniti-Appointment-Reschedule-Message">
          <a onClick={this.handleMessageUsClick}>
            Message us
          </a>&nbsp;to reschedule your appointment
        </div>
    }

  }

export default UnitiAppointment;
