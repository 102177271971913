import { browserHistory } from 'react-router'
import * as analytics from './analytics-utils';
import * as utils from './utils';
import * as constants from './presenters/Constants';
const crypto = require('crypto');
let caimanLoginPath = window.env.REACT_APP_CAIMAN_API_BASE_URL;
let caimanClientId = window.env.REACT_APP_CLIENT_ID;
const linkPattern = /\[(?<text>[\w_\s]+)\]\((?<href>[\S]+)\)/ig;

export function base64URLEncode(str) {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

export function sha256(buffer) {
    return crypto.createHash('sha256').update(buffer).digest();
}
export function redirect(e, url) {
    if (e) e.preventDefault();
    window.location = url
}

export function openInNewTab(e, url) {
    if (e) e.preventDefault();
    if(url && !url.startsWith("https://") && !url.startsWith("http://"))
    url = `https://${url}`

    window.open(url, '_blank')
}

export function addClickEventToOmnitureObject(eventAction) {
    analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, eventAction);
}

export function openInSameTab(e, url) {
    if (e && e !== undefined && e.ctrlKey) {
        window.open(url, '_blank')
    }
    else {
        if (e) e.preventDefault();
        window.open(url, '_self')
    }
}

export function route(e, url) {
    if (e) e.preventDefault();
    browserHistory.push(url)
}

export function extractDayWithSuffix(dateStr) {
    if (!dateStr) {
        return "";
    }
    let day = dateStr.split(' ', 2)[1];
    let j = day % 10,
        k = day % 100;
    if (j === 1 && k !== 11) {
        return day + "st";
    }
    if (j === 2 && k !== 12) {
        return day + "nd";
    }
    if (j === 3 && k !== 13) {
        return day + "rd";
    }
    return day + "th";
}

export function extractMonthYear(dateStr) {
    if (!dateStr) {
        return "";
    }
    let dateComps = dateStr.split(' ');
    return dateComps[2] + " " + dateComps[3];
}

export function isLocalStorageEnabled() {
    try {
        var mod = '__storage_test__';
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        return true;
    } catch (e) {
        return false;
    }
}
export function convertToTimestamp(date){
    return date.toISOString();
}

export function convertDateToAppointmentAPITimestamp(date){
    return date.toISOString().substring(0, 19);
}

 export function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function isMobileDevice() {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)) {
        return true;
    }
}

export function isIpadDevice() {
    if (navigator.userAgent.match(/iPad/i)) {
        return true;
    }
}

export function handleSignInSignOut(e, location, accessToken = null, isSilent = false) {
    if (accessToken) {
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.SIGNOUT_CLICK_ANALYTICS, null);    
        let postLogoutRedirectUri = null;
        if(location && location.includes('orderdetails')) {
            postLogoutRedirectUri = window.env.REACT_APP_HOST_NAME + '/orderdetails';
        }
        utils.logout(e, postLogoutRedirectUri);
    } else {
        if (e) e.preventDefault();
        let redirectUrl = window.env.REACT_APP_HOST_NAME + '/dashboard';  
        let omniturePagename = constants.ORDER_SEARCH_PAGE_NAME_ANALYTICS;      
        if(location && location.includes('orderdetails')) {      
            omniturePagename = constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS
            redirectUrl = window.env.REACT_APP_HOST_NAME + '/orderdetails';        
        } else if(isSilent) { // on order search page
            redirectUrl = window.env.REACT_APP_HOST_NAME;
        }
        analytics.addOmnitureObjectForEventClick(omniturePagename, constants.SIGNIN_CLICK_ANALYTICS, null);
        utils.goToSignIn(e, redirectUrl, isSilent);        
    }
};

export const verifyOrderId = (orderId) => {
    const validPatterns = [
        /^1-(\d{1,13})$/,
        /^NBNO-(\d{1,7})$/,
        /^NBNTR-(\d{1,7})$/,
        /^SBS(\d{1,8})$/,
        /^SMB(\d{1,5})$/,
        /^SR1-(\d{1,13})$/,
        /^MPMO-(\d{1,13})$/,
        /^[a-zA-Z]{1}[0-9]{14}$/,
        /^H([a-zA-Z0-9]{7})$/,
        /^[Ii]{1}[Ss]{1}[Pp]{1}[0-9]{10}$/,
        /^[0-9]{14,16}$/,

        // Loyalty orders
        /^ACC[0-9]+/,
        /^LYO[0-9]+/,
        /^LYH[0-9]+/,
        /^LBO[0-9]+/,
        /^LBH[0-9]+/,
    ];

    // For Energy orders
    if(isEdgarEnabled()) validPatterns.push(/^ENEO-[0-9]+/);

    for (let i = 0; i < validPatterns.length; i++)
        if (validPatterns[i].test(orderId)) return true;
        else if (i === validPatterns.length - 1) return false;
}

export function goToSignIn(e, redirectUrl, isSilent) { 
    let verifier = base64URLEncode(crypto.randomBytes(32));
    localStorage.setItem('caiman_verifier', verifier);    
    let challenge = base64URLEncode(sha256(verifier));
    let silentCallParam = "";
    if(isSilent) {
        silentCallParam = "&prompt=none";
    }
    let url = caimanLoginPath + '/as/authorization.oauth2?response_type=code&client_id=' + caimanClientId + '&redirect_uri=' + redirectUrl + '&scope=openid hi b2c&code_challenge_method=S256&response_mode=query&code_challenge=' + challenge + silentCallParam;

    utils.openInSameTab(e, url);    
}

export function getAccessToken (code, pathname) {
    let params = new URLSearchParams();
    let location = Object.assign({}, browserHistory.getCurrentLocation());
    let orderId = localStorage.getItem('orderNumber');
    let redirectUrl = null;
    if(pathname && pathname.includes('orderdetails')) { 
        redirectUrl = window.env.REACT_APP_HOST_NAME + '/orderdetails'
    } else if(pathname && pathname.includes('dashboard') ) {
        redirectUrl = window.env.REACT_APP_HOST_NAME + '/dashboard'
    } else {//for order search home page
        redirectUrl = window.env.REACT_APP_HOST_NAME;
    }
    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    params.append('client_id', caimanClientId);
    params.append('redirect_uri', redirectUrl);
    params.append('code_verifier', localStorage.getItem('caiman_verifier'));
    //let location = Object.assign({}, browserHistory.getCurrentLocation());
    delete location.query['code'];
    if(pathname && pathname.includes('orderdetails')) { 
        if (orderId !== null || orderId !== undefined){
            location.pathname = "/orderdetails/"+ orderId;
        }
    }
    browserHistory.replace(location);

    return fetch(caimanLoginPath + '/as/token.oauth2', {
        method: 'post',
        body: params
    }).then(response => {
        return response.json();
    })
        .then(json => {
            if (json && json.access_token) {
                 localStorage.setItem('accessToken', json.access_token);
                return json;
            }
        })
        .catch((ex) => {
            console.log('error in getting token');
            console.log(ex);
        })
}

export function logout(e, postLogoutRedirectUri) {    
    localStorage.removeItem('accessToken');
    if(!postLogoutRedirectUri) {
        postLogoutRedirectUri = 'https://www.telstra.com.au/';
    }  
    let url = caimanLoginPath+'/idp/startSLO.ping?TargetResource='+postLogoutRedirectUri;
    
    utils.openInSameTab(e, url);    
}

export function openChat(linkText,chatGroupTags,orderDetails) {
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.MESSAGE_US_ANALYTICS);
        const chatParams = {
            isDirect: true,
            section: chatGroupTags,
        };
        const data = {
            type: 'error',
            error: {
                contextId: orderDetails?.orderSummary?.planName,
                message: `Customer Message : ${linkText} <br>
                    Order ID: ${orderDetails?.orderSummary?.orderId}
                    Order Status: ${orderDetails?.orderSummary?.orderStatus}`
            }
        }
        window.livePerson.show(chatParams);
        window.lpTag.sdes.push(data);
    }

export function isApptMgrEnabledForNBNCategory(){
    const toggleFeature_OSTK138 = window.env.REACT_APP_TOGGLE_OSTK_138;
    if(toggleFeature_OSTK138 && toggleFeature_OSTK138 === 'true') {
        return true;
    }
}

export function isEdgarEnabled() {
    // Since it's a string
    return window.env.REACT_APP_IS_EDGAR_ENABLED === "true";    
}

export function isDynamicTimelineEnabled() {
    return window.env.REACT_APP_IS_DYNAMIC_TIMELINE_ENABLED === "true";
}

/**
 * Handles behaviour to open chat box or new tab considering mobile or desktop platform
 * 
 * @param {*} e 
 * @param {string} desktopLink: required 
 * @param {string} mobileLink
 * @param {string} chatGroupTags: required if desktopLink == 'open_chat'
 * @param {*} orderDetails: required if desktopLink == 'open_chat'
 * @returns 
 */
 export function handleCtaLinkClick(e, desktopLink, mobileLink, chatGroupTags, header) {
    chatGroupTags = chatGroupTags || ['telstra', 'ost', 'troubleshoot']
    mobileLink = mobileLink || desktopLink
  
    // handle seperately for mobile device
    if(isMobileDevice() || isIpadDevice()) {
        if (isSoftPhoneLink(mobileLink)) {
            openInSameTab(e, mobileLink)
        } else {
            openInNewTab(e, mobileLink)
        }
        return
    }
  
    if(desktopLink === 'open_chat') {
      const summary = {
        orderSummary: {
          planName: header?.orderDescription,
          orderId: header?.orderNumber,
          orderStatus: header?.orderStatus
        }
      }
      openChat('message us', chatGroupTags, summary)
      return
    }

    //open link in same tab for soft phone
    if (isSoftPhoneLink(desktopLink)) {
        openInSameTab(e, desktopLink)
    }
    
    // open link in new tab for desktop
    openInNewTab(e, desktopLink)
  }

  export function isSoftPhoneLink(link){
    return link && link.startsWith('tel:')
  }

export function substituteLinks(data) {
  const result = [];
  let updatedData = new String(data);
  for(const match of data.matchAll(linkPattern)) {
    let index = updatedData.indexOf(match[0]);
    result.push(updatedData.slice(0, index));
    result.push(
      <a target="_blank" href={match.groups.href} key={result.length}>
        {match.groups.text}
      </a>
    );
    updatedData = updatedData.slice(index + match[0].length);
  }
  if(updatedData) {
    result.push(updatedData);
  }
  return <>{result}</>;
}