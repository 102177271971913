import React, { Component } from 'react';
import HeaderTD, { Breadcrumbs } from './HeaderTD';
import queryString from "query-string";
import { browserHistory } from 'react-router';
import {
  Static_contentTD,
  AlternativeMethodsTD,
  StripBannerTD,
  // RetrieveErrorTD,
  BackToTopTD,
} from './OrderSearchComponentsTD';
import { Row, Column } from "react-foundation-components/lib/global/grid";
import Spinner from "../../Spinner/Spinner"
import ErrorMessage from './ErrorComponentTD';
import BannerMessage from "./BannerComponentTD";
import OrderTrackerFormTD from './OrderTrackerFormTD';
import { route } from '../../utils';
import * as analytics from '../../analytics-utils';
import * as constants from "../Constants";
import CovidComponent from "../CovidComponent";
import FooterWrapper from './FooterWrapperTD';
import * as apifetch from "../../adapters/apiFetch";
import * as utils from '../../utils';
import SessionTimeout from './SessionTimeout';

export default class OrderSearchContainer extends Component {
  componentDidMount() {
    document.body.style.backgroundColor = "#fff";    
  }

  componentDidUpdate(prevProps) {
    if (this.props.orderDetails !== prevProps.orderDetails) {
      if (this.props.orderDetails.isUnauthorized === true && this.props.orderDetails.orderId) {
        route(undefined, "/orderdetails/" + this.props.orderDetails.orderId);
      }
    }
    // console.log(prevProps, this.props)
  }

  closeCovidBanner() {
    this.setState({ showCovid: false });
  }

  constructor(props) {
    super(props);
    this.pageUp = React.createRef();
    this.scrollToTop = this.scrollToTop.bind(this);
    this.displayLoaderOnSearch = this.displayLoaderOnSearch.bind(this);
    this.accessToken = null;    
    this.loading = true;
    this.state = { showCovid: false, showSpinnerOnSearch: false };
    this.urlParams = this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : null;
    this.errorStatusValue = localStorage.getItem('errorStatus');
    if (window.env.REACT_APP_DEV_ENV === "true") { //since the env variable is of type string
      this.processbrowserLocation();
    } else {
      this.processAuthFlowForOrderSearch();
    }

  }

  displayLoaderOnSearch(flag) {
    this.setState({showSpinnerOnSearch: flag});
  }

  processAuthFlowForOrderSearch() {
    let isSilent = true;
    
    if (this.urlParams === null || this.urlParams === undefined) {      
      localStorage.setItem('errorStatus', this.props.errorStatus );
      utils.handleSignInSignOut(null, this.props.location.pathname, null, isSilent);      
    } else if (this.urlParams && this.urlParams.code && this.urlParams.code !== undefined) {
      let code = this.urlParams.code;
      let pathname = this.props && this.props.location && this.props.location.pathname ? this.props.location.pathname : null;
      utils.getAccessToken(code, pathname).then(authToken => {
        if (authToken) {
          this.accessToken = authToken.access_token;
          this.loading = false;
          this.setState({ showCovid: true });          
        }
      });
    } else { //default case      
      this.processbrowserLocation();
    }
  }
  processbrowserLocation() {
    let location = Object.assign({}, browserHistory.getCurrentLocation());
    if (location.search)
      delete location.search;

    if (location.hash)
      delete location.hash;

    if (location.query['error'])
      delete location.query['error'];      
    browserHistory.replace(location);
    this.loading = false;    
    this.setState({ showCovid: true });

  }
  scrollToTop() {
    window.scrollTo(0, this.pageUp.offsetTop);
  }

  render() {   
    const { orderDetails: { errorCode = '' } } = this.props;
    return (
      <React.Fragment>       
        {
          this.loading ?
            <Row collapse expanded>
              <Column largeCentered="centered" large={8}>
                <div className="telstra-spinner-box">
                  <div>
                    <Spinner size="large" />
                  </div>
                </div>
              </Column>
            </Row> :
            <React.Fragment>
              {this.state.showCovid ? <CovidComponent closeCovidBanner={this.closeCovidBanner.bind(this)} /> : ''}
              <HeaderTD ref={this.pageUp} accessToken={this.accessToken} location={this.props.location.pathname} />
              <div className="inner-container">
                <Breadcrumbs />
              </div>

              {(this.state.showSpinnerOnSearch) && !(this.props.errorStatus || this.errorStatusValue === "true") ?
                  <div>
                    <Row collapse expanded>
                      <Column largeCentered="centered" large={8}>
                        <div className="telstra-spinner-box">
                          <div>
                            <Spinner size="large" />
                          </div>
                        </div>
                      </Column>
                    </Row>
                  </div>
                  :
                  <div>
                    <div className="parent-container">
                      <Static_contentTD/>
                    </div>
                    {this.addOmnitureObjectonPageLoad()}

                    <div className='inner-container'>
                {(this.props.errorStatus || this.errorStatusValue === "true") && <ErrorMessage errorType={this.props.errorType} errorCode={errorCode} className="error-summary-banner" />}
                      <OrderTrackerFormTD
                          displayLoaderOnSearch={(flag) => this.displayLoaderOnSearch(flag)}
                          accessToken={this.accessToken}
                          fetchUnauthOrderDetails={this.props.fetchUnauthOrderDetails}
                          fetchAuthOrderDetails={this.props.fetchAuthOrderDetails}
                      />
                      <AlternativeMethodsTD signedIn={!!this.accessToken}/>
                      <StripBannerTD/>
                      <BackToTopTD scrollToTop={this.scrollToTop}/>
                    </div>
                    <FooterWrapper/>
                  </div>
              }
            </React.Fragment>
        }
        {this.accessToken && <SessionTimeout />}
      </React.Fragment>
    );
  }  

  addOmnitureObjectonPageLoad() {
    let pageObj = null;
    let applicationObj = null;        

    pageObj = analytics.createPageObj(constants.ORDER_SEARCH_PAGE_NAME_ANALYTICS);
    applicationObj = this.setApplicationObjProps();   
    this.pageObj = pageObj;
    this.applicationObj = applicationObj;

    analytics.createDateLayerObj(pageObj, applicationObj); 
  }

  setApplicationObjProps() {        
      let applicationObj = analytics.createApplicationObj(null, this.accessToken);
      return applicationObj;
  }
}
