// Woraround to only load telstra babel polyfill once
// global._babelPolyfill = false;
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router';
import store, { history } from './store';
import 'foundation-sites/dist/foundation.min.css';
import ConnectedDashboard from './connectors/ConnectedDashboard';
import Callback from './presenters/Callback';
import ConnectedOrderSearchContainerTD from './connectors/ConnectedOrderSearchContainerTD';
import ConnectedOrderStatusTrackerTD from './connectors/ConnectedOrderStatusTrackerTD';
import "@able/react/dist/able-react.min.css";
import './css/index.css';
import '@able/web/dist/able-web.css';

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            {/* NEW ROUTES */}
            <Route exact path='/' component={ConnectedOrderSearchContainerTD} />
            <Route exact path='/orderdetails/:orderNumber' component={ConnectedOrderStatusTrackerTD}/>
            <Route exact path='/orderdetails' component={ConnectedOrderStatusTrackerTD}/>
            <Route path='/callback' component={Callback} />
            <Route path='/dashboard' component={ConnectedDashboard} />
        </Router>
    </Provider>,
    document.getElementById('root')
);
