import React, {Component} from "react";
import queryString from "query-string";
import Order from "./OrderTD";
import { Row , Column} from "react-foundation-components/lib/global/grid";
import Header from "./HeaderTD";
import Spinner from "../../Spinner/Spinner";
import CovidComponent from "../CovidComponent";
import { StripBannerTD, BackToTopTD } from './OrderSearchComponentsTD';
import DashboardBreadcrumbsTD from './DashboardBreadcrumbsTD';
import FooterWrapper from './FooterWrapperTD'
import ErrorComponentTD from './ErrorComponentTD';
import * as analytics from '../../analytics-utils';
import * as constants from "../Constants";
import * as utils from '../../utils';
import SessionTimeout from "./SessionTimeout";
import emptyScreenImg from '../../images/Empty.svg';

class Dashboard extends Component {

    constructor(props) {
        super(props);
        
        // without TelstraAuth

        //  this.props.subscribeToAuthEvent();
        //  let hashParams = queryString.parse(this.props.location.hash);

        //  if(hashParams && hashParams.access_token){
        //      this.props.fetchOrders(hashParams.access_token);
        //      this.firstTime = false;
        //  }
        this.pageUp = React.createRef()
        this.renderOrders = this.renderOrders.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.getOpenOrdersClassName = this.getOpenOrdersClassName.bind(this);
        this.getPastOrdersClassName = this.getPastOrdersClassName.bind(this);
        this.selectOpenOrders = this.selectOpenOrders.bind(this);
        this.selectPastOrders = this.selectPastOrders.bind(this);
        this.renderEmptyOrder = this.renderEmptyOrder.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.accessToken = null;
        this.urlParams = this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : null;

        //with TelstraAuth
        if(window.env.REACT_APP_DEV_ENV === "true") { //since the env variable is of type string
            this.props.fetchOrders(123);
        } else {
            this.processAuthFlowForDashboard();
        }
        this.state = {isOpenOrdersActive: true, isPastOrdersActive: false, showCovid: false};        
    }

    processAuthFlowForDashboard() {
        if(this.urlParams === null || this.urlParams === undefined || (this.urlParams && this.urlParams.code === undefined ) ) {
            utils.handleSignInSignOut(null, this.props.location.path);
        } else if(this.urlParams && this.urlParams.code && this.urlParams.code !== undefined) {
            let code = this.urlParams.code;
            let pathname = this.props && this.props.location && this.props.location.pathname ? this.props.location.pathname : null;
            utils.getAccessToken(code, pathname).then(authToken => {
                if(authToken) {
                    this.accessToken = authToken.access_token;
                    this.props.fetchOrders(authToken.access_token);
                    //this.props.fetchAppointmentDetailsApiAuth(authToken.access_token, this.orderId);
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#fff";
        //updateDataLayerObject(constants.ORDER_DASHBOARD_PAGE_NAME_ANALYTICS)
    }    

    render() {
        const {dashboard} = this.props;
        return (
            <div>
                <div className="account-info"></div>
                {this.renderContent()}
                {this.accessToken && <SessionTimeout />}
            </div>
        );
    }

    renderContent() {
        const {dashboard} = this.props;
        localStorage.setItem('accessToken', this.accessToken);
        {this.addOmnitureObjectonPageLoad()}
        
        if(dashboard.isFetching === false ){            
           return (
                <div className="dashboard">
                    { this.state.showCovid ? <CovidComponent closeCovidBanner={this.closeCovidBanner.bind(this)}/> : ''}
                    <Header accessToken={this.accessToken} />
                    <Row collapse>
                        <Column>
                            <DashboardBreadcrumbsTD />
                        </Column>
                    </Row>
                    <Row collapse>
                        <Column className="">
                            <div className="inner-container responsive-width">
                                {dashboard.isError ?
                                    <ErrorComponentTD />
                                    :
                                    <div className="order-dashboard-td">
                                        <h1 className="order-dashboard-title-td">Order dashboard</h1>
                                        <div className="dashboard-tabs-td">
                                            <div className={this.getOpenOrdersClassName()} onClick={this.selectOpenOrders}>
                                                Open orders
                                            </div>
                                            <div className={this.getPastOrdersClassName()} onClick={this.selectPastOrders}>
                                                Order history
                                            </div>
                                        </div>
                                        <div className="orders-box-td">
                                            {this.renderOrders()}
                                        </div>
                                    </div>}
                            </div>
                         </Column>
                    </Row>
                    <Row collapse>
                        <Column>
                            <StripBannerTD />                            
                        </Column>
                    </Row>
                    <BackToTopTD scrollToTop={this.scrollToTop} className="docked-btn-container responsive-width"/>
                    <FooterWrapper />
                </div>
           );
        }else{
               return(<div>
                <Row collapse expanded>
                    <Column large={12}>
                        <Header accessToken={this.accessToken}/>
                    </Column>
                </Row>
                <Row collapse expanded>
                    <Column largeCentered="centered" large={8}>
                        <div className="telstra-spinner-box">
                            <div>
                                <Spinner size="large" />
                            </div>
                        </div>
                    </Column>
                </Row>
            </div>
            )
        }
    }

    addOmnitureObjectonPageLoad() {
        let pageObj = null;
        let applicationObj = null;        

        pageObj = analytics.createPageObj(constants.ORDER_DASHBOARD_PAGE_NAME_ANALYTICS);
        applicationObj = this.setApplicationObjProps();   
        this.pageObj = pageObj;
        this.applicationObj = applicationObj;

        analytics.createDateLayerObj(pageObj, applicationObj); 
    }

    setApplicationObjProps() {        
        let applicationObj = analytics.createApplicationObj(null, this.accessToken);
        return applicationObj;
    }
    
    getOpenOrdersClassName(){
        let cn = 'open-orders-tab-td';
        if (this.state.isOpenOrdersActive) {
            cn += ' dashboard-active-tab-td';
        } else {
            cn += ' dashboard-inactive-tab-td';
        }
        return cn;
    }

    getPastOrdersClassName(){
        let cn = 'past-orders-tab-td';
        if (this.state.isPastOrdersActive) {
            cn += ' dashboard-active-tab-td';
        } else {
            cn += ' dashboard-inactive-tab-td';
        }
        return cn;
    }

    selectOpenOrders(){
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DASHBOARD_PAGE_NAME_ANALYTICS, constants.OPEN_ORDERS_TAB_CLICK_ANALYTICS)
        if(this.state.isPastOrdersActive){
            this.setState({isOpenOrdersActive: true, isPastOrdersActive: false});
         }
    }

    selectPastOrders(){
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DASHBOARD_PAGE_NAME_ANALYTICS, constants.PAST_ORDERS_TAB_CLICK_ANALYTICS)
        if(this.state.isOpenOrdersActive){
            this.setState({isOpenOrdersActive: false, isPastOrdersActive: true});
         }
    }

    scrollToTop() {
        window.scrollTo(0, this.pageUp.offsetTop);
    }

    renderOrders(){
        const {dashboard} = this.props;
        let counter = 0;

        let orders;
        if(dashboard && dashboard.orders){
            if(this.state.isPastOrdersActive){
                let openOrders = dashboard.orders.filter((order) => order.orderStatus && (order.orderStatus.toLowerCase() === 'in progress' || order.orderStatus.toLowerCase() === 'on hold'));

                orders = dashboard.orders.filter((order) => {
                    const existingOpenOrder = openOrders.find((openOrder) => openOrder.orderId === order.orderId);

                    if(
                        order.orderStatus
                        && order.orderStatus.toLowerCase() !== 'in progress'
                        && order.orderStatus.toLowerCase() !== 'on hold'
                        && !existingOpenOrder
                    ) {
                        counter++;
                            return true;
                        }else{
                           return false;
                        }
                })
                .map((order,i) => {
                    if(i+1 === counter){
                        return <Order key={order.orderId} order={order}/>;
                    }else{
                        return <div key={order.orderId}>
                            <Order order={order}/>
                            <div className="order-separator-td" />
                        </div>;
                    }
                });
            }else if(this.state.isOpenOrdersActive){
                orders = dashboard.orders.filter((order) => {
                    if(order.orderStatus && (order.orderStatus.toLowerCase() === 'in progress' || order.orderStatus.toLowerCase() === 'on hold')){
                        counter++;
                        return true;
                    }else{
                        return false;
                    }
                })
                .map((order,i) => {
                    if(i+1 === counter){
                        return <Order key={order.orderId} order={order}/>;
                    }else{
                        return <div key={order.orderId}>
                            <Order order={order}/>
                            <div className="order-separator-td" />
                        </div>;
                    }
                });
            }
        }
        return orders && orders.length>0? orders : <div>{this.renderEmptyOrder()}</div>;
    }

    renderEmptyOrder(){
        return (
            this.state.isOpenOrdersActive ? 
                <div className="empty-order">
                    <img src={emptyScreenImg} alt="no open orders" />
                    You currently don't have any open orders to display
                </div>:
                <div className="empty-order">
                    <img src={emptyScreenImg} alt="no past orders" />
                    You don't have any past orders to display
                </div>
        );
    }

    closeCovidBanner() {
        this.setState({showCovid: false});
    }
}
export default Dashboard;
