import React, {Component} from "react";
import StatusIconTD from "./StatusIconsTD";
import TimelineMilestone from './TimelineMilestonesTD'


class MilestoneTimeline extends Component {

    render() {

        let {status, label, achievedDate}= this.props;
        return (
          <TimelineMilestone
            title={label}
            createdAt={achievedDate}
            icon={<StatusIconTD uniqueIdentifier="Order-Processed" status={status.toLowerCase().trim()}/>}
            lineColor={status && status.toLowerCase().trim() !== 'completed' ? '#D8D8D8' : '#0064D2'}
            lineStyle={{ width: '4px' }}
            bubbleStyle={{ border: 'transparent', background: 'unset' }}
            titleStyle={{ fontSize: '20px', color: '#282828', fontWeight: 'bold' }}
            contentStyle={{ fontSize: '20px', color: '#414141', fontWeight: 'light' }}
           >
            <p className="order-created-td-content"></p>
           </TimelineMilestone>
        );
    }

    getNodes(equalizerComponent, equalizerElement) {
        return [
          this.refs.node1,
          this.refs.node2
        ]
  }
}

export default MilestoneTimeline;
