import React, {Component} from "react";
import StatusIconTD from "./StatusIconsTD";
import TimelineMilestone from './TimelineMilestonesTD'

class OrderProcessed extends Component {

    render() {

        let {status}= this.props;
        return (
          <TimelineMilestone
            title="Order created"
            createdAt={this.props.orderProcessedDate}
            icon={<StatusIconTD uniqueIdentifier="Order-Processed" status={status ? status.toLowerCase() :"Completed"}/>}
            lineColor={status && status.toLowerCase() !== 'completed' ? '#D8D8D8' : '#0064D2'}
          >
            <p/>
          </TimelineMilestone>
        );
    }

    getNodes(equalizerComponent, equalizerElement) {
        return [
          this.refs.node1,
          this.refs.node2
        ]
  }
}

export default OrderProcessed;
