import React, {Component} from "react";
import progressInprogress from "../../images/progress-inprogress.svg";
import progressInfo from "../../images/progress-info.svg";
import progressFail from "../../images/Error@1.5x.svg";
import check from '../../images/Completed@1.5x.svg';
import inProgress from '../../images/in-Progress@1.5x.svg';
import toDo from '../../images/to-do@1.5x.svg';

class StatusIcon extends Component {

    render() {
        const {status, customAction} = this.props;

        let statusIconImg;
        switch(status && status.toLowerCase()){
            case "not started":
            case 'pending':
                statusIconImg = <img src={toDo} alt='Order is pending' className='all-timeline-icon pending-timeline-icon'/>;
                break;
            case "ok":
            case 'in progress':
                statusIconImg = <img src={inProgress} alt='Order is in progress' className='all-timeline-icon'/>;
                if(customAction && customAction.includes('2.2')) {
                    return <img  src={progressInfo} alt='Order is in progress' className='all-timeline-icon'/>;
                }
                if(customAction && (customAction.includes('2.4') || customAction.includes('2.3') ) ) {
                    return <img  src={progressInfo} alt='Order is on hold' className='all-timeline-icon'/>;
                }
                break;
            case 'completed':
            case 'complete':
                statusIconImg = <img src={check} alt='Order is completed' className='all-timeline-icon complete-timeline-icon'/>;
                break;
            case 'cancelled':
                statusIconImg = <img src={check} alt='Order is cancelled' className='all-timeline-icon complete-timeline-icon'/>;
                break;
            case 'info':
            case 'attention':
                statusIconImg = <img src={inProgress} alt='Order has info' className='all-timeline-icon'/>;
                if(customAction && (customAction.includes('1.1') ) ) { // For "Banner Only" custom actions
                    statusIconImg = <img src={progressInprogress} alt='Order is complete' className='all-timeline-icon'/>;
                }
                break;
            case 'problem':
            case 'error':
            case 'failed':
                statusIconImg = <img src={progressFail} alt='Order failed' className='all-timeline-icon'/>;
                break;
            default:
                return null;
        }
        return statusIconImg;

    }
}

export default StatusIcon;
