import React from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Lottie from 'react-lottie';

/**
 * @function - Animation container to run animations.
 * @param {ReactElement}
 * @returns - React component
 * @since 1.2.26
 */
class Animation extends React.PureComponent {
  render = () => {
    const { className, data, loop, variant, size, ...other } = this.props;
    const componentClass = 'mt-illustration';
    const classes = classnames(
      componentClass,
      className,
      variant === 'small' ? `${componentClass}--small` : '',
      variant === 'icon' ? `${componentClass}--icon` : ''
    );

    /**
     * @function styles
     * @descrtiption sets up custom height and width css styles
     * @return css style object || null
     * @since 1.4.0
     */
    const styles = () => {
      if (typeof size !== 'undefined') {
        return {
          width: size.width ? size.width : '',
          height: size.height ? size.height : '',
        };
      }
      return null;
    };

    const animationOptions = {
      animationData: data,
      loop: loop,
      autoplay: true,
    };

    return (
      <div className={classes} style={styles()} {...other}>
        <Lottie options={animationOptions} tabindex="-1" />
      </div>
    );
  };
}

Animation.propTypes = {
  /** additional css classes */
  className: PropTypes.string,
  /** the JSON data required for animation */
  data: PropTypes.object,
  /** loops the animation */
  loop: PropTypes.bool,
  /** set the different animation variant */
  variant: PropTypes.oneOf(['default', 'small', 'icon']),
  /** override the base size options with your own values */
  size: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
};

Animation.defaultProps = {
  loop: false,
};

export default Animation;
