import fetch from "isomorphic-fetch";
import * as constants from "../presenters/Constants";
import * as utils from "../utils";
import { v4 as uuidv4 } from 'uuid';

export default function apiLocation() {
    return window.env.REACT_APP_API_URL;
}

export function apiLocationAppointments() {
    return window.env.REACT_APP_API_APPT_URL;
    //return "http://localhost:8080";
}

export function fetchFromUnauthApi(dispatch, path, actionCreator, errorActionCreator) {
    let correlationId = uuidv4();
    let pathArray = path.split('/');
    const orderID = pathArray[pathArray.length - 1];    
    console.log('orderID in unauth apifetch before', orderID);
    if(!orderID) {
        orderID = localStorage.getItem('orderNumber');
    }
    console.log('orderID in unauth apifetch', orderID);
    return fetch(apiLocation() + path, 
        {
            headers: {
                'correlation-id' : correlationId
            }
        })
        .then(response => {

            if (Math.floor(response.status / 100) !== 2) {
                dispatch(errorActionCreator(response.status, orderID));
                return false;
            }
            return response.json()
        })
        .then(json => {
            if (json) {
                if (json.data.orderId === null) {
                    dispatch(errorActionCreator(404, orderID));
                } else {
                    dispatch(actionCreator(json));
                }
            }
        })
        .catch((ex) => {
            dispatch(errorActionCreator(undefined, orderID));
        })
}

export function fetchFromAuthApi(dispatch, path, actionCreator, errorActionCreator, accessToken, bypassCache, overrideAppointment) {
    let correlationId = uuidv4();
    let pathArray = path.split('/');
    const orderID = pathArray[pathArray.length - 1];
    if(bypassCache){
        return fetch(apiLocation() + path + '?overrideAppointment=' + overrideAppointment, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'bypass-cache' : bypassCache,
                'correlation-id' : correlationId
            },
        }).then(response => {
            if (Math.floor(response.status / 100) !== 2) {
                dispatch(errorActionCreator(response.status, orderID));
                return false;
            }
            return response.json()
        }).then(json => {
            if (json) {
                if (json.data.orderId === null) {
                    dispatch(errorActionCreator(404, orderID));
                } else {
                    dispatch(actionCreator(json));
                }
            }
        })
            .catch((ex) => {
                dispatch(errorActionCreator(undefined, orderID));
            })
    }
    else{
        let finalPath = apiLocation() + path;
        if(overrideAppointment && overrideAppointment !== undefined) {
            finalPath = apiLocation() + path + '?overrideAppointment=' + overrideAppointment;
        }
        return fetch(finalPath, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'correlation-id': correlationId
            },
        }).then(response => {
            console.log('response', response);
            if (Math.floor(response.status / 100) !== 2) {
                dispatch(errorActionCreator(response.status, orderID));
                return false;
            }
            return response.json()
        }).then(json => {
            if (json) {
                console.log('json', json);
                if (json.data.orderId === null) {
                    dispatch(errorActionCreator(404, orderID));
                } else {
                    dispatch(actionCreator(json));
                }
            }
        })
            .catch((ex) => {
                dispatch(errorActionCreator(undefined, orderID));
            })
    }

}

export function fetchFromAppointmentSlotsApi(dispatch, path, actionCreator, errorActionCreator, appointmentType, appointmentId, fromDateTime, appointmentAddressState, hardwareDeliveryDate, nbnAppointmentDate) {

    if(appointmentType && appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA) {
        this.state = { "data": { "appointmentType": appointmentType, "appointmentId": appointmentId, "fromDateTime": fromDateTime, "appointmentAddressState":appointmentAddressState, "nbnAppointmentDate": nbnAppointmentDate, "hardwareDeliveryDate": hardwareDeliveryDate } };
    } else {
        this.state = { "data": { "appointmentType": appointmentType, "appointmentId": appointmentId, "fromDateTime": fromDateTime, "appointmentAddressState":appointmentAddressState } };
    }

    return fetch(apiLocationAppointments() + path, {
        headers: {
            'Content-Type': "application/json"
        },
        method: 'POST',
        body: JSON.stringify(this.state)
    })
        .then(response => {
            return response.json()
        })
        .then(json => {
            if (json) {
                if (Math.floor(json.status / 100) !== 2) {
                    dispatch(errorActionCreator(json));
                }
                else if (json.data.slots === undefined || json.data.slots === null) {
                    dispatch(errorActionCreator(json));
                } else {
                    dispatch(actionCreator(json));
                }
            }
        })
        .catch((ex) => {
            dispatch(errorActionCreator());
        })
}

export function fetchFromAppointmentSlotsApiAuth(dispatch, path, actionCreator, errorActionCreator, accessToken, appointmentType, appointmentId, fromDateTime, appointmentAddressState) {

        this.state = { "data": { "appointmentType": appointmentType, "appointmentId": appointmentId, "fromDateTime": fromDateTime, "appointmentAddressState":appointmentAddressState } };

        return fetch(apiLocationAppointments() + path, {
            headers: {
                'Content-Type': "application/json",
                'Authorization': "Bearer "+accessToken
            },
            method: 'POST',
            body: JSON.stringify(this.state)
        })
            .then(response => {
                return response.json()
            })
            .then(json => {
                if (json) {
                    if (Math.floor(json.status / 100) !== 2) {
                        dispatch(errorActionCreator(json));
                    }
                    else if (json.data.slots === undefined || json.data.slots === null) {
                        dispatch(errorActionCreator(json));
                    } else {
                        dispatch(actionCreator(json));
                    }
                }
            })
            .catch((ex) => {
                dispatch(errorActionCreator());
            })
    }

export function fetchFromAppointmentDetailsApi(dispatch, path, actionCreator, errorActionCreator, orderId, appointmentType, nbnAppointmentId, telstraAppointmentId) {
    let appointments = [];
    if(nbnAppointmentId && nbnAppointmentId !== undefined && nbnAppointmentId !== "null"){
        appointmentType = constants.APPT_CATEGORY_NBN;
        appointments.push({ "appointmentId": nbnAppointmentId, "appointmentType": appointmentType });
    }
    if(telstraAppointmentId && telstraAppointmentId !== undefined && telstraAppointmentId !== "null"){
        appointmentType = constants.APPT_CATEGORY_TELSTRA;
        appointments.push({ "appointmentId": telstraAppointmentId, "appointmentType": appointmentType });
    }
    //appointments = [];
    this.state = { "orderId":orderId, "appointments": appointments };
    return fetch(apiLocationAppointments() + path, {
        headers: {
            'Content-Type': "application/json"
        },
        method: "POST",
        body: JSON.stringify(this.state)
    }).then(response => {
        return response.json()
    })
        .then(json => {
            if (json) {
                if (Math.floor(json.status / 100) !== 2) {
                    dispatch(errorActionCreator(json));
                }
                else if (json.data.appointments === undefined || json.data.appointments === null) {
                    dispatch(errorActionCreator(json));
                } else {
                    dispatch(actionCreator(json));
                }
            }
        })
        .catch((ex) => {
            dispatch(errorActionCreator());
        })
}
export function updateAppointmentConfirmApi(dispatch, path, actionCreator, errorActionCreator, orderId, appointmentType, appointmentId, deliveryType
    , date, slotStartTime, slotEndTime, slotType, slotId, timezone, dualAppointments) {
    let appointmentSlot = {};
    deliveryType = "Standard Install";
    if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_NBN) {
        appointmentSlot = { "date": date, "slotStartTime": slotStartTime, "slotEndTime": slotEndTime, "slotType": slotType, "timeZone": timezone};
    }

    if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA) {
        appointmentSlot = { "id": slotId};
    }
    if(dualAppointments && dualAppointments !== undefined && dualAppointments.length === 0) {
        this.state = { "data": [{ "orderId":orderId, "appointmentId":appointmentId, "appointmentType": appointmentType, "deliveryType": deliveryType,  "appointmentSlot": appointmentSlot }] };
    } else {
        this.state = { "data": dualAppointments };
    }

    return fetch(apiLocationAppointments() + path + "/" + appointmentId, {
        headers: {
            'Content-Type': "application/json"
        },
        method: "PATCH",
        body: JSON.stringify(this.state)
    }).then(response => {

        return response.json()
    })
        .then(json => {
            if (json) {
                if (Math.floor(json.status / 100) !== 2) {
                    dispatch(errorActionCreator(json));
                }
                else {
                    dispatch(actionCreator(json));
                }
            }
        })
        .catch((ex) => {
            dispatch(errorActionCreator());
        })

}

export function updateAppointmentConfirmApiAuth(dispatch, path, actionCreator, errorActionCreator, accessToken, orderId, appointmentType, appointmentId, deliveryType
    , date, slotStartTime, slotEndTime, slotType, slotId, timezone, dualAppointments) {
    let appointmentSlot = {};
    deliveryType = "Standard Install";
    if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_NBN) {
        appointmentSlot = { "date": date, "slotStartTime": slotStartTime, "slotEndTime": slotEndTime, "slotType": slotType,"timeZone": timezone };
    }

    if(appointmentType.toLowerCase() === constants.APPT_CATEGORY_TELSTRA) {
        appointmentSlot = { "id": slotId};
    }

    if(dualAppointments && dualAppointments !== undefined && dualAppointments.length === 0) {
        this.state = { "data": [{ "orderId":orderId, "appointmentId":appointmentId, "appointmentType": appointmentType, "deliveryType": deliveryType,  "appointmentSlot": appointmentSlot }] };
    } else {
        this.state = { "data": dualAppointments };
    }

    return fetch(apiLocationAppointments() + path + "/" + appointmentId, {
        headers: {
            'Content-Type': "application/json",
            'Authorization': "Bearer "+accessToken
        },
        method: "PATCH",
        body: JSON.stringify(this.state)
    }).then(response => {
        return response.json()
    })
        .then(json => {
            if (json) {
                if (Math.floor(json.status / 100) !== 2) {
                    dispatch(errorActionCreator(json));
                }
                else {
                    dispatch(actionCreator(json));
                }
            }
        })
        .catch((ex) => {
            dispatch(errorActionCreator());
        })

}

export function fetchFromAppointmentDetailsApiAuth(dispatch, path, actionCreator, errorActionCreator, accessToken, orderId, appointmentType, nbnAppointmentId, telstraAppointmentId) {
    let appointments = [];
    if(nbnAppointmentId && nbnAppointmentId !== undefined){
        appointmentType = constants.APPT_CATEGORY_NBN;
        appointments.push({ "appointmentId": nbnAppointmentId, "appointmentType": appointmentType });
    }
    if(telstraAppointmentId && telstraAppointmentId !== undefined){
        appointmentType = constants.APPT_CATEGORY_TELSTRA;
        appointments.push({ "appointmentId": telstraAppointmentId, "appointmentType": appointmentType });
    }
    //appointments.push({"appointmentId": "APT111930793061", "appointmentType":"NBN"});
    this.state = { "orderId": orderId, "appointments": appointments };
    return fetch(apiLocationAppointments() + path, {
        headers: {
            'Content-Type': "application/json",
            'Authorization': "Bearer "+accessToken
        },
        method: "POST",
        body: JSON.stringify(this.state)
    }).then(response => {
        if (Math.floor(response.status / 100) !== 2) {
            dispatch(errorActionCreator(response.status));
            return false;
        }
        return response.json()
    }).then(json => {
        if (json) {
            if (json.data.appointments === undefined || json.data.appointments === null) {
                dispatch(errorActionCreator(404));
            } else {
                dispatch(actionCreator(json));
            }
        } else {
            dispatch(errorActionCreator(401));
        }
    })
    .catch((ex) => {
            dispatch(errorActionCreator());
        })
}

export function fetchDashboardFromAuthApi(dispatch, path, actionCreator, errorActionCreator, accessToken) {
    console.log('api path', apiLocation() + path);
    console.log('access token', accessToken);
    return fetch(apiLocation() + path, {
        headers: {
            'Authorization': 'Bearer ' + accessToken
        },
    }).then(response => {
        console.log('response', response);
        if (Math.floor(response.status / 100) !== 2) {
            dispatch(errorActionCreator(response.status));
            return false;
        }
        return response.json()
    }).then(json => {
        console.log('json', json);
        if (json) {
            dispatch(actionCreator(json));
        }
    })
    .catch((ex) => {
        console.log('catch');
        dispatch(errorActionCreator());
    })
}
