import React from 'react';

class GlobalFooter extends React.Component {
  constructor(props) {
    super(props);
    this.mq = window.matchMedia('(min-width: 1024px)');
    this.state = { isDesktop: window.matchMedia('(min-width: 1024px)').matches };
    this.mobileFooter = this.mobileFooter.bind(this);
  }

  updateDimensions = () => {
    this.setState({ isDesktop: window.matchMedia('(min-width: 1024px)').matches });
    this.mobileFooter();
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.mobileFooter();
  }

  mobileFooter() {
    let coll = document.getElementsByClassName("collapsible-footer");
    let i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        let content = this.nextElementSibling;
        if (content.style.maxHeight){
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    return (
        this.state.isDesktop ?
        <footer className="globalFooter" role="contentinfo">
          <div className="tcom-global-footer">
            <div className="footer-container">
              <div className="footer-links">
                <section className="footerList" aria-labelledby="heading-help">
                  <div className="footer-list"><h2 className="heading-text" id="heading-help"><span
                      itemProp="name">Help</span></h2>
                    <ul role="menu" itemScope="" itemType="http://schema.org/SiteNavigationElement">
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/contact-us"
                                                            itemProp="name">Contact us</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/support"
                                                            itemProp="name">Support</a></li>
                      <li role="menuitem" itemProp="url"><a
                          href="https://crowdsupport.telstra.com.au/t5/Find-your-nearest-Telstra-Retail/ct-p/tstore"
                          itemProp="name">Find a store</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://outages.telstra.com/" itemProp="name">Check
                        for outages</a></li>
                      <li role="menuitem" itemProp="url"><a
                          href="https://www.telstra.com.au/my-account/troubleshooting-tool" itemProp="name">Troubleshoot
                        an issue</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/consumer-advice"
                                                            itemProp="name">Consumer advice</a></li>
                    </ul>
                  </div>
                </section>
                <section className="footerList" aria-labelledby="heading-about">
                  <div className="footer-list"><h2 className="heading-text" id="heading-about"><span
                      itemProp="name">About</span></h2>
                    <ul role="menu" itemScope="" itemType="http://schema.org/SiteNavigationElement">
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/aboutus" itemProp="name">About
                        us</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://careers.telstra.com/home"
                                                            itemProp="name">Careers</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/partners-and-causes"
                                                            itemProp="name">Partners and causes</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/aboutus/investors"
                                                            itemProp="name">Investors</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/sitemap"
                                                            itemProp="name">Sitemap</a></li>
                    </ul>
                  </div>
                </section>
                <section className="footerList" aria-labelledby="heading-privacy">
                  <div className="footer-list"><h2 className="heading-text" id="heading-privacy"><span itemProp="name">Privacy and terms</span>
                  </h2>
                    <ul role="menu" itemScope="" itemType="http://schema.org/SiteNavigationElement">
                      <li role="menuitem" itemProp="url"><a
                          href="https://www.telstra.com.au/help/critical-information-summaries" itemProp="name">Critical
                        Information Summaries</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/customer-terms"
                                                            itemProp="name">Our customer terms</a></li>
                      <li role="menuitem" itemProp="url"><a
                          href="https://www.telstra.com.au/consumer-advice/your-information" itemProp="name">Your
                        information</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/privacy"
                                                            itemProp="name">Privacy</a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/terms-of-use"
                                                            itemProp="name">Our website terms</a></li>
                    </ul>
                  </div>
                </section>
                <section className="footerList" aria-labelledby="heading-other">
                  <div className="footer-list"><h2 className="heading-text" id="heading-other"><span itemProp="name">Other Telstra sites</span>
                  </h2>
                    <ul role="menu" itemScope="" itemType="http://schema.org/SiteNavigationElement">
                      <li role="menuitem" itemProp="url"><a href="https://www.telstrawholesale.com.au/" itemProp="name">Telstra
                        Wholesale <svg role="img" aria-labelledby="icon-title-61980" className="nav-icon" height="15"
                                       width="15" viewBox="0 0 100 100"><title id="icon-title-61980">opens external
                          website</title>
                          <path
                              d="M18.8 85.1h56c2.2 0 4-1.8 4-4v-32h-8v28h-48v-48h28v-8h-32c-2.2 0-4 1.8-4 4v56c0 2.2 1.8 4 4 4z"
                              fill="currentColor"></path>
                          <path d="M45.7 48.7l5.6 5.6 25.9-25.8v8.7h8V14.9H62.8v8h8.7z" fill="currentColor"></path>
                        </svg></a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstraglobal.com/" itemProp="name">Global
                        Enterprises &amp; Services
                        <svg role="img" aria-labelledby="icon-title-61369" className="nav-icon" height="15" width="15"
                             viewBox="0 0 100 100"><title id="icon-title-61369">opens external website</title>
                          <path
                              d="M18.8 85.1h56c2.2 0 4-1.8 4-4v-32h-8v28h-48v-48h28v-8h-32c-2.2 0-4 1.8-4 4v56c0 2.2 1.8 4 4 4z"
                              fill="currentColor"></path>
                          <path d="M45.7 48.7l5.6 5.6 25.9-25.8v8.7h8V14.9H62.8v8h8.7z" fill="currentColor"></path>
                        </svg>
                      </a></li>
                      <li role="menuitem" itemProp="url"><a href="http://media.telstra.com.au/home" itemProp="name">Telstra
                        Media <svg role="img" aria-labelledby="icon-title-27560" className="nav-icon" height="15"
                                   width="15" viewBox="0 0 100 100"><title id="icon-title-27560">opens external
                          website</title>
                          <path
                              d="M18.8 85.1h56c2.2 0 4-1.8 4-4v-32h-8v28h-48v-48h28v-8h-32c-2.2 0-4 1.8-4 4v56c0 2.2 1.8 4 4 4z"
                              fill="currentColor"></path>
                          <path d="M45.7 48.7l5.6 5.6 25.9-25.8v8.7h8V14.9H62.8v8h8.7z" fill="currentColor"></path>
                        </svg></a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstrahealth.com/" itemProp="name">Telstra
                        Health <svg role="img" aria-labelledby="icon-title-57175" className="nav-icon" height="15"
                                    width="15" viewBox="0 0 100 100"><title id="icon-title-57175">opens external
                          website</title>
                          <path
                              d="M18.8 85.1h56c2.2 0 4-1.8 4-4v-32h-8v28h-48v-48h28v-8h-32c-2.2 0-4 1.8-4 4v56c0 2.2 1.8 4 4 4z"
                              fill="currentColor"></path>
                          <path d="M45.7 48.7l5.6 5.6 25.9-25.8v8.7h8V14.9H62.8v8h8.7z" fill="currentColor"></path>
                        </svg></a></li>
                      <li role="menuitem" itemProp="url"><a href="https://www.telstra.com.au/thanks" itemProp="name">Telstra
                        Thanks Rewards</a></li>
                    </ul>
                  </div>
                </section>
              </div>
              <div className="socialIcons parbase">
                <section className="telstra-social" aria-labelledby="heading-find"><h2 className="heading-text"
                                                                                       id="heading-find">Find us</h2>
                  <ul>
                    <li id="id-938985014-015460f1-d946-443c-b2e3-7f47e276e03b"
                        data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-015460f1-d946-443c-b2e3-7f47e276e03b&quot;,&quot;name&quot;:&quot;Facebook&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                      <a href="https://www.facebook.com/Telstra24x7" aria-label="Facebook"><span
                          className="footer-icon"><svg role="img" height="32" width="32" viewBox="0 0 32 32"
                                                       xmlns="http://www.w3.org/2000/svg"><path
                          d="M16 0C7.161 0 0 7.161 0 16s7.161 16 16 16 16-7.161 16-16S24.806 0 16 0zm4.677 9.935h-1.71c-1.322 0-1.58.646-1.58 1.581v2.065h3.194l-.42 3.225h-2.774v8.259h-3.322v-8.259H11.29v-3.225h2.775v-2.387c0-2.742 1.677-4.259 4.129-4.259.838 0 1.645.033 2.483.13v2.87z"
                          fill="#5B5B5B" fill-rule="nonzero"></path></svg></span> <span>Facebook</span></a></li>
                    <li id="id-938985014-acbd8530-7ccd-4b67-9d57-60854b144ab6"
                        data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-acbd8530-7ccd-4b67-9d57-60854b144ab6&quot;,&quot;name&quot;:&quot;Twitter&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                      <a href="https://twitter.com/telstra" aria-label="Twitter"><span className="footer-icon"><svg
                          role="img" height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path
                          d="M16 0C7.161 0 0 7.161 0 16s7.161 16 16 16 16-7.161 16-16S24.806 0 16 0zm6.645 12.613v.452c0 4.483-3.42 9.71-9.71 9.71a9.678 9.678 0 0 1-5.258-1.55c.29.033.549.033.84.033a6.926 6.926 0 0 0 4.257-1.452c-1.484-.032-2.774-1-3.193-2.387.193 0 .419.033.613.033.322 0 .612-.033.903-.13A3.362 3.362 0 0 1 8.355 14v-.032c.451.258 1 .42 1.548.42-.968-.582-1.548-1.678-1.548-2.84 0-.613.161-1.193.451-1.71a9.529 9.529 0 0 0 7.033 3.581c-.033-.258-.097-.548-.097-.806 0-1.871 1.548-3.42 3.42-3.42 1 0 1.87.42 2.483 1.097.807-.161 1.484-.451 2.161-.838a3.446 3.446 0 0 1-1.483 1.87 7.165 7.165 0 0 0 1.967-.548c-.451.71-1.032 1.323-1.645 1.839z"
                          fill="#5B5B5B" fill-rule="nonzero"></path></svg></span> <span>Twitter</span></a></li>
                    <li id="id-938985014-348eb0a4-f762-4ee5-bcc1-a6d42acfc912"
                        data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-348eb0a4-f762-4ee5-bcc1-a6d42acfc912&quot;,&quot;name&quot;:&quot;Instagram&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                      <a href="https://www.instagram.com/telstra/" aria-label="Instagram"><span className="footer-icon"><svg
                          role="img" height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g
                          fill="#5B5B5B" fill-rule="evenodd"><path
                          d="M22.603 10.907a3.292 3.292 0 0 0-.64-.992c-.288-.32-.576-.48-.992-.64-.288-.128-.768-.256-1.6-.288-.896-.032-1.184-.064-3.456-.064-2.272 0-2.56 0-3.456.064-.832.032-1.28.192-1.6.288-.384.16-.672.352-.992.64-.32.288-.48.576-.64.992-.128.288-.256.768-.288 1.6-.032.896-.064 1.184-.064 3.456 0 2.272 0 2.56.064 3.456.032.832.192 1.28.288 1.6.16.384.352.672.64.992.288.288.576.48.992.64.288.128.768.256 1.6.288.896.032 1.184.064 3.456.064 2.272 0 2.56 0 3.456-.064.832-.032 1.28-.192 1.6-.288.384-.16.672-.352.992-.64.32-.288.48-.576.64-.992.128-.288.256-.768.288-1.6.032-.896.064-1.184.064-3.456 0-2.272 0-2.56-.064-3.456-.032-.832-.16-1.312-.288-1.6zm-6.656 9.44a4.37 4.37 0 0 1-4.384-4.384 4.37 4.37 0 0 1 4.384-4.384 4.37 4.37 0 0 1 4.384 4.384 4.37 4.37 0 0 1-4.384 4.384zm4.544-7.936a1.01 1.01 0 0 1-1.024-1.024 1.01 1.01 0 0 1 1.024-1.024 1.01 1.01 0 0 1 1.024 1.024c.032.576-.448 1.024-1.024 1.024z"
                          fill-rule="nonzero"></path><circle cx="15.949" cy="15.961" r="2.848"
                                                             transform="rotate(-9.218 15.95 15.961)"></circle><path
                          d="M15.947.091C7.18.091.075 7.195.075 15.963S7.18 31.835 15.947 31.835 31.82 24.731 31.82 15.963 24.683.091 15.947.091zm8.48 19.392a6.698 6.698 0 0 1-.384 2.08 4.107 4.107 0 0 1-.992 1.504c-.48.48-.96.768-1.504.992-.544.224-1.152.352-2.08.384-.928.032-1.216.064-3.52.064-2.304 0-2.624 0-3.52-.064a6.698 6.698 0 0 1-2.08-.384 4.107 4.107 0 0 1-1.504-.992c-.48-.48-.768-.96-.992-1.504-.224-.544-.352-1.152-.384-2.08-.032-.928-.064-1.216-.064-3.52 0-2.304 0-2.624.064-3.52a6.698 6.698 0 0 1 .384-2.08 4.107 4.107 0 0 1 .992-1.504c.48-.48.96-.768 1.504-.992.544-.224 1.152-.352 2.08-.384.928-.032 1.216-.064 3.52-.064 2.304 0 2.624 0 3.52.064a6.698 6.698 0 0 1 2.08.384 4.107 4.107 0 0 1 1.504.992c.48.48.768.96.992 1.504.224.544.352 1.152.384 2.08.032.928.064 1.216.064 3.52 0 2.304 0 2.592-.064 3.52z"
                          fill-rule="nonzero"></path></g></svg></span> <span>Instagram</span></a></li>
                    <li id="id-938985014-fdefef33-9c1c-4a4b-abc6-44df8b512173"
                        data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-fdefef33-9c1c-4a4b-abc6-44df8b512173&quot;,&quot;name&quot;:&quot;YouTube&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                      <a href="https://www.youtube.com/user/TelstraCorp" aria-label="YouTube"><span
                          className="footer-icon"><svg role="img" height="32" width="32" viewBox="0 0 32 32"
                                                       xmlns="http://www.w3.org/2000/svg"><g fill="#5B5B5B"
                                                                                             fill-rule="evenodd"><path
                          d="M15.872 0C7.104 0 0 7.104 0 15.872s7.104 15.872 15.872 15.872 15.872-7.104 15.872-15.872S24.608 0 15.872 0zm9.664 20.704c-.224.864-.928 1.536-1.792 1.792-1.568.416-7.872.416-7.872.416s-6.304 0-7.872-.416c-.864-.224-1.536-.928-1.792-1.792-.448-1.568-.448-4.832-.448-4.832s0-3.296.416-4.864c.224-.864.928-1.536 1.792-1.792C9.536 8.8 15.84 8.8 15.84 8.8s6.304 0 7.872.416c.864.224 1.536.928 1.792 1.792.416 1.568.416 4.864.416 4.864s.032 3.264-.384 4.832z"
                          fill-rule="nonzero"></path><path
                          d="M13.856 18.88l5.216-3.008-5.216-3.04z"></path></g></svg></span> <span>YouTube</span></a>
                    </li>
                    <li id="id-938985014-b0075f38-e69a-48a9-8f5d-e39646d43794"
                        data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-b0075f38-e69a-48a9-8f5d-e39646d43794&quot;,&quot;name&quot;:&quot;CrowdSupport&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                      <a href="https://crowdsupport.telstra.com.au/" aria-label="CrowdSupport"><span
                          className="footer-icon"><svg role="img" height="32" width="32" viewBox="0 0 32 32"
                                                       xmlns="http://www.w3.org/2000/svg"><g fill="#5B5B5B"
                                                                                             fill-rule="evenodd"><path
                          d="M17.603 15.932c-.29-.096-.418-.322-.482-.547 0-.29.096-.516.32-.645a2.674 2.674 0 0 0 1.254-2.288 2.699 2.699 0 0 0-2.699-2.707c-1.478 0-2.7 1.225-2.7 2.707 0 .935.483 1.773 1.254 2.288.225.129.386.419.321.645a.618.618 0 0 1-.482.547c-.128.033-3.502 1.257-3.502 5.865 0 .194.129.323.321.323h9.447c.193 0 .386-.13.418-.323.032-4.608-3.31-5.832-3.47-5.865z"></path><path
                          d="M21.17 15.836c-.29-.097-.419-.322-.483-.548 0-.29.097-.516.322-.645.61-.386.996-1.063.996-1.772 0-1.16-.932-2.095-2.089-2.095-.096 0-.193.033-.29.033.226.483.386 1.03.386 1.643a4.103 4.103 0 0 1-1.028 2.707c1.157.741 2.988 2.352 3.374 5.51h1.51c.097 0 .129-.032.129-.128-.032-3.674-2.731-4.64-2.828-4.705zM12.943 15.127a4.103 4.103 0 0 1-1.028-2.707c0-.548.129-1.128.386-1.644-.097-.032-.193-.032-.29-.032a2.087 2.087 0 0 0-2.088 2.095c0 .741.386 1.418.996 1.772.225.13.386.42.321.645a.618.618 0 0 1-.482.548c-.096 0-2.763.999-2.763 4.672 0 .097.032.13.129.13H9.57c.385-3.127 2.217-4.706 3.373-5.48z"></path><path
                          d="M15.964.034C7.16.034.026 7.188.026 16.018S7.16 32.002 15.964 32.002 31.9 24.848 31.9 16.018 24.736.034 15.964.034zm7.808 22.042h-1.446a1.665 1.665 0 0 1-1.639 1.45h-9.479a1.665 1.665 0 0 1-1.638-1.45H8.124c-.836 0-1.51-.709-1.51-1.514 0-3.19 1.638-4.77 2.763-5.479a3.475 3.475 0 0 1-.803-2.19c0-1.87 1.542-3.417 3.405-3.417.386 0 .74.033 1.125.194a4.107 4.107 0 0 1 2.86-1.16c1.124 0 2.088.418 2.86 1.16a3.44 3.44 0 0 1 1.124-.194c1.928 0 3.47 1.547 3.47 3.416 0 .838-.289 1.58-.803 2.191 1.06.71 2.7 2.288 2.667 5.479a1.49 1.49 0 0 1-1.51 1.514z"
                          fill-rule="nonzero"></path></g></svg></span> <span>CrowdSupport®</span></a></li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </footer>
            :
            <footer className="globalFooter" role="contentinfo">
              <div className="tcom-global-footer">
                <div className="footer-container">
                  <div className="footer-links">
                    <section className="footerList" aria-label="Help">
                      <div className="accordion-tab">
                        <div className="mt-showhide showhide-footer">
                          <h2 className="mt-showhide__heading collapsible-footer">
                            <button type="button" className="mt-showhide__button" aria-expanded="false"
                                    aria-controls="showHide69033" id="showHide69033Label">Help<span
                                className="mt-showhide__button__icon"></span></button>
                          </h2>
                          <div id="showHide69033" role="region" tabIndex="-1" className="mt-showhide__content collapsible-footer-content"
                               aria-labelledby="showHide69033Label">
                            <div className="mt-showhide__content__inner">
                              <ul role="menu" itemScope="" itemType="http://schema.org/SiteNavigationElement">
                                <li role="menuitem" itemProp="url"
                                    id="id-347708954-56099d96-0a60-4f0b-96dd-8bb13135af8f"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-347708954-56099d96-0a60-4f0b-96dd-8bb13135af8f&quot;,&quot;title&quot;:&quot;Help&quot;,&quot;linkText&quot;:&quot;Contact us&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/contact-us&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/contact-us" itemProp="name">Contact us </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id-347708954-8fd0afbd-77f8-4e90-8147-56a81f7fc549"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-347708954-8fd0afbd-77f8-4e90-8147-56a81f7fc549&quot;,&quot;title&quot;:&quot;Help&quot;,&quot;linkText&quot;:&quot;Support&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/support&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/support" itemProp="name">Support </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id-347708954-bb0b5562-e6eb-4382-b5dd-73b1e898bc4f"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-347708954-bb0b5562-e6eb-4382-b5dd-73b1e898bc4f&quot;,&quot;title&quot;:&quot;Help&quot;,&quot;linkText&quot;:&quot;Find a store&quot;,&quot;destination&quot;:&quot;https://crowdsupport.telstra.com.au/t5/Find-your-nearest-Telstra-Retail/ct-p/tstore&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://crowdsupport.telstra.com.au/t5/Find-your-nearest-Telstra-Retail/ct-p/tstore"
                                     itemProp="name">Find a store </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id-347708954-a2c61782-8ed8-47ba-ba2f-25b0e2bc9342"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-347708954-a2c61782-8ed8-47ba-ba2f-25b0e2bc9342&quot;,&quot;title&quot;:&quot;Help&quot;,&quot;linkText&quot;:&quot;Check for outages&quot;,&quot;destination&quot;:&quot;https://outages.telstra.com/&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://outages.telstra.com/" itemProp="name">Check for outages </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id-347708954-a1d66c1e-e36a-4ff9-8ca4-02c1e31cfc34"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-347708954-a1d66c1e-e36a-4ff9-8ca4-02c1e31cfc34&quot;,&quot;title&quot;:&quot;Help&quot;,&quot;linkText&quot;:&quot;Troubleshoot an issue&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/my-account/troubleshooting-tool&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/my-account/troubleshooting-tool" itemProp="name">Troubleshoot
                                    an issue </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id-347708954-8df68278-69f2-40d5-9e7a-325adc1904ab"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-347708954-8df68278-69f2-40d5-9e7a-325adc1904ab&quot;,&quot;title&quot;:&quot;Help&quot;,&quot;linkText&quot;:&quot;Consumer advice&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/consumer-advice&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/consumer-advice" itemProp="name">Consumer
                                    advice </a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="footerList" aria-label="About">
                      <div className="accordion-tab">
                        <div className="mt-showhide showhide-footer">
                          <h2 className="mt-showhide__heading collapsible-footer">
                            <button type="button" className="mt-showhide__button" aria-expanded="false"
                                    aria-controls="showHide62484" id="showHide62484Label">About<span
                                className="mt-showhide__button__icon"></span></button>
                          </h2>
                          <div id="showHide62484" role="region" tabIndex="-1" className="mt-showhide__content collapsible-footer-content"
                               aria-labelledby="showHide62484Label">
                            <div className="mt-showhide__content__inner">
                              <ul role="menu" itemScope="" itemType="http://schema.org/SiteNavigationElement">
                                <li role="menuitem" itemProp="url"
                                    id="id--966751622-9e82740e-6bbf-4fa9-abdc-0de43ed7ff69"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--966751622-9e82740e-6bbf-4fa9-abdc-0de43ed7ff69&quot;,&quot;title&quot;:&quot;About&quot;,&quot;linkText&quot;:&quot;About us&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/aboutus&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/aboutus" itemProp="name">About us </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id--966751622-bd1c92bc-7214-428b-bfaf-a87952605348"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--966751622-bd1c92bc-7214-428b-bfaf-a87952605348&quot;,&quot;title&quot;:&quot;About&quot;,&quot;linkText&quot;:&quot;Careers&quot;,&quot;destination&quot;:&quot;https://careers.telstra.com/home&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://careers.telstra.com/home" itemProp="name">Careers</a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id--966751622-e6b93164-b252-47ba-8303-a9da8876877a"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--966751622-e6b93164-b252-47ba-8303-a9da8876877a&quot;,&quot;title&quot;:&quot;About&quot;,&quot;linkText&quot;:&quot;Partners and causes&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/partners-and-causes&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/partners-and-causes" itemProp="name">Partners and
                                    causes </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id--966751622-337aff84-c164-4ead-94a6-85164f90575c"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--966751622-337aff84-c164-4ead-94a6-85164f90575c&quot;,&quot;title&quot;:&quot;About&quot;,&quot;linkText&quot;:&quot;Investors&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/aboutus/investors&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/aboutus/investors" itemProp="name">Investors </a>
                                </li>
                                <li role="menuitem" itemProp="url"
                                    id="id--966751622-426d077f-06e5-4fc9-857e-bd44adacde94"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--966751622-426d077f-06e5-4fc9-857e-bd44adacde94&quot;,&quot;title&quot;:&quot;About&quot;,&quot;linkText&quot;:&quot;Sitemap&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/sitemap&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/sitemap" itemProp="name">Sitemap </a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="footerList" aria-label="Privacy and terms">
                      <div className="accordion-tab">
                        <div className="mt-showhide showhide-footer">
                          <h2 className="mt-showhide__heading collapsible-footer">
                            <button type="button" className="mt-showhide__button" aria-expanded="false"
                                    aria-controls="showHide17524" id="showHide17524Label">Privacy and terms<span
                                className="mt-showhide__button__icon"></span></button>
                          </h2>
                          <div id="showHide17524" role="region" tabIndex="-1" className="mt-showhide__content collapsible-footer-content"
                               aria-labelledby="showHide17524Label">
                            <div className="mt-showhide__content__inner">
                              <ul role="menu" itemScope="" itemType="http://schema.org/SiteNavigationElement">
                                <li role="menuitem" itemProp="url"
                                    id="id--2013373926-b152674a-84b2-46f2-826a-07d221a257c3"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--2013373926-b152674a-84b2-46f2-826a-07d221a257c3&quot;,&quot;title&quot;:&quot;Privacy and terms&quot;,&quot;linkText&quot;:&quot;Critical Information Summaries&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/help/critical-information-summaries&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/help/critical-information-summaries"
                                     itemProp="name">Critical Information Summaries </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id--2013373926-9bf0000f-a0ae-41d4-ab9f-27818a91a5d7"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--2013373926-9bf0000f-a0ae-41d4-ab9f-27818a91a5d7&quot;,&quot;title&quot;:&quot;Privacy and terms&quot;,&quot;linkText&quot;:&quot;Our customer terms&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/customer-terms&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/customer-terms" itemProp="name">Our customer
                                    terms </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id--2013373926-1dba035d-dee2-4057-b36e-0697cfdcb539"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--2013373926-1dba035d-dee2-4057-b36e-0697cfdcb539&quot;,&quot;title&quot;:&quot;Privacy and terms&quot;,&quot;linkText&quot;:&quot;Your information&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/consumer-advice/your-information&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/consumer-advice/your-information" itemProp="name">Your
                                    information </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id--2013373926-3840e77e-228d-4305-8252-03cabd6d005e"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--2013373926-3840e77e-228d-4305-8252-03cabd6d005e&quot;,&quot;title&quot;:&quot;Privacy and terms&quot;,&quot;linkText&quot;:&quot;Privacy&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/privacy&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/privacy" itemProp="name">Privacy </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id--2013373926-343ff525-9bc3-4206-b95d-7a9bb7a00db6"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id--2013373926-343ff525-9bc3-4206-b95d-7a9bb7a00db6&quot;,&quot;title&quot;:&quot;Privacy and terms&quot;,&quot;linkText&quot;:&quot;Our website terms&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/terms-of-use&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/terms-of-use" itemProp="name">Our website
                                    terms </a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="footerList" aria-label="Other Telstra sites">
                      <div className="accordion-tab">
                        <div className="mt-showhide showhide-footer">
                          <h2 className="mt-showhide__heading collapsible-footer">
                            <button type="button" className="mt-showhide__button" aria-expanded="false"
                                    aria-controls="showHide81580" id="showHide81580Label">Other Telstra sites<span
                                className="mt-showhide__button__icon"></span></button>
                          </h2>
                          <div id="showHide81580" role="region" tabIndex="-1" className="mt-showhide__content collapsible-footer-content"
                               aria-labelledby="showHide81580Label">
                            <div className="mt-showhide__content__inner">
                              <ul role="menu" itemScope="" itemType="http://schema.org/SiteNavigationElement">
                                <li role="menuitem" itemProp="url"
                                    id="id-157280810-a170c743-5a1b-4662-bd7b-1dfdda05272a"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-157280810-a170c743-5a1b-4662-bd7b-1dfdda05272a&quot;,&quot;title&quot;:&quot;Other Telstra sites&quot;,&quot;linkText&quot;:&quot;Telstra Wholesale&quot;,&quot;destination&quot;:&quot;https://www.telstrawholesale.com.au/&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstrawholesale.com.au/" itemProp="name">Telstra Wholesale <svg
                                      role="img" aria-labelledby="icon-title-73883" className="nav-icon" height="15"
                                      width="15" viewBox="0 0 100 100"><title id="icon-title-73883">opens external
                                    website</title>
                                    <path
                                        d="M18.8 85.1h56c2.2 0 4-1.8 4-4v-32h-8v28h-48v-48h28v-8h-32c-2.2 0-4 1.8-4 4v56c0 2.2 1.8 4 4 4z"
                                        fill="currentColor"></path>
                                    <path d="M45.7 48.7l5.6 5.6 25.9-25.8v8.7h8V14.9H62.8v8h8.7z"
                                          fill="currentColor"></path>
                                  </svg></a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id-157280810-d5258d32-d527-4433-b392-cc0b2e198b15"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-157280810-d5258d32-d527-4433-b392-cc0b2e198b15&quot;,&quot;title&quot;:&quot;Other Telstra sites&quot;,&quot;linkText&quot;:&quot;Global Enterprises &amp; Services&quot;,&quot;destination&quot;:&quot;https://www.telstraglobal.com/&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstraglobal.com/" itemProp="name">Global
                                    Enterprises &amp; Services
                                    <svg role="img" aria-labelledby="icon-title-89688" className="nav-icon" height="15"
                                         width="15" viewBox="0 0 100 100"><title id="icon-title-89688">opens external
                                      website</title>
                                      <path
                                          d="M18.8 85.1h56c2.2 0 4-1.8 4-4v-32h-8v28h-48v-48h28v-8h-32c-2.2 0-4 1.8-4 4v56c0 2.2 1.8 4 4 4z"
                                          fill="currentColor"></path>
                                      <path d="M45.7 48.7l5.6 5.6 25.9-25.8v8.7h8V14.9H62.8v8h8.7z"
                                            fill="currentColor"></path>
                                    </svg>
                                  </a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id-157280810-d974924b-a75e-4d24-9b79-9c8c59fdb94b"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-157280810-d974924b-a75e-4d24-9b79-9c8c59fdb94b&quot;,&quot;title&quot;:&quot;Other Telstra sites&quot;,&quot;linkText&quot;:&quot;Telstra Media&quot;,&quot;destination&quot;:&quot;http://media.telstra.com.au/home&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="http://media.telstra.com.au/home" itemProp="name">Telstra Media <svg
                                      role="img" aria-labelledby="icon-title-66556" className="nav-icon" height="15"
                                      width="15" viewBox="0 0 100 100"><title id="icon-title-66556">opens external
                                    website</title>
                                    <path
                                        d="M18.8 85.1h56c2.2 0 4-1.8 4-4v-32h-8v28h-48v-48h28v-8h-32c-2.2 0-4 1.8-4 4v56c0 2.2 1.8 4 4 4z"
                                        fill="currentColor"></path>
                                    <path d="M45.7 48.7l5.6 5.6 25.9-25.8v8.7h8V14.9H62.8v8h8.7z"
                                          fill="currentColor"></path>
                                  </svg></a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id-157280810-33ce88ae-5b1a-4370-9e91-f5d8838d1dfc"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-157280810-33ce88ae-5b1a-4370-9e91-f5d8838d1dfc&quot;,&quot;title&quot;:&quot;Other Telstra sites&quot;,&quot;linkText&quot;:&quot;Telstra Health&quot;,&quot;destination&quot;:&quot;https://www.telstrahealth.com/&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstrahealth.com/" itemProp="name">Telstra Health <svg
                                      role="img" aria-labelledby="icon-title-79886" className="nav-icon" height="15"
                                      width="15" viewBox="0 0 100 100"><title id="icon-title-79886">opens external
                                    website</title>
                                    <path
                                        d="M18.8 85.1h56c2.2 0 4-1.8 4-4v-32h-8v28h-48v-48h28v-8h-32c-2.2 0-4 1.8-4 4v56c0 2.2 1.8 4 4 4z"
                                        fill="currentColor"></path>
                                    <path d="M45.7 48.7l5.6 5.6 25.9-25.8v8.7h8V14.9H62.8v8h8.7z"
                                          fill="currentColor"></path>
                                  </svg></a></li>
                                <li role="menuitem" itemProp="url"
                                    id="id-157280810-442e6884-33b0-4dc4-b292-10cf3e522322"
                                    data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-157280810-442e6884-33b0-4dc4-b292-10cf3e522322&quot;,&quot;title&quot;:&quot;Other Telstra sites&quot;,&quot;linkText&quot;:&quot;Telstra Thanks Rewards&quot;,&quot;destination&quot;:&quot;https://www.telstra.com.au/thanks&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;global-footer-item&quot;}}">
                                  <a href="https://www.telstra.com.au/thanks" itemProp="name">Telstra Thanks
                                    Rewards </a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="socialIcons parbase">
                    <section className="telstra-social" aria-labelledby="heading-find"><h2 className="heading-text"
                                                                                           id="heading-find">Find
                      us</h2>
                      <ul>
                        <li id="id-938985014-015460f1-d946-443c-b2e3-7f47e276e03b"
                            data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-015460f1-d946-443c-b2e3-7f47e276e03b&quot;,&quot;name&quot;:&quot;Facebook&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                          <a href="https://www.facebook.com/Telstra24x7" aria-label="Facebook"><span
                              className="footer-icon"><svg role="img" height="32" width="32" viewBox="0 0 32 32"
                                                           xmlns="http://www.w3.org/2000/svg"><path
                              d="M16 0C7.161 0 0 7.161 0 16s7.161 16 16 16 16-7.161 16-16S24.806 0 16 0zm4.677 9.935h-1.71c-1.322 0-1.58.646-1.58 1.581v2.065h3.194l-.42 3.225h-2.774v8.259h-3.322v-8.259H11.29v-3.225h2.775v-2.387c0-2.742 1.677-4.259 4.129-4.259.838 0 1.645.033 2.483.13v2.87z"
                              fill="#5B5B5B" fill-rule="nonzero"></path></svg></span> <span>Facebook</span></a></li>
                        <li id="id-938985014-acbd8530-7ccd-4b67-9d57-60854b144ab6"
                            data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-acbd8530-7ccd-4b67-9d57-60854b144ab6&quot;,&quot;name&quot;:&quot;Twitter&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                          <a href="https://twitter.com/telstra" aria-label="Twitter"><span className="footer-icon"><svg
                              role="img" height="32" width="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path
                              d="M16 0C7.161 0 0 7.161 0 16s7.161 16 16 16 16-7.161 16-16S24.806 0 16 0zm6.645 12.613v.452c0 4.483-3.42 9.71-9.71 9.71a9.678 9.678 0 0 1-5.258-1.55c.29.033.549.033.84.033a6.926 6.926 0 0 0 4.257-1.452c-1.484-.032-2.774-1-3.193-2.387.193 0 .419.033.613.033.322 0 .612-.033.903-.13A3.362 3.362 0 0 1 8.355 14v-.032c.451.258 1 .42 1.548.42-.968-.582-1.548-1.678-1.548-2.84 0-.613.161-1.193.451-1.71a9.529 9.529 0 0 0 7.033 3.581c-.033-.258-.097-.548-.097-.806 0-1.871 1.548-3.42 3.42-3.42 1 0 1.87.42 2.483 1.097.807-.161 1.484-.451 2.161-.838a3.446 3.446 0 0 1-1.483 1.87 7.165 7.165 0 0 0 1.967-.548c-.451.71-1.032 1.323-1.645 1.839z"
                              fill="#5B5B5B" fill-rule="nonzero"></path></svg></span> <span>Twitter</span></a></li>
                        <li id="id-938985014-348eb0a4-f762-4ee5-bcc1-a6d42acfc912"
                            data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-348eb0a4-f762-4ee5-bcc1-a6d42acfc912&quot;,&quot;name&quot;:&quot;Instagram&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                          <a href="https://www.instagram.com/telstra/" aria-label="Instagram"><span
                              className="footer-icon"><svg role="img" height="32" width="32" viewBox="0 0 32 32"
                                                           xmlns="http://www.w3.org/2000/svg"><g fill="#5B5B5B"
                                                                                                 fill-rule="evenodd"><path
                              d="M22.603 10.907a3.292 3.292 0 0 0-.64-.992c-.288-.32-.576-.48-.992-.64-.288-.128-.768-.256-1.6-.288-.896-.032-1.184-.064-3.456-.064-2.272 0-2.56 0-3.456.064-.832.032-1.28.192-1.6.288-.384.16-.672.352-.992.64-.32.288-.48.576-.64.992-.128.288-.256.768-.288 1.6-.032.896-.064 1.184-.064 3.456 0 2.272 0 2.56.064 3.456.032.832.192 1.28.288 1.6.16.384.352.672.64.992.288.288.576.48.992.64.288.128.768.256 1.6.288.896.032 1.184.064 3.456.064 2.272 0 2.56 0 3.456-.064.832-.032 1.28-.192 1.6-.288.384-.16.672-.352.992-.64.32-.288.48-.576.64-.992.128-.288.256-.768.288-1.6.032-.896.064-1.184.064-3.456 0-2.272 0-2.56-.064-3.456-.032-.832-.16-1.312-.288-1.6zm-6.656 9.44a4.37 4.37 0 0 1-4.384-4.384 4.37 4.37 0 0 1 4.384-4.384 4.37 4.37 0 0 1 4.384 4.384 4.37 4.37 0 0 1-4.384 4.384zm4.544-7.936a1.01 1.01 0 0 1-1.024-1.024 1.01 1.01 0 0 1 1.024-1.024 1.01 1.01 0 0 1 1.024 1.024c.032.576-.448 1.024-1.024 1.024z"
                              fill-rule="nonzero"></path><circle cx="15.949" cy="15.961" r="2.848"
                                                                 transform="rotate(-9.218 15.95 15.961)"></circle><path
                              d="M15.947.091C7.18.091.075 7.195.075 15.963S7.18 31.835 15.947 31.835 31.82 24.731 31.82 15.963 24.683.091 15.947.091zm8.48 19.392a6.698 6.698 0 0 1-.384 2.08 4.107 4.107 0 0 1-.992 1.504c-.48.48-.96.768-1.504.992-.544.224-1.152.352-2.08.384-.928.032-1.216.064-3.52.064-2.304 0-2.624 0-3.52-.064a6.698 6.698 0 0 1-2.08-.384 4.107 4.107 0 0 1-1.504-.992c-.48-.48-.768-.96-.992-1.504-.224-.544-.352-1.152-.384-2.08-.032-.928-.064-1.216-.064-3.52 0-2.304 0-2.624.064-3.52a6.698 6.698 0 0 1 .384-2.08 4.107 4.107 0 0 1 .992-1.504c.48-.48.96-.768 1.504-.992.544-.224 1.152-.352 2.08-.384.928-.032 1.216-.064 3.52-.064 2.304 0 2.624 0 3.52.064a6.698 6.698 0 0 1 2.08.384 4.107 4.107 0 0 1 1.504.992c.48.48.768.96.992 1.504.224.544.352 1.152.384 2.08.032.928.064 1.216.064 3.52 0 2.304 0 2.592-.064 3.52z"
                              fill-rule="nonzero"></path></g></svg></span> <span>Instagram</span></a></li>
                        <li id="id-938985014-fdefef33-9c1c-4a4b-abc6-44df8b512173"
                            data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-fdefef33-9c1c-4a4b-abc6-44df8b512173&quot;,&quot;name&quot;:&quot;YouTube&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                          <a href="https://www.youtube.com/user/TelstraCorp" aria-label="YouTube"><span
                              className="footer-icon"><svg role="img" height="32" width="32" viewBox="0 0 32 32"
                                                           xmlns="http://www.w3.org/2000/svg"><g fill="#5B5B5B"
                                                                                                 fill-rule="evenodd"><path
                              d="M15.872 0C7.104 0 0 7.104 0 15.872s7.104 15.872 15.872 15.872 15.872-7.104 15.872-15.872S24.608 0 15.872 0zm9.664 20.704c-.224.864-.928 1.536-1.792 1.792-1.568.416-7.872.416-7.872.416s-6.304 0-7.872-.416c-.864-.224-1.536-.928-1.792-1.792-.448-1.568-.448-4.832-.448-4.832s0-3.296.416-4.864c.224-.864.928-1.536 1.792-1.792C9.536 8.8 15.84 8.8 15.84 8.8s6.304 0 7.872.416c.864.224 1.536.928 1.792 1.792.416 1.568.416 4.864.416 4.864s.032 3.264-.384 4.832z"
                              fill-rule="nonzero"></path><path
                              d="M13.856 18.88l5.216-3.008-5.216-3.04z"></path></g></svg></span>
                            <span>YouTube</span></a></li>
                        <li id="id-938985014-b0075f38-e69a-48a9-8f5d-e39646d43794"
                            data-analytics-component="{&quot;componentInfo&quot;:{&quot;componentID&quot;:&quot;id-938985014-b0075f38-e69a-48a9-8f5d-e39646d43794&quot;,&quot;name&quot;:&quot;CrowdSupport&quot;},&quot;category&quot;:{&quot;primaryCategory&quot;:&quot;social-item&quot;}}">
                          <a href="https://crowdsupport.telstra.com.au/" aria-label="CrowdSupport"><span
                              className="footer-icon"><svg role="img" height="32" width="32" viewBox="0 0 32 32"
                                                           xmlns="http://www.w3.org/2000/svg"><g fill="#5B5B5B"
                                                                                                 fill-rule="evenodd"><path
                              d="M17.603 15.932c-.29-.096-.418-.322-.482-.547 0-.29.096-.516.32-.645a2.674 2.674 0 0 0 1.254-2.288 2.699 2.699 0 0 0-2.699-2.707c-1.478 0-2.7 1.225-2.7 2.707 0 .935.483 1.773 1.254 2.288.225.129.386.419.321.645a.618.618 0 0 1-.482.547c-.128.033-3.502 1.257-3.502 5.865 0 .194.129.323.321.323h9.447c.193 0 .386-.13.418-.323.032-4.608-3.31-5.832-3.47-5.865z"></path><path
                              d="M21.17 15.836c-.29-.097-.419-.322-.483-.548 0-.29.097-.516.322-.645.61-.386.996-1.063.996-1.772 0-1.16-.932-2.095-2.089-2.095-.096 0-.193.033-.29.033.226.483.386 1.03.386 1.643a4.103 4.103 0 0 1-1.028 2.707c1.157.741 2.988 2.352 3.374 5.51h1.51c.097 0 .129-.032.129-.128-.032-3.674-2.731-4.64-2.828-4.705zM12.943 15.127a4.103 4.103 0 0 1-1.028-2.707c0-.548.129-1.128.386-1.644-.097-.032-.193-.032-.29-.032a2.087 2.087 0 0 0-2.088 2.095c0 .741.386 1.418.996 1.772.225.13.386.42.321.645a.618.618 0 0 1-.482.548c-.096 0-2.763.999-2.763 4.672 0 .097.032.13.129.13H9.57c.385-3.127 2.217-4.706 3.373-5.48z"></path><path
                              d="M15.964.034C7.16.034.026 7.188.026 16.018S7.16 32.002 15.964 32.002 31.9 24.848 31.9 16.018 24.736.034 15.964.034zm7.808 22.042h-1.446a1.665 1.665 0 0 1-1.639 1.45h-9.479a1.665 1.665 0 0 1-1.638-1.45H8.124c-.836 0-1.51-.709-1.51-1.514 0-3.19 1.638-4.77 2.763-5.479a3.475 3.475 0 0 1-.803-2.19c0-1.87 1.542-3.417 3.405-3.417.386 0 .74.033 1.125.194a4.107 4.107 0 0 1 2.86-1.16c1.124 0 2.088.418 2.86 1.16a3.44 3.44 0 0 1 1.124-.194c1.928 0 3.47 1.547 3.47 3.416 0 .838-.289 1.58-.803 2.191 1.06.71 2.7 2.288 2.667 5.479a1.49 1.49 0 0 1-1.51 1.514z"
                              fill-rule="nonzero"></path></g></svg></span> <span>CrowdSupport®</span></a></li>
                      </ul>
                    </section>
                  </div>
                </div>
              </div>
            </footer>
    );
  }
}

export default GlobalFooter;