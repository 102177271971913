import React, { Component } from "react";
import * as constants from "../Constants";
import iconInfo from "../../images/icon-info-blue.svg";
import iconError from "../../images/icon-error.svg";
import { openInNewTab, isMobileDevice, isIpadDevice } from "../../utils";
import {Surface, ActionButton, Spacing} from '@able/react';
import * as analytics from '../../analytics-utils';
import covidHeaderClose from "../../images/covid-header-close.svg";
import * as utils from '../../utils';
import ReactDOM from 'react-dom';

class BannerMessage extends Component {

    constructor(props) {
        super(props);
        this.serviceMessageDescriptionWithLinks = this.serviceMessageDescriptionWithLinks.bind(this);
        this.renderServiceMessage = this.renderServiceMessage.bind(this);
        this.cleanNonExistingProperty = this.cleanNonExistingProperty.bind(this);
        this.state = { showApptPopup: false };
    }

    render() {
        const serviceMessage = this.renderServiceMessage();
        if (!serviceMessage) return null;
        return (
            <div className={this.props.className}>
                {serviceMessage}
            </div>
        );
    }

    componentWillMount() {
        const { orderDetails: { orderSummary: { referenceCode = '' } = {}, reasonForUnHealthyData: { unHealthyMessage = '' } = {} } = {} } = this.props;
        if (referenceCode)
            this.addOmnitureObjectForBannerLoad(referenceCode, unHealthyMessage)        
    }

    addOmnitureObjectForBannerLoad(referenceCode, unHealthyMessage) {
        const {applicationObj, pageObj} = this.props;       
        let eventObj = null;        
       
        eventObj = analytics.createEventObj('error', 'Business', referenceCode, unHealthyMessage);
    
        analytics.createDateLayerObj(pageObj, applicationObj, eventObj);       
    }
    getWrapperClassName(status) {
        switch (status) {
            case 'error':
                return "banner-content-td-red";
        }
        return "banner-content-td-blue";
    }

    getIcon(status) {
        switch (status) {
            case 'error':
                return <img src={iconError} alt="error" style={{minWidth: 24+'px', minHeight: 24+'px'}}/>
        }
        return <img src={iconInfo} alt="information" style={{minWidth: 24+'px', minHeight: 24+'px'}}/>
    }

    cleanNonExistingProperty(value) {
        return value ? value : '';
    }

    // Legacy code
    serviceMessageDescriptionWithLinks() {
        const { orderDetails } = this.props;
        const { orderSummary: { orderId } } = orderDetails;
        let serviceMessageDescription = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.unHealthyMessage ? unescape(this.cleanNonExistingProperty(orderDetails.reasonForUnHealthyData.unHealthyMessage)) : null;
        if (!serviceMessageDescription) {
            return null
        }
        // let bannerLinkText = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.bannerLinkText ? unescape(orderDetails.reasonForUnHealthyData.bannerLinkText) : null;
        // let bannerLinkUrl = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.bannerLinkUrl ? unescape(orderDetails.reasonForUnHealthyData.bannerLinkUrl) : null;
        let unhealthyMessageUrl = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.unhealthyMessageUrl;
        var serviceMessage = [];

        if (serviceMessageDescription && serviceMessageDescription.includes(constants.TRADEMARK_SYMB)) {
            serviceMessageDescription = serviceMessageDescription.split(constants.TRADEMARK_SYMB).join('™');
        }

        if (serviceMessageDescription && serviceMessageDescription.includes(constants.MOVES_EMAIL_LINK)) {
            let serviceMessageArrayForEmailLink  = serviceMessageDescription.split(constants.MOVES_EMAIL_LINK);
            let subject = `${constants.MOVES_EMAIL_SUBJECT}${orderId}`; 
            let body = constants.MOVES_EMAIL_BODY;
            body += `Order Number: ${orderId}%0d%0d`
            body += constants.MOVES_EMAIL_BODY_PROVIDE_DETAILS;
            let email = `mailto:MovingWithTelstra@team.telstra.com?subject=${subject}&body=${body}`;
            serviceMessage.push(serviceMessageArrayForEmailLink[0]);
            serviceMessage.push(<a href={email}>email us</a>);
            serviceMessage.push(serviceMessageArrayForEmailLink[1]);
            return serviceMessage;
        }

        var serviceMessageUrlsSize = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.unhealthyMessageUrl ? orderDetails.reasonForUnHealthyData.unhealthyMessageUrl.length : null;

        let serviceMessageArrayForContactUs = serviceMessageDescription && serviceMessageDescription.includes("<contact us>") ? serviceMessageDescription.split('<contact us>') : null;

        if (serviceMessageArrayForContactUs && serviceMessageArrayForContactUs.length > 1) { // if contact us is present in the description
            for (let iterator = 0; iterator < serviceMessageArrayForContactUs.length; iterator++) { //loop to check where does "click here" lie w.r.t. "contact us"
                if (!serviceMessageArrayForContactUs[iterator].includes('<click here>')) { // click here is not present in the 1st part of contact us split so push the contact us into the serviceMessage
                    serviceMessage.push(<span key={iterator} id="contactUsText">{serviceMessageArrayForContactUs[iterator]}</span>);
                    if (iterator === 0) {
                        let linkKey = "key" + iterator;
                        serviceMessage.push(<a key={linkKey} id="contactUsLink" href={orderDetails.reasonForUnHealthyData.contactUsUrl} onClick={(e) => openInNewTab(e, orderDetails.reasonForUnHealthyData.contactUsUrl)}>{orderDetails.reasonForUnHealthyData.bannerLinkText ? orderDetails.reasonForUnHealthyData.bannerLinkText : "message us"}</a>);
                    }

                } else {
                    let serviceMessageArrForClickHere = serviceMessageArrayForContactUs[iterator].split('<click here>');
                    for (let j = 0; j < serviceMessageArrForClickHere.length; j++) {
                        let clickTextKey, clickTextId = "clickText" + j;

                        serviceMessage.push(<span id={clickTextId} key={clickTextKey}>{serviceMessageArrForClickHere[j]}</span>);
                        if (orderDetails.reasonForUnHealthyData.unhealthyMessageUrl[j]) {
                            let clickLinkKey, clickLinkId = "clickLink" + j;
                            serviceMessage.push(<a id={clickLinkId} key={clickLinkKey} href={orderDetails.reasonForUnHealthyData.unhealthyMessageUrl[j]} onClick={(e) => openInNewTab(e, orderDetails.reasonForUnHealthyData.unhealthyMessageUrl[j])}>click here</a>);
                        }

                    }
                };

            }
            return serviceMessage;
        } else if (Array.isArray(unhealthyMessageUrl) && unhealthyMessageUrl.length != 0) {
            if (serviceMessageDescription && serviceMessageDescription.includes('<message us>')) {
                let createdMessageDesc = serviceMessageDescription.split('<message us>');
                serviceMessage.push(createdMessageDesc[0]);
                serviceMessage.push(this.getMessageUsLink());
                serviceMessage.push(this.cleanNonExistingProperty(createdMessageDesc[1]));
                return serviceMessage;
            } else {
                serviceMessage.push(serviceMessageDescription + " ");
                return serviceMessage;
            }
        } else {
            let serviceMessageArrForClickHere = serviceMessageDescription && serviceMessageDescription.includes('<click here>') ? serviceMessageDescription.split('<click here>') : null;
            if (serviceMessageArrForClickHere && serviceMessageArrForClickHere.length > 0) {
                for (let j = 0; j < serviceMessageArrForClickHere.length; j++) {
                    let clickTextKey, clickTextId = "clickText" + j;
                    serviceMessage.push(<span id={clickTextId} key={clickTextKey}>{serviceMessageArrForClickHere[j]}</span>);
                    if (orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.unhealthyMessageUrl && orderDetails.reasonForUnHealthyData.unhealthyMessageUrl[j]) {
                        let clickLinkKey, clickLinkId = "clickLink" + j;
                        serviceMessage.push(<a id={clickLinkId} key={clickLinkKey} href={orderDetails.reasonForUnHealthyData.unhealthyMessageUrl[j]} onClick={(e) => openInNewTab(e, orderDetails.reasonForUnHealthyData.unhealthyMessageUrl[j])}>click here</a>);
                    }

                }
                return serviceMessage;
            }
        }
        return serviceMessageDescription;
    }

    getMessageUsLink() {
        const { orderDetails } = this.props;

        let defaultMessageLinkUrl = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.unhealthyMessageUrl ? unescape(orderDetails.reasonForUnHealthyData.unhealthyMessageUrl) : null;
        let unhealthyMessageMobileUrl = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.unhealthyMessageMobileUrl ? unescape(orderDetails.reasonForUnHealthyData.unhealthyMessageMobileUrl) : null;

        let unhealthyMessageUrl = defaultMessageLinkUrl;
        if ((isMobileDevice() || isIpadDevice()) && unhealthyMessageMobileUrl) {
            unhealthyMessageUrl = unhealthyMessageMobileUrl;
        }

        const showChat = unhealthyMessageUrl === 'open_chat';
        const unhealthyMessageLinkText = 'message us';

        // special case (Banner link url doesn't start with http or https), prefix it with https://
        if (!showChat && unhealthyMessageUrl) {
            if(!unhealthyMessageUrl.startsWith("https://") && !unhealthyMessageUrl.startsWith("http://"))
            unhealthyMessageUrl = `https://${unhealthyMessageUrl}`
        }

        if (unhealthyMessageUrl) {
            if (showChat) {
                return (
                    <a id="createdMessage" key="createdMessage" onClick={(e) => this.onMsgLinkClick(e, unhealthyMessageLinkText)}>{unhealthyMessageLinkText}</a>
                    )
            } else {
                return (
                    <a id="createdMessage" key="createdMessage" href={unhealthyMessageUrl} onClick={(e) => openInNewTab(e, unhealthyMessageUrl)}>message us</a>
                )
            }
        }
        return <></>
        
    }

    onMsgLinkClick = (e,linkText) => {
        const { orderDetails } = this.props;
        const chatGroupTags = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.chatGroupTags ? orderDetails.reasonForUnHealthyData.chatGroupTags : ['telstra', 'ost', 'troubleshoot'];

        this.openChat(linkText,chatGroupTags);
    }

    openChat = (linkText,chatGroupTags) => {
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.MESSAGE_US_ANALYTICS);
        const { orderDetails } = this.props;
        const chatParams = {
            isDirect: true,
            section: chatGroupTags,
        };
        const data = {
            type: 'error',
            error: {
                contextId: orderDetails?.orderSummary?.planName,
                message: `
                    Customer Message : ${linkText} <br>
                    Order ID: ${orderDetails?.orderSummary?.orderId}
                    Order Status: ${orderDetails?.orderSummary?.orderStatus}
                `
            }
        }
        window.livePerson.show(chatParams);
        window.lpTag.sdes.push(data);
    }

    renderAppointmentComponents() {
        const { orderDetails } = this.props;
        const orderId = orderDetails?.orderSummary?.orderId;
        const planName = orderDetails?.orderSummary?.planName;
        let iframeUrl = window.env.REACT_APP_API_APPT_URL + "/appointments?orderId=" + orderId + "&callbackURL=ORDER_STATUS_TRACKER&planName=" + planName;
        if (this.state.showApptPopup) {
            if(window.env.REACT_APP_DEV_ENV === 'true' || this.props.isAuthenticated) {
                return ReactDOM.createPortal(
                    <div className="iframe-container">
                        <div className="iframe-parent">
                            <iframe
                                id="IframePopup"
                                className="iframe-popup-td"
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                src={iframeUrl}/>
                        </div>
                        <div id="closeBtn" className="close-btn-td" onClick={this.confirmAlert.bind(this)}>
                            <img className="close-appt-manager-btn" src={covidHeaderClose}
                                 alt="COVID 19 MESSAGE"/>
                        </div>
                        <div id="popupBack" className="popup-td" onClick={this.confirmAlert.bind(this)}/>
                    </div>,
                    document.getElementById('iframe-portal')
                )
            } else {
                let redirectUrl = window.env.REACT_APP_HOST_NAME + '/orderdetails';
                utils.goToSignIn('', redirectUrl, false);
            }
        } else {
            return null;
        }
    }
    showApptDetailsMobile() {
        const { orderDetails } = this.props;
        const orderId = orderDetails?.orderSummary?.orderId;
        window.location.href = window.env.REACT_APP_API_APPT_URL + "/appointments?orderId="+ orderId + "&callbackURL=" + process.env.REACT_APP_API_APPT_CALLBACK_URL;
    }


    confirmAlert() {
        if (window.confirm("You haven’t finished rescheduling your appointment. Are you sure you want to leave?")) {
            this.togglePopup();
        }
    }

    togglePopup() {
        // this.props.closeLightbox();
        this.setState({ showApptPopup: !this.state.showApptPopup });
    }


    getCtaButton() {
        const { orderDetails } = this.props;
        let bannerLinkText = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.bannerLinkText ? unescape(orderDetails.reasonForUnHealthyData.bannerLinkText) : null;
        let defaultBannerLinkUrl = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.bannerLinkUrl ? unescape(orderDetails.reasonForUnHealthyData.bannerLinkUrl) : null;
        let bannerLinkMobileUrl = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.bannerLinkMobileUrl ? unescape(orderDetails.reasonForUnHealthyData.bannerLinkMobileUrl) : null;

        let bannerLinkUrl = defaultBannerLinkUrl;
        if ((isMobileDevice() || isIpadDevice()) && bannerLinkMobileUrl) {
            bannerLinkUrl = bannerLinkMobileUrl;
        }
        const showChat = bannerLinkUrl === 'open_chat';

        // special case (Banner link url doesn't start with http or https), prefix it with https://
        if (!showChat && bannerLinkUrl) {
            if(!bannerLinkUrl.startsWith("https://") && !bannerLinkUrl.startsWith("http://"))
                bannerLinkUrl = `https://${bannerLinkUrl}`
        }

        if(defaultBannerLinkUrl === constants.RESCHEDULE_APPT_BANNER_LINK){
            if ((isMobileDevice() || isIpadDevice()) && bannerLinkMobileUrl) {
                return (
                    <ActionButton element="button" variant="LowEmphasis" label={bannerLinkText} tabIndex="1" onClick={(event) => this.showApptDetailsMobile()} className="banner-cta-btn" tabIndex="0" />
                );
            }
            return (
                <ActionButton element="button" variant="LowEmphasis" label={bannerLinkText} tabIndex="1" onClick={this.togglePopup.bind(this)} className="banner-cta-btn" tabIndex="0" />
            );
        }

        if (bannerLinkText) {
            if (showChat) {
                return (
                    <ActionButton element="button" variant="LowEmphasis" tabIndex="1" onClick={(e) => this.onMsgLinkClick(e, bannerLinkText)} className="banner-cta-btn" tabIndex="0" label={bannerLinkText}/>
                )
            } else {
                return (
                    <ActionButton
                        className="banner-cta-btn"
                        element="button"
                        variant="LowEmphasis"
                        onClick={e => {
                        openInNewTab(e, bannerLinkUrl)
                        }}
                        label={bannerLinkText}
                        tabIndex="0"
                    />
                )
            }
        }
        return <></>
    }

    getBannerHeader() {
        const { orderDetails } = this.props;
        let unHealthyHeader = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.unHealthyHeader ? unescape(orderDetails.reasonForUnHealthyData.unHealthyHeader) : null
        if (unHealthyHeader) {
            if (unHealthyHeader.includes(constants.TRADEMARK_SYMB)) {
                unHealthyHeader = unHealthyHeader.split(constants.TRADEMARK_SYMB).join('™');
            }
            return <h2 className="banner-message-content-header-td" tabIndex="0">{unHealthyHeader}</h2>
        }
        return <></>
    }

    getBannerDescription() {
        let unHealthyMessage = this.serviceMessageDescriptionWithLinks()
        if (unHealthyMessage) {
            return (
                <p className="banner-subtext-td" tabIndex="0">
                    {unHealthyMessage}
                </p>
            )
        }
        return <></>
    }

    getBannerStatus() {
        let { orderDetails } = this.props
        let colorCode = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.unHealthyColorCode ? orderDetails.reasonForUnHealthyData.unHealthyColorCode.toLowerCase() : null
        switch (colorCode) {
            case constants.RED:
                return 'error'
            default:
                return 'info'
        }
    }

    createBannerObjForOmniture() {
        let { orderDetails, pageObj, applicationObj } = this.props
        let unHealthyHeader = orderDetails.reasonForUnHealthyData && orderDetails.reasonForUnHealthyData.unHealthyHeader ? unescape(orderDetails.reasonForUnHealthyData.unHealthyHeader) : null
        let unHealthyMessage = this.serviceMessageDescriptionWithLinks()

        let bannerObj = null
        bannerObj = {
            "bannerHeader": unHealthyHeader,
            "bannerDescription": unHealthyMessage
        }
        analytics.createBannerObj(pageObj, applicationObj, bannerObj);
    }

    renderServiceMessage() {
        let { orderDetails } = this.props
        // If reason for unHealthyData is not present, don't show any banner message.
        if (!orderDetails.reasonForUnHealthyData || (!orderDetails.reasonForUnHealthyData.unHealthyHeader && !orderDetails.reasonForUnHealthyData.unHealthyMessage)) {
            return null;
        }
        this.createBannerObjForOmniture();
        // TODO: Do we need padding bottom here?
        let bannerStatus = this.getBannerStatus();
        let icon = this.getIcon(bannerStatus);
        let wrapperClassName = this.getWrapperClassName(bannerStatus);
        return (
            <Surface className={wrapperClassName} variant="SurfaceHigh">
                    <div className="banner-body-td">
                        <div>{icon}</div>
                        <Spacing left="spacing2x" />
                        <div>
                    {this.getBannerHeader()}
                    {this.getBannerDescription()}
                    {this.renderAppointmentComponents()}
                        </div>
                    </div>
                    {this.getCtaButton()}
                </Surface>

        )
    }
}

export default BannerMessage;
