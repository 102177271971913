import React, {Component} from "react";
import StatusIconTD from "./StatusIconsTD";
import {ADDRESS_TO_BE_DISCONNECT_ON} from "../Constants";
import {ADDRESS_DISCONNECTED_ON} from "../Constants";
import {COMPLETED} from "../Constants";
import TimelineMilestone from './TimelineMilestonesTD'

class Disconnect extends Component {

    render() {
        const {disconnect} = this.props;
        return (
          <TimelineMilestone
            title="Disconnect from old address"
            icon={ <StatusIconTD uniqueIdentifier="Disconnect" status={disconnect.iconStatus}/> }
            lineColor={disconnect.iconStatus && disconnect.iconStatus.toLowerCase() !== 'completed'  ? '#d8d8d8': '#0064D2'}>
            <div id="Disconnect-Date" className="progress-details date">{disconnect.status && disconnect.status.toLowerCase() === COMPLETED  ? ADDRESS_DISCONNECTED_ON: ADDRESS_TO_BE_DISCONNECT_ON}{disconnect.date}</div>
          </TimelineMilestone>
        );
    }

    getNodes(equalizerComponent, equalizerElement) {
        return [
          this.refs.node1,
          this.refs.node2
        ]
    }
}

export default Disconnect;
