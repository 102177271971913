import React from "react";
import TimelineMilestone from './TimelineMilestonesTD'
import StatusIconTD from "./StatusIconsTD"
import infoIcon from "../../images/icon-info-blue.svg";
import { COMPLETED } from "../Constants";

export default function ServiceUpgradeTD({serviceUpgradeDetail}) {
  if (serviceUpgradeDetail && serviceUpgradeDetail.showServiceUpgrade) {
    return (
      <TimelineMilestone
        title={serviceUpgradeDetail.serviceUpgradeLabel}
        icon={
          <StatusIconTD 
            uniqueIdentifier="ServiceUpgrade" 
            status={serviceUpgradeDetail.serviceUpgradeTimelineStatus}
          />
        }
        lineColor={
          serviceUpgradeDetail.serviceUpgradeTimelineStatus && 
          serviceUpgradeDetail.serviceUpgradeTimelineStatus.toLowerCase() !== COMPLETED ? '#d8d8d8' : '#0064D2'
        }
      >
        { 
          serviceUpgradeDetail.serviceUpgradeSubstatus &&
          <p id="Service-Upgrade-Substatus">
            {serviceUpgradeDetail.serviceUpgradeSubstatus}
          </p>
        }
        { serviceUpgradeDetail && 
          <div className="plan-label">
                <div id="old-plan-label">
                  {serviceUpgradeDetail.oldPlanText
                    ? serviceUpgradeDetail.oldPlanText
                    : ""}
                </div>

                <div id="new-plan-label">
                  {serviceUpgradeDetail.newPlanText
                    ? serviceUpgradeDetail.newPlanText
                    : ""}
                </div>
              </div>
        }
        {
          serviceUpgradeDetail.serviceUpgradeStatusMessage &&
          <div className="flex-row message-flex">
            <img 
              src={infoIcon}
              className="small-icon-td" 
              alt="Info Icon" 
            />
            <span id="Service-Upgrade-Status-Message">
              {serviceUpgradeDetail.serviceUpgradeStatusMessage}
            </span>
          </div>
        }
      </TimelineMilestone>
    )
  } 
  return null;
}