import React from "react";
import TimelineMilestone from './TimelineMilestonesTD'
import StatusIconTD from "./StatusIconsTD"
import alertIcon from "../../images/icon-alert-small.svg";
import infoIcon from "../../images/icon-info-blue.svg";
import { COMPLETED, ON_HOLD } from "../Constants";

export default function IDDPackTD({iddPackDetail}) {
  if (iddPackDetail && iddPackDetail.showActivateOrRemoveCallingPack) {
    return (
      <TimelineMilestone
        title={iddPackDetail.iddCallingPackTimelineLabel}
        icon={<StatusIconTD uniqueIdentifier="IDDPack" status={iddPackDetail.iddPackTimelineStatus}/>}
        lineColor={iddPackDetail.iddPackTimelineStatus && iddPackDetail.iddPackTimelineStatus.toLowerCase() !== COMPLETED ? '#d8d8d8' : '#0064D2'}
      >
        { 
          iddPackDetail.iddCallingPackSubstatus &&
          <p id="IDD-Pack-Substatus">{iddPackDetail.iddCallingPackSubstatus}</p>
        }
  
        { 
          iddPackDetail.iddCallingPackName &&
          <p>Item : <span id="IDD-Pack-Name">{iddPackDetail.iddCallingPackName}</span></p>
        }
  
        {
          iddPackDetail.iddCallingPackStatusMessage &&
          <div className="flex-row message-flex">
            <img 
              src={iddPackDetail.iddCallingPackSubstatus.toLowerCase() === ON_HOLD ? alertIcon : infoIcon}
              className="small-icon-td" 
              alt="Info Icon" />
            <span id="IDD-Pack-Status-Message">{iddPackDetail.iddCallingPackStatusMessage}</span>
          </div>
        }
      </TimelineMilestone>
    )
  } 
  return null;
}