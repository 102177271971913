import React, { Component } from "react";
import * as constants from "../../constants";
import {Spacing, Surface, TextStyle} from '@able/react';
import iconError from "../../images/icon-error.svg";
import * as analytics from '../../analytics-utils';
import * as allConstants from "../Constants";

class ErrorMessage extends Component {

  getErrorText() {
    let errorMessage = '';
    switch (this.props.errorType) {
      case constants.RETRIEVE_ERRORS.DOWNSTREAM_ERROR:
        errorMessage = constants.ERROR_MESSAGE_DOWNSTREAM_ERROR
        break;
      case constants.RETRIEVE_ERRORS.NOT_FOUND_ERROR:
        errorMessage = constants.ERROR_MESSAGE_NOT_FOUND_ERROR
        break;  
      default:
        errorMessage = constants.ERROR_MESSAGE_NOT_FOUND_ERROR 
        break; 
    }
    this.addOmnitureObjectForErrorLoad(errorMessage);
    
    return errorMessage;
  }

  addOmnitureObjectForErrorLoad(errorMessage) {   
    const {errorCode} = this.props;
    let pageObj = null;
    let applicationObj = null;
    let eventObj = null;

    pageObj = analytics.createPageObj(allConstants.ORDER_SEARCH_PAGE_NAME_ANALYTICS);
    eventObj = analytics.createEventObj('error', 'Business', errorCode, errorMessage);

    analytics.createDateLayerObj(pageObj, applicationObj, eventObj);    
  }

  getHeaderText() {
    switch (this.props.errorType) {
      case constants.RETRIEVE_ERRORS.DOWNSTREAM_ERROR:
        return constants.ERROR_CARD_HEADER_DOWNSTREAM_ERROR
      case constants.RETRIEVE_ERRORS.NOT_FOUND_ERROR:
        return constants.ERROR_CARD_HEADER_NOT_FOUND_ERROR  
      default:
        return constants.ERROR_CARD_HEADER_NOT_FOUND_ERROR  
    }
  }
  
  render() {
    let error = {
      type: "technical",
      errorCode: "T0000500",
      shortMsg: "Service call fails to retrieve order details (Error Code 500)"
    };
    const alertIcon = <img src={iconError} alt="error" style={{minWidth: 24+'px', minHeight: 24+'px'}}/>
    return (
        <div className={this.props.className}>
        <Surface className="banner-content-td" variant="SurfaceHigh">
          <div className="banner-body-td">
            <div>{alertIcon}</div>
            <Spacing left="spacing1x" />
            <div>
              <TextStyle
                  element="p"
                  alias="HeadingD"
                  colour="Default"
                  alignment="Left"
                  className=""
              >
                {this.getHeaderText()}
              </TextStyle>
              <p className="banner-subtext-td" tabIndex="0">{this.getErrorText()}</p>
            </div>
          </div>
        </Surface>
        </div>
    )
  }
}

export default ErrorMessage;
