import React, { Component } from 'react'
import s from './styles'

class Timeline extends Component {
  render() {
    const { orientation = 'left', children, lineColor, lineStyle, style, className, ...otherProps } = this.props
    const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { orientation }))
    let classes = `responsive-width timeline-container`;
    if (className) {
      classes += ` ${className}`;
    }
    return (
      <div className={classes}>
        <div role="tabpanel" style={{ ...s.container, ...style }} {...otherProps} className="order-timeline">
          {childrenWithProps}
          <div style={s.containerAfter} />
        </div>
      </div>
    )
  }
}


export default Timeline
