import {connect} from "react-redux";
import OrderSummary from "../presenters/components/OrderSummaryTD";

function mapStateToProps(state) {
    return {
        orderSummary: state.orderDetails.orderSummary
    }
}

const ConnectedOrderSummary = connect(mapStateToProps)(OrderSummary);

export default ConnectedOrderSummary;
