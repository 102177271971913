import React, {Component} from "react";
import { Row , Column} from "react-foundation-components/lib/global/grid";
import iconDeviceName from "../../images/icon-mobile-device.svg";
import * as constants from "../Constants";
import { Header } from './OrderDetailsComponentsTD';

class MobileOrderSummary extends Component {
    constructor(props) {
        super(props);
    }

    render() {
         return this.renderMobileOrderSummary();
    }



 renderMobileOrderSummary(){
        const {orderSummary} = this.props;
        const orderStatus = orderSummary.orderStatus;
        const orderId =  orderSummary.orderId;
        const externalOrderId =  orderSummary.externalOrderId;
        const planName = orderSummary.planName;
        const queryOrderId = orderSummary.queryOrderId;
        console.log('*** mobile order summary ***');
        let showOrderDate = false;
        if (
            orderSummary.orderSummaryDate
            && orderSummary.orderCategory.toLowerCase() === constants.ORDER_CATEGORY_MOBILE_POSTPAID
        ) {
            showOrderDate = true;
        }
        let orderNumber = null;
        if (queryOrderId) {
            orderNumber = queryOrderId;
        } else if (externalOrderId) {
            orderNumber = externalOrderId;
        } else {
            orderNumber = orderId;
        }

        if (orderId && orderStatus) {
         return (
            <div>
                <Row collapse className="no-margin">
                    <Column>
                        <Header description={orderSummary.planName} />
                    </Column>
                </Row>
                <div className="order-summary-content responsive-width">
                    <Row collapse className="no-margin">
                        {
                            showOrderDate ? (
                                <Column medium={5}>
                                    <div>
                                        {this.renderMobileOrderSummaryDateLabel(orderSummary)}
                                    </div>
                                    <div id="Order-Summary-Day" className="order-summary-date">
                                        {orderSummary.orderSummaryDate}
                                    </div>
                                </Column>
                            ): null
                        }
                        <Column medium={5}>
                            <div className="order-summary-right">
                                <span id="Order-Number-Label" className="field-label">
                                    Order number:
                                </span>
                                <span id="Order-Number" className="field-value order-number">
                                    {orderNumber}
                                </span>
                            </div>
                            <div className="order-summary-right order-status-box">
                                <span id="Order-Status-Label" className="field-label">
                                    Order status:
                                </span>
                                <span id="Order-Status"className="field-value order-status">
                                    {orderStatus}
                                </span>
                            </div>
                        </Column>
                    </Row>
                </div>
            </div>
        )
    } //write else condition to show error message
    }

    renderMobileOrderSummaryDateLabel(orderSummary){
        let orderStatus = orderSummary.orderStatus;
        let orderSummaryDateLabel = orderSummary.orderSummaryDateLabel;
        if (orderSummaryDateLabel){
            return <span id="Date-Label" className="field-label order-summary-date-label">{orderSummaryDateLabel}</span>;
        }
        switch(orderStatus.toLowerCase()){
            case constants.ON_HOLD:
            case constants.IN_PROGRESS:
                return <span id="Date-Label" className="field-label order-summary-date-label">Order Creation Date</span>;
            case constants.COMPLETE:
                return <span id="Date-Label" className="field-label order-summary-date-label">Order completed on</span>;
            case constants.CANCELLED:
                return <span id="Date-Label" className="field-label order-summary-date-label">Order cancelled on</span>;
            default :
                return '';
        }
    }

    }
export default MobileOrderSummary;
