import React, { Component } from "react";
import StatusIconTD from "./StatusIconsTD";
import TimelineMilestone from "./TimelineMilestonesTD";
import iconInfo from "../../images/icon-info-blue.svg";
import * as constants from "../Constants";

export default class TransferRequestDetail extends Component {
  render() {
    const { transferRequestDetail } = this.props;

    if (transferRequestDetail) {
      return (
        <TimelineMilestone
          title={transferRequestDetail.title}
          icon={
            transferRequestDetail.status && (
              <StatusIconTD
                uniqueIdentifier="Transfer-Request"
                status={transferRequestDetail.status}
              />
            )
          }
          lineColor={
            transferRequestDetail.status &&
            transferRequestDetail.status.toLowerCase() !==
              constants.COMPLETED && transferRequestDetail.status.toLowerCase() !== constants.COMPLETE
              ? "#d8d8d8"
              : "#0064D2"
          }
      
          createdAt=
            {transferRequestDetail.transferRequestDate ?
              <div className="progress-label">
                <div>
                    {transferRequestDetail.transferRequestDate}
                </div>
              </div>
              :null
          }
        >
          {transferRequestDetail.status && transferRequestDetail.status.toLowerCase() ===
            constants.IN_PROGRESS &&
            transferRequestDetail.description ? (
            <>
            <div id ="Transfer-Request-Alert" className="flex-row message-flex">       
              <img src={iconInfo} alt="info" className="shipment-message-icon small-icon-td-error shipment-message-icon"/>
              <span>{transferRequestDetail.description}</span>
            </div>
            </>
          ) : (
            <></>
          )}
        </TimelineMilestone>
      );
    }
    return null;
  }
}
