import React, { useEffect } from 'react';
import truck from '../../images/TruckTD.png';
import mobileTruck from '../../images/MobileTruckTD.png'
import MobileImageTD from '../../images/mobileTD.svg';
import MultilingualTD from '../../images/customer-testimonialsTD.svg';
import EmailSupportTD from '../../images/email-supportTD.svg';
import AlertIconTD from '../../images/Alert SmallTD.svg';
import BackToTopIconTD from '../../images/BackToTopTD.svg';
import Usabilla from '../Usabilla';
import UsabillaWidget from '../UsabillaWidget';
import { handleSignInSignOut } from '../../utils'
import { ActionButton } from '@able/react';


export const Static_contentTD = () => (
  <div className='static-content'>
    <div className='static-content-text'>
      <span>
        <h1>Track my order</h1>
        <p>Keep an eye on the progress of your <br className="mobile-none" /> orders and deliveries here.</p>
      </span>
    </div>
    <img src={truck} alt="" className='static-content-image-desktop' />
    <img src={mobileTruck} alt="" className='static-content-image-mobile' />
  </div>
);

const ViewDashboardLink = () => (
  <ActionButton
   element="button"
   variant="LowEmphasis"
   element="a"
   href={window.env.REACT_APP_HOST_NAME + '/dashboard'} 
   label="View your dashboard"
  />
);

const SignInLink = () => (
  <ActionButton
   element="button"
   variant="LowEmphasis"
   id="sign-in-button"
   onClick={(e) => handleSignInSignOut()} 
   label="Sign in"
  />
);

export const AlternativeMethodsTD = ({ signedIn }) => (
  <div className='alternative-methods p-top-7 p-btm-10 responsive-width'>
    <h2 className='m-btm-5'>Other ways to track your order</h2>
    <div className='alt-method'>
      <p>
        {
          signedIn ? 'See all your orders on your dashboard.' : 'Find your order by signing in.'
        }
      </p>
      {
        signedIn ? <ViewDashboardLink /> : <SignInLink />
      }
    </div>
    <div className='alt-method m-top-5'>
      <p>Track your order in the My Telstra app.</p>
      <ActionButton
        variant="LowEmphasis"
        element="a"
        href="https://www.telstra.com.au/mytelstra"
        target="_blank"
        rel="noopener noreferrer"
        label="Learn more about the app"
      />
    </div>
  </div>
)

export const RetrieveErrorTD = () => (
  <div className='retrieve-error m-btm-10'>
    <img src={AlertIconTD} alt='alertIcon' />
    <span>
      <h2>Sorry, we are unable to retrieve the details of your order.</h2>
      <p>
        Please ensure the order number you have entered is correct and try
        again.
      </p>
      <p>
        If you are using the correct order number, we will notify you once your
        order has progressed. Unfortunately due to COVID-19 we are expecting
        some delays. We apologise for the inconvenience.
      </p>
    </span>
  </div>
);

export const FeedbackComponent = () => {
  useEffect(() => {
    Usabilla();
  }, [])
  return (
    <div className='usabilla-feedback'>
      <UsabillaWidget />
    </div>
  );
};

export const StripBannerTD = ({ style }) => (
  <div className='strip-banner responsive-width' style={style}>
    <div className='strip-banner-function'>
      <img src={MobileImageTD} alt='telstraApp' />
      <span>
        <h3>My Telstra app</h3>
        <p>Help made easy with the My Telstra app.</p>
        <a
          href='https://www.telstra.com.au/mytelstra'
          target='_blank'
          className='alt-method-link'
        >
          Download now
        </a>
      </span>
    </div>
    <div className='strip-banner-function'>
      <img src={MultilingualTD} alt='multiLingual' />
      <span>
        <h3>Multilingual Support</h3>
        <p>
          If you'd like to speak to us in your preferred language, we've got you
          covered.
        </p>
        <a
          href='https://www.telstra.com.au/multicultural'
          target='_blank'
          className='alt-method-link'
        >
          Multilingual support
        </a>
      </span>
    </div>
    <div className='strip-banner-function'>
      <img src={EmailSupportTD} alt='customerSupport' />
      <span>
        <h3>Support Centre</h3>
        <p>More information can be found in our Frequently Asked Questions.</p>
        <a
          href='https://www.telstra.com.au/support'
          target='_blank'
          className='alt-method-link'
        >
          Go to support
        </a>
      </span>
    </div>
  </div>
);

export const BackToTopTD = ({ scrollToTop, className = '' }) => (
  <div className={`footer-dock responsive-width ${className}`}>
    <div className='footer-dock-button-container'>
      <a className='footer-dock-button' onClick={scrollToTop}>
        Top
        <img src={BackToTopIconTD} alt='backToTopIcon' />
      </a>
    </div>
  </div>
);
