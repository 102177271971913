import React, {Component} from "react";
import StatusIconTD from "./StatusIconsTD";

import TimelineMilestone from './TimelineMilestonesTD'
import {MODEM_PLUGIN_STATUS_NBN_CONNECTED} from "../Constants";
import alertIcon from "../../images/icon-alert-small.svg";
import infoIcon from "../../images/icon-info-blue.svg";
import * as constants from "../Constants";
import { ActionButton } from "@able/react";
import * as analytics from "../../analytics-utils";
import {openInNewTab} from "../../utils";

class ModemPlugIn extends Component {

    render() {
        const {modemPlugInDetail} = this.props;
        if (modemPlugInDetail && modemPlugInDetail.showModemPluginTimeline) {
        return (
            <TimelineMilestone
                title="Plug in modem"
                icon={<StatusIconTD uniqueIdentifier="PluginModem"
                                    status={modemPlugInDetail.modemPluginTimelineStatus}/>}
                lineColor={modemPlugInDetail.modemPluginTimelineStatus && modemPlugInDetail.modemPluginTimelineStatus.toLowerCase() !== 'completed' ? '#d8d8d8' : '#0064D2'}
                createdAt = {modemPlugInDetail.modemPluginStatusMessage && <span id="Shipment-Status-Text">{modemPlugInDetail.modemPluginStatusMessage}</span>}
            >

                {modemPlugInDetail.modemConnectionStatus === MODEM_PLUGIN_STATUS_NBN_CONNECTED &&
                <div id="pluginModem-connect-date" className="progress-details date">{modemPlugInDetail.modemConnectionDate}</div>}

                <div className="flex-row message-flex">
                    {(modemPlugInDetail.modemPluginSubLabelIcon && modemPlugInDetail.modemPluginSubLabel) &&
                    <img src={modemPlugInDetail.modemPluginSubLabelIcon === 'Alert' ? alertIcon : infoIcon}
                         className="modem-plugin-icon-td" alt="Info Icon" />}

                    <div className="flex-column message-flex">
                        {(modemPlugInDetail.modemPluginSubLabelIcon && modemPlugInDetail.modemPluginSubLabel) &&
                        <span id="modem-plugin-Text" className="modem-plugin-txt">{modemPlugInDetail.modemPluginSubLabel}</span>}
                        {modemPlugInDetail.selfInstructionsLinkLabel && modemPlugInDetail.selfInstructionsLink && (
                        <div className="modem-plugin-self-install">
                            <ActionButton
                                element="button"
                                variant="LowEmphasis"
                                onClick={e => this.openInstructionLink(e)}
                                label={modemPlugInDetail.selfInstructionsLinkLabel}
                            />
                        </div>)}

                        {modemPlugInDetail.nbnHealthCheckTroubleshootLabel && modemPlugInDetail.nbnHealthCheckTroubleshootLink && (
                            <div className="modem-plugin-self-install">
                                <ActionButton
                                    element="button"
                                    variant="LowEmphasis"
                                    onClick={e => openInNewTab(e,this.props.modemPlugInDetail.nbnHealthCheckTroubleshootLink)}
                                    label={modemPlugInDetail.nbnHealthCheckTroubleshootLabel}
                                />
                            </div>)}
                    </div>
                </div>
            </TimelineMilestone>
        );
        }
        return null;
    }

    openInstructionLink(e) {
        openInNewTab(e,this.props.modemPlugInDetail.selfInstructionsLink);
    }

    getNodes(equalizerComponent, equalizerElement) {
        return [
          this.refs.node1,
          this.refs.node2
        ]
    }
}

export default ModemPlugIn;
