import React from "react";
import covidHeaderClose from "../../images/covid-header-close.svg";

export default function SiteWideAlert({ alertMessage }) {

  return (
    <div>
      {showCovidHeader(alertMessage)}
      {showCloseBtnForCovid()}
    </div>
  );
}

function showCovidHeader(alertMessage) {

  return <section className="covid-section-main">

      <section className="covid-section">
          <div className="covid-parent-first">
            <div className="covid-text" dangerouslySetInnerHTML={{__html: alertMessage}}></div>
          </div>

    </section>
  </section>
}


function showCloseBtnForCovid() {
  return <div className="close-covid-btn" /* onClick={this.props.closeCovidBanner} */>
      <img className="close-covid-btn" src={covidHeaderClose} alt="Close Notification" />
  </div>
}
