import React, { Component } from "react";
import StatusIconTD from "./StatusIconsTD";
import TimelineMilestone from "./TimelineMilestonesTD";
import iconInfo from "../../images/icon-info-blue.svg";
import * as constants from "../Constants";

export default class NumberTransferDetail extends Component {
  render() {
    const { numberTransferDetail, isAuthenticated } = this.props;

    if (numberTransferDetail) {
      return (
        <TimelineMilestone
          title={numberTransferDetail.title}
          icon={
            numberTransferDetail.status && (
              <StatusIconTD
                uniqueIdentifier="Number-Transfer"
                status={numberTransferDetail.status}
              />
            )
          }
          lineColor={
            numberTransferDetail.status &&
            numberTransferDetail.status.toLowerCase() !==
              constants.COMPLETE && numberTransferDetail.status.toLowerCase() !== constants.COMPLETED
              ? "#d8d8d8"
              : "#0064D2"
          }
         
          createdAt=
            {numberTransferDetail.numberTransferDate ? 
              <div className="progress-label">
                <div>
                  
                    {numberTransferDetail.numberTransferDate}
                    
                </div>
              </div>  
          :null}

        >
          {numberTransferDetail.status && numberTransferDetail.status.toLowerCase() ===
            constants.IN_PROGRESS &&
            numberTransferDetail.description ? (
            <>
            <div id ="Number-Transfer-Alert" className="flex-row message-flex">       
              <img src={iconInfo} alt="info" className="shipment-message-icon small-icon-td-error shipment-message-icon"/>
              <span>{numberTransferDetail.description}</span>
            </div>
            </>
          ) : (
            <></>
          )}

        {isAuthenticated && numberTransferDetail.portInFNN && (
                  <div className="progress-details hardware-items">
                    <ul><li id="Phone-number"><span>Phone number: {numberTransferDetail.portInFNN}</span></li></ul>
                  </div>)}
        </TimelineMilestone>
      );
    }
    return null;
  }
}
