import { connect } from "react-redux";
import OrderProgressTD from "../presenters/components/OrderProgressTD";

function mapStateToProps(state) {
  return {
    orderDetails: state.orderDetails
  }
}

const ConnectedOrderProgressTD = connect(mapStateToProps)(OrderProgressTD);

export default ConnectedOrderProgressTD;
