import React from 'react';
import speechBubble from '../../images/speechbubble.svg';

class ChatButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: true };
  }

  onClick = () => {
    const { tags, orderTitle, orderId, orderStatus } = this.props;
    this.setState({ show: false });
    window.livePerson.show({
      isDirect: true,
      section: tags,
    });
    window.lpTag.sdes.push({
      type: 'error',
      error: {
        contextId: orderTitle,
        message: `
          Order ID: ${orderId}
          Order Status: ${orderStatus}
        `
      }
    });
  }

  render() {
    if (!this.state.show) {
      return null;
    }
    return (
      <div className="chat-btn-TD" onClick={this.onClick}>
        <img className="chat-btn-icon-TD" src={speechBubble} />
        Message Us
      </div>
    );
  }
}

export default ChatButton;