import React, {Component} from "react";
import progressFail from "../../images/Error@1.5x.svg";
import check from '../../images/Completed@1.5x.svg';
import inProgress from '../../images/edgar-timeline-in-progress-step.svg';
import toDo from '../../images/edgar-timeline-pending-step.svg';

class StatusIcon extends Component {

    render() {
        const {status} = this.props;

        let statusIconImg;
        switch(status.toLowerCase()){
            case "not started":
                statusIconImg = <img src={toDo} alt='Order is pending' className='all-timeline-icon pending-timeline-icon'/>;
                break;
            case 'in progress':
                statusIconImg = <img src={inProgress} alt='Order is in progress' className='all-timeline-icon'/>;
                break;
            case 'completed':
                statusIconImg = <img src={check} alt='Order is completed' className='all-timeline-icon complete-timeline-icon'/>;
                break;
            case 'attention':
                statusIconImg = <img src={inProgress} alt='Order has info' className='all-timeline-icon'/>;
                break;
            case 'problem':
                statusIconImg = <img src={progressFail} alt='Order failed' className='all-timeline-icon'/>;
                break;
            default:
                return null;
        }
        return statusIconImg;
    }
}

export default StatusIcon;
