import { connect } from 'react-redux';
import OrderSearchContainerTD from '../presenters/components/OrderSearchContainerTD';
import { selectErrorStatus, selectErrorType } from '../selectors';
import {
  fetchAuthOrderDetails,
  fetchUnauthOrderDetails,
} from '../actions';

const mapStateToProps = (state) => ({
  errorStatus: selectErrorStatus(state),
  errorType: selectErrorType(state),
  order: state.order,
  // to check if the orderDetails are getting fetched.
  fetching: state.orderDetails.isFetching,
  orderDetails: state.orderDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUnauthOrderDetails: (orderNumber) => dispatch(fetchUnauthOrderDetails(orderNumber)),
  fetchAuthOrderDetails: (orderNumber, accessToken) => dispatch(fetchAuthOrderDetails(orderNumber, accessToken))
});

const ConnectedOrderSearchContainerTD = connect(mapStateToProps, mapDispatchToProps)(
  OrderSearchContainerTD
);

export default ConnectedOrderSearchContainerTD;
