import React from "react";
import {openInNewTab} from "../../utils";
import { ActionButton } from "@able/react";
import TimelineTD from '../../presenters/components/TimelineTD';
import StatusIcon from "./TimelineStatusIcon";
import TimelineMilestonesTD from "../../presenters/components/TimelineMilestonesTD";
import { getCompareFunc } from "../common/utils";
import InfoIcon from "../../images/icon-info-blue.svg";
import RedAlertIcon from "../../images/red-alert-icon.svg";
import * as analytics from '../../analytics-utils';
import * as constants from "../../presenters/Constants";

const timelineColor = '#0064D2';

export default function GroupTimeline({ timelineGroup, page, header }) {
  const {groupHeader, groupFooter, groupTimeline} = timelineGroup;
  let timelines = groupTimeline?.sort(getCompareFunc('displaySequence'));
  timelines = getTimelines(groupTimeline);

  return (
    <React.Fragment>
      <div className="order-details-timeline" id="order-details-timeline">
        {page && page.loggedIn && header && header.formattedAddress && (
          <div className="timeline-group-header responsive-width">
            <span>{header.formattedAddress}</span>
          </div>
        )}

        <TimelineTD className="order-timeline-timeline-items">
          {timelines.map(timeline => (
            <TimelineMilestonesTD
              accessibleTitle
              {...timeline.props}
              key={timeline.props.displaySequence}
            >
                {timeline.children}
              </TimelineMilestonesTD>
          ))}
        </TimelineTD>

        <div className="responsive-width timeline-group-footer">
          <h3 role="subheading" tabIndex="0">{groupFooter.heading}</h3>
          <span tabIndex="0">{groupFooter.label}</span>
          <div>
            <ActionButton 
              tabIndex="0"
              element="button" 
              variant="LowEmphasis" 
              onClick={e => {
                openInNewTab(e, groupFooter.linkURL)
                addClickEventToEdgarOmnitureObject(groupFooter.linkLabel)
              }} 
              label={groupFooter.linkLabel} />
          </div>
        </div>

      </div>
    </React.Fragment>
  );
}

function addClickEventToEdgarOmnitureObject(eventAction) {
  analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, eventAction);
}

function getTimelines(groupTimelines) {
  groupTimelines = groupTimelines || [];
  
  return groupTimelines.map((timeline, index, a) => {
    const timelineProps = {
      props: {
        title: timeline.label,
        icon: getIconByStatus(timeline.status),
        lineColor: timeline.status?.toLowerCase() === 'completed' ? timelineColor : '#939393',
        key: timeline.displaySequence,
        displaySequence: timeline.displaySequence
      },
      children: renderTimelineBody(timeline)
    }

    // Don't show line after last timeline
    if(index === a.length - 1) timelineProps.props.lineStyle = {display: 'none'};

    return timelineProps;
  })
}

function renderTimelineBody(timeline) {
  if(!timeline) return null;
  return (
    <React.Fragment>
      <div className="timeline-section">
        {timeline.linkLabelIcon_1 && getAlertIcon(timeline.linkLabelIcon_1)}
        {timeline.message_1 && <p tabIndex="0">{timeline.message_1}</p>}
      </div>

      {timeline.message_2 && <div className="timeline-section timeline-second-message">
        {timeline.linkLabelIcon_2 && getAlertIcon(timeline.linkLabelIcon_2)}
        {/* Space placeholder */}
        {(timeline.linkLabelIcon_1 && !timeline.linkLabelIcon_2) && <div className="red-alert-icon--edgar"></div>}
        {<p tabIndex="0">{timeline.message_2}</p>}
      </div>}

      {timeline.linkLabel_1 &&
        <div className="timeline-section timeline-link-text">
          {/* Space placeholder */}
          {message1IconPresent(timeline) && !message2IconPresent(timeline) && getAlertIcon(timeline.linkLabelIcon_1, true)}
          {!message1IconPresent(timeline) && message2IconPresent(timeline) && getAlertIcon(timeline.linkLabelIcon_2, true)}

          {timeline.linkURL_1 ?
            <ActionButton
              element="button"
              variant="LowEmphasis"
              onClick={e => {
                openInNewTab(e, timeline.linkURL_1)
                addClickEventToEdgarOmnitureObject(timeline.linkLabel_1)
              }}
              label={timeline.linkLabel_1}
              tabIndex="0"
            /> : <p>{timeline.linkLabel_1}</p>}
        </div>
      }
    </React.Fragment>
  )
}

function message1IconPresent(timeline) {
  return timeline.linkLabelIcon_1 && timeline.message_1;
}

function message2IconPresent(timeline) {
  return timeline.linkLabelIcon_2 && timeline.message_2;
}

function getAlertIcon(iconName, placeholder = false) {
  let cls = '';
  let icon = '';
  let alt = '';
  switch (iconName) {
    case 'RED ALERT ICON':
      cls = 'red-alert-icon--edgar';
      icon = RedAlertIcon;
      alt = 'Alert icon';
      break;
    case 'INFO':
      cls = 'info-icon--edgar';
      icon = InfoIcon;
      alt = 'Info icon';
      break;
  }

  return (
    <div className={cls}>
      {!placeholder && <img src={icon} alt={alt} />}
    </div>
  )  
}

function getIconByStatus(status) {
  return <StatusIcon status={status}/>;
}