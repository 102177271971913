import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import orderDetails from './modules/orderDetails';
import dashboard from './modules/orders';
import order from './modules/order';
import orderSearchTD from './modules/orderSearchTD';

const rootReducer = combineReducers({
  routing: routerReducer,
  orderSearch: orderSearchTD,
  orderDetails,
  dashboard,
  order
});

export default rootReducer;
