import _ from 'lodash';
import React, { Component } from "react";
import queryString from "query-string";
import ConnectedOrderSummary from "../../connectors/ConnectedOrderSummary";
import ConnectedOrderProgressTD from "../../connectors/ConnectedOrderProgressTD";
import { Row, Column } from "react-foundation-components/lib/global/grid";
import { browserHistory } from 'react-router'
import HeaderTD from "./HeaderTD";
import Spinner from "../../Spinner/Spinner";
import {handleSignInSignOut, route} from "../../utils";
import Usabilla from "../Usabilla";
import * as constants from "../Constants";
import BannerMessage from "./BannerComponentTD";
import CovidComponent from "../CovidComponent";
import { StripBannerTD, BackToTopTD, FeedbackComponent } from './OrderSearchComponentsTD';
import OrderDetailBreadcrumbs from './OrderDetailBreadcrumbsTD';
import * as analytics from '../../analytics-utils';
import FooterWrapper from './FooterWrapperTD'
import * as utils from "../../utils";
import OrderDetailsIllustration from './OrderDetailsIllustrationTD';
import ChatButtonTD from './ChatButtonTD';
import SessionTimeout from './SessionTimeout';

class OrderStatusTracker extends Component {

    constructor(props) {
        super(props);

        this.pageUp = React.createRef()
        this.scrollToTop = this.scrollToTop.bind(this)
        this.getOrderNumber = this.getOrderNumber.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.renderComponents = this.renderComponents.bind(this);
        this.renderOrderStatusTracker = this.renderOrderStatusTracker.bind(this);
        this.firstTime = true;
        this.isUnauthorized = false;
        this.state = { isUnauthorized: false, showCovid: false, accessToken : null,bypassCache: false, showUsabillaWidget:true, showOrderDetails: true, showChatButton: false, chatGroupTags: [], overrideAppointment: false };
        this.orderNumber = this.getOrderNumber();
        this.accessToken = this.props.accessToken ? this.props.accessToken : null;
        this.pageObj = null;
        this.applicationObj = null;        
        this.urlParams = null;
        this.loading = true;

        localStorage.removeItem('telstraAppointmentIdOst');
        localStorage.removeItem('nbnAppointmentIdOst');
        localStorage.removeItem('nbnAppointmentId');
        localStorage.removeItem('telstraAppointmentId'); 
        //with TelstraAuth       
        
        if(window.env.REACT_APP_DEV_ENV === "true") { //since the env variable is of type string
            this.processbrowserLocation();            
        } else {
            this.processAuthFlowForOrderDetails();

        }
        // without TelstraAuth

        //  this.props.subscribeToAuthEvent();
        //  let hashParams = queryString.parse(this.props.location.hash);
        //  this.firstTime = false;

        //  if(hashParams && hashParams.access_token){
        //      this.isAuthenticated = true;
        //      this.props.fetchAuthOrderDetails(orderNumber, hashParams.access_token);
        //      this.accessToken = hashParams.access_token;
        //  }else{
        //      this.isAuthenticated = false;
        //      this.props.fetchUnauthOrderDetails(orderNumber);
        //  }
    }

    processAuthFlowForOrderDetails() {
        let isSilent = true;
        this.urlParams = this.props.location && this.props.location.search ? queryString.parse(this.props.location.search) : null;
        if(this.urlParams === null || this.urlParams === undefined || (this.urlParams && this.urlParams.overrideAppointment)) {
            if(this.urlParams && this.urlParams.overrideAppointment) {
                localStorage.setItem('overrideAppointment', this.urlParams.overrideAppointment);
            }
            utils.handleSignInSignOut(null, this.props.location.pathname, null, isSilent);          
        } else if(this.urlParams && this.urlParams.code) {
            let code = this.urlParams.code;
            const appointmentOverride = localStorage.getItem('overrideAppointment');
            let pathname = this.props && this.props.location && this.props.location.pathname ? this.props.location.pathname : null;
            utils.getAccessToken(code, pathname).then(authToken => {
                if(authToken) {
                    this.accessToken = authToken.access_token;
                    this.isAuthenticated = true;
                    this.firstTime = false;
                    this.props.fetchAuthOrderDetails(this.orderNumber, authToken.access_token, this.state.bypassCache, appointmentOverride);
                    this.loading = false;
                    this.setState({isUnauthorized:false, bypassCache: false});
                }
            });
            localStorage.removeItem('overrideAppointment');
        } else { //default case
            this.processbrowserLocation();
        } 
    }
    processbrowserLocation() {
        let location = Object.assign({}, browserHistory.getCurrentLocation());
        if(location.search)
            delete location.search;           
        
        if(location.hash) 
            delete location.hash;           

        if(location.query['error']) 
            delete location.query['error'];
        
        location.pathname = "/orderdetails/"+this.orderNumber;
        browserHistory.replace(location);
        this.props.fetchUnauthOrderDetails(this.orderNumber);
        this.loading = false;
        this.setState({isUnauthorized:true, bypassCache: false});
    }
    closeLightbox() {
        this.setState({ showUsabillaWidget: false });
    }

    bypassCache() {
        this.setState({ bypassCache: true});
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#fff";
        window.addEventListener('message', this.handleIframeTask);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orderDetails !== this.props.orderDetails) {
            if (this.props.orderDetails && !this.props.orderDetails.isFetching) {
                const displayRules = _.get(this.props.orderDetails, 'displayRules.0');
                const showMessagingWindow = _.get(displayRules, 'displayAction.0.showMessagingWindow');
                const timeToWaitInSecs = _.get(displayRules, 'displayConditions.0.value');
                const chatGroupTags = _.get(displayRules, 'dataAttributes.chatGroupTags');
                if (showMessagingWindow && !(utils.isMobileDevice() || utils.isIpadDevice())) {
                    setTimeout(() => {
                        this.setState({ showChatButton: true, chatGroupTags })
                    }, timeToWaitInSecs * 1000);
                }
            }
        }
    }

    scrollToTop() {
        window.scrollTo(0, this.pageUp.offsetTop);
    }

    handleIframeTask = (e) => {

        let listenerMsg = e.data;

        if(typeof listenerMsg === "string" && listenerMsg.toLowerCase() === constants.APPT_RESCHEDULED_LISTENER){
            this.bypassCache();
        }

        if(typeof listenerMsg === "string" && listenerMsg.toLowerCase() === constants.APPT_OVERRIDDEN_LISTENER){
            this.setState({ bypassCache: true, overrideAppointment: true });
        }

        if (typeof listenerMsg === "string" && listenerMsg.toLowerCase() === constants.TOKEN_EXPIRED_LISTENER) {
            handleSignInSignOut(e, this.props.location, this.accessToken);
        }
    };
   
    render() {

        let orderNumber = this.getOrderNumber();

        if(this.props.orderDetails.isUnauthorized) {
            this.props.fetchUnauthOrderDetails(orderNumber);
            this.setState({isUnauthorized:true, bypassCache: false});
        }
        
        if(this.state.bypassCache){
            if (this.accessToken) {
				console.log("this.accessToken",this.accessToken);
                this.isAuthenticated = true;
                this.firstTime = false;
                this.props.fetchAuthOrderDetails(orderNumber, this.accessToken, this.state.bypassCache, this.state.overrideAppointment);
            } else {
                this.isAuthenticated = false;
                this.firstTime = false;
                this.props.fetchUnauthOrderDetails(orderNumber);
            }
            this.setState({bypassCache:false});
        }
        const { orderDetails, location } = this.props;
        if (orderDetails.isExpired) {
            window.location.reload();
        }

        Usabilla();
        return (
          <React.Fragment>
            {this.renderOrderStatusTracker()}
            {this.accessToken ? <SessionTimeout /> : null}
          </React.Fragment>
        );
    }

    renderOrderStatusTracker() {
        let hardwareDeliveryDate=null;
        if(this.props.orderDetails && this.props.orderDetails.shipmentDetails && this.props.orderDetails.shipmentDetails.customerRequestedDeliveryDate){
            hardwareDeliveryDate= this.props.orderDetails.shipmentDetails.customerRequestedDeliveryDate;
        }
        return this.renderContent();
    }

    renderContent() {
        const { orderDetails } = this.props;
		
        if (orderDetails.isFetching === false && !this.loading) {
            return this.renderComponents()
        } else {
            return (<div>
                <Row collapse expanded>
                    <Column>
                        <HeaderTD accessToken={this.accessToken} location={this.props.location.pathname} />
                    </Column>
                </Row>
                <Row collapse expanded>
                    <Column largeCentered="centered" large={8}>
                        <div className="telstra-spinner-box">
                            <div>
                                <Spinner size="large" />
                            </div>
                        </div>
                    </Column>
                </Row>
            </div>
            )
        }
    }

    renderComponents() {
        const { orderDetails } = this.props;
        console.log('orderDetails.isError in ost', orderDetails.isError);
        console.log('orderDetails.isunauth in ost', orderDetails.isUnauthorized);
        if (orderDetails.isError) {
            route(undefined, '/')
            return <React.Fragment></React.Fragment>
        }
        return <div>
            {this.state.showCovid ? <CovidComponent closeCovidBanner={this.closeCovidBanner.bind(this)} /> : <></>}
            {this.addOmnitureObjectonPageLoad()}
           
            <HeaderTD ref={this.pageUp} accessToken={this.accessToken} location={this.props.location.pathname}/>
            <Row collapse expanded>
                <Column>
                    <OrderDetailsIllustration orderDetails={orderDetails} />
                </Column>
            </Row>
            <Row collapse>
                <Column>
                    <OrderDetailBreadcrumbs />
                </Column>
            </Row>
            <Row collapse>
                {                  
                    <div className="inner-container">
                        <BannerMessage
                            isAuthenticated={this.isAuthenticated}
                            isUnauthorized={this.isUnauthorized}
                            pageObj={this.pageObj}
                            applicationObj={this.applicationObj}
                            orderDetails={this.props.orderDetails}
                            className="order-summary-banner"
                        />
                        <ConnectedOrderSummary
                            orderCompletionDate={orderDetails.orderCompletionDate}
                            reasonForUnHealthyData={orderDetails.reasonForUnHealthyData}
                        />
                        <ConnectedOrderProgressTD
                            closeLightbox={this.closeLightbox.bind(this)}
                            isAuthenticated={this.isAuthenticated}
                            isUnauthorized={this.isUnauthorized}
                            accessToken={this.accessToken ? this.accessToken : ''}
                            bypassCache={this.bypassCache.bind(this)}
                            location={this.props.location}
                        />
                    </div>                    
                }
            </Row>
            <Row collapse>
                <div className="inner-container">
                    <StripBannerTD style={{ marginTop: '40px' }} />
                </div>
            </Row>
            {!orderDetails.isNotSupported ?
                <FeedbackComponent />
                : null}
            <BackToTopTD scrollToTop={this.scrollToTop} className="docked-btn-container responsive-width"/>
            {
                this.state.showChatButton && (
                    <ChatButtonTD
                        tags={this.state.chatGroupTags}
                        orderTitle={orderDetails.orderSummary?.planName}
                        orderId={orderDetails.orderSummary?.orderId}
                        orderStatus={orderDetails.orderSummary?.orderStatus}
                    />
                )
            }
            <FooterWrapper />
        </div>
    }

    addOmnitureObjectonPageLoad() { //on order details load
        const { orderDetails } = this.props;
        let pageObj = null;
        let applicationObj = null;
        let eventObj = null;

        pageObj = analytics.createPageObj(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS);
        
        applicationObj = this.setApplicationObjProps();   
        this.pageObj = pageObj;
        this.applicationObj = applicationObj;     
        eventObj = analytics.createEventObj(constants.ORDER_DETAILS_VIEWED_ANALYTICS, orderDetails?.orderSummary?.orderId || orderDetails?.orderId)

        analytics.createDateLayerObj(pageObj, applicationObj, eventObj);        
    }

    setApplicationObjProps() {
        const { orderDetails } = this.props;
        let applicationObj = analytics.createApplicationObj(orderDetails, this.accessToken);

        return applicationObj;
    }

    changeDetailsState() {
        this.setState({showOrderDetails: !this.state.showOrderDetails});
    }

    getOrderNumber() {
        const { params } = this.props;
        let orderNumber = params.orderNumber;
        try {
            if (orderNumber) {
                localStorage.orderNumber = orderNumber;
            } else {
                orderNumber = localStorage.orderNumber;
            }
        } catch (e) {
            console.log('LocalStorage is not available');
        }
        return orderNumber;
    }

    closeCovidBanner() {
        this.setState({ showCovid: false });
    }

}
export default OrderStatusTracker;
