import * as client from "../adapters/client";

const REQUEST_ORDER = 'REQUEST_ORDER';
const RECEIVE_ORDER = 'RECEIVE_ORDER';
const RECEIVE_ORDER_ERROR = 'RECEIVE_ORDER_ERROR';
const RECEIVE_ORDER_NOT_FOUND = 'RECEIVE_ORDER_NOT_FOUND';
const RESET_ORDER = 'RESET_ORDER';

export function requestOrder() {
    return {
        type: REQUEST_ORDER
    }
}

export function receiveOrder(json) {
    return {
        type: RECEIVE_ORDER,
        eBPMRefNumber: json.data.eBPMRefNumber,
        orderId: json.data.orderId,
        externalOrderId: json.data.externalOrderId
    }
}

export function fetchOrder(siebelOrderNumber, fetcher = client.fetchOrder) {

    return (dispatch) => {
        dispatch(requestOrder());
        fetcher(dispatch, receiveOrder, receiveOrderError, siebelOrderNumber);
    }
}

export function receiveOrderError(status) {
    switch (status) {
        case 404:
            return {type: RECEIVE_ORDER_NOT_FOUND}
        default:
            return {type: RECEIVE_ORDER_ERROR}
    }
}

export function resetOrder() {
    return {
        type: RESET_ORDER
    }
}

export default function reducer(state = {}, action) {
    switch (action.type) {
        case REQUEST_ORDER:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                isNotFound: false
            });
        case RECEIVE_ORDER:
            return Object.assign({}, state, {
                isFetching: false,
                eBPMRefNumber: action.eBPMRefNumber?action.eBPMRefNumber : action.externalOrderId ? action.externalOrderId: action.orderId
            });
        case RECEIVE_ORDER_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true
            });
        case RECEIVE_ORDER_NOT_FOUND:
            return Object.assign({}, state, {
                isFetching: false,
                isNotFound: true
            });
        case RESET_ORDER:
            return {};
        default:
            return state
    }
}