import React, { Component } from "react";
import OrderTimelineTD from "./OrderTimelineTD";
import * as constants from "../Constants";

import Timeline from './TimelineTD'
import {handleSignInSignOut} from "../../utils";



class MobileOrderProgress extends Component {
    render() {
        this.renderOrderSteps = this.renderOrderSteps.bind(this);
        const { orderTimeline } = this.props;

        if(orderTimeline)
        {return (
            <div>
                <Timeline>
                    {this.renderOrderSteps()}
                </Timeline>
                 {/*OSTK-1162: AC3 Hide Change Order link*/}
                {/*{orderCategory && orderCategory.toLowerCase() === constants.ORDER_CATEGORY_MOBILE_POSTPAID ?
                <div>
                    {this.renderChangeOrderBannerMessage(isUnauthorized, isAuthenticated)}
                </div> : ''}*/}
            </div>
        );
    }else {return null}
}

    renderOrderSteps() {
        const { orderTimeline , hardwareDelivery } = this.props;
        let preOrderStepsArray = [];

        for (let counter = 0; counter < orderTimeline.length; counter++) {
            let preOrderCurrentStep = orderTimeline[counter];
            let statusIconStateClass = "progress-state-normal";
            let timelineClass = "preorder-timeline-" + counter;

            if (preOrderCurrentStep.displayStatus.toLowerCase() === constants.ORDER_PROCESSED) {
                timelineClass = "order-processed";
                statusIconStateClass = "order-processed-state";
            } else if (preOrderCurrentStep.displayStatus.toLowerCase() === constants.ORDER_COMPLETE || preOrderCurrentStep.displayStatus.toLowerCase() === constants.ORDER_CANCELLED ) {
                timelineClass = "order-complete";
                statusIconStateClass = "order-complete-state";
            } else {
                if(preOrderCurrentStep.displayStatus.toLowerCase()===constants.HARDWARE_DELIVERY && !preOrderCurrentStep.shipmentDetails)
                {
                    continue;
                }
                timelineClass += " progress-step-middle";

            }
            preOrderStepsArray.push(
              <OrderTimelineTD key={counter} counter={counter} displayStatus={preOrderCurrentStep.displayStatus} progressStatus={preOrderCurrentStep.status} timelineItemClass={timelineClass} statusIconStateClass={statusIconStateClass} date={preOrderCurrentStep.date ? preOrderCurrentStep.date : null} shipmentDetails={preOrderCurrentStep.shipmentDetails} hardwareDelivery={hardwareDelivery}/>
            );
        }
        return preOrderStepsArray;
    }

    renderChangeOrderBannerMessage(isUnauthorized, isAuthenticated) {
        if(!isAuthenticated) {
            return(
                <div  className="change-form column small-11 medium-6 end">
                    <div id="Unauth-Change-Order-Title" className="change-order-title">
                        Change your Order?
                    </div>
                    <div id="Unauth-Change-Order-Text" className="change-order-login-text">
                        <a href="#" id="LoginOmniture" className="auth-label-change-order" onClick={(e)=> handleSignInSignOut(e, this.props.location, this.props.accessToken)}>Login</a> to make a change to the device or plan on this order.
                    </div>
                </div>
            );
        } else {
            if(isUnauthorized) {
                return(
                    <div  className="change-form column small-11 medium-6 end">
                        <div id="Auth-Unable-To-Change-Order-Title" className="change-order-title">
                            Unable to change your order?
                        </div>
                        <div id="Auth-Unable-to-Change-Order-Text" className="change-order-login-text">
                             This order number is not linked to your account OR you need to be the
                             authorized account holder to make changes.
                        </div>
                    </div>
                );
            } else {
                return(
                    <div  className="change-form column small-11 medium-7 end">
                        <div id="Auth-Change-Order-Title" className="change-order-title">
                            Change your Order?
                        </div>
                        <div id="Auth-Change-Order-Text" className="change-order-login-text">
                             Make a change to the device or plan on this order by <a href=" http://say.telstra.com.au/customer/general/forms/ChangeOrder" id="ChangeOrderOmniture"  target="_blank" className="auth-label-change-order">changing your order. </a>
                              To see if your are eligible to request a change, please review our <a href="https://www.telstra.com.au/support/category/mobiles-tablets/upgrading-your-device/mobiles-on-a-plan/how-do-i-change-an-online-order" target="_blank" className="auth-label-change-order" >FAQ</a> page before proceeding.
                        </div>
                    </div>
                );
            }
        }
    }
}

export default MobileOrderProgress;
