import React from "react";
import ElectricityIcon from "../../images/electricity.svg";
import GasIcon from "../../images/gas.svg";

const Icons = {
  'ELECTRICITYICON' : ElectricityIcon,
  'GASICON' : GasIcon,
}

export default function OrderDetailsHeader(props) {
  const {orderDescription, orderDateLabel, orderDate, orderStatus, orderNumber} = props.header;
  const headerIcon = props.headerIcon;

  return (
    <React.Fragment>
      <div className="order-details-header" id="order-details-header">
        { orderDescription && 
          (
            <div className="order-details-header__title responsive-width">
              {headerIcon && <img src={Icons[headerIcon]} className="energy-header-icon"></img>}
              <h1 role="heading" tabIndex="0">{orderDescription}</h1>
            </div>
          )
        }
        <div className="order-details-header__summary responsive-width">
          <div className="order-details-header__left">
            <div className="order-details-header__row" tabIndex="0"><span>Order status</span> <span>{orderStatus}</span></div>
            <div className="order-details-header__row" tabIndex="0"><span>Order number</span> <span>{orderNumber}</span></div>
            <div className="order-details-header__row" tabIndex="0"><span>{orderDateLabel}</span> <span>{orderDate}</span></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}