import React, { Component } from "react";
import { openInNewTab, isMobileDevice, isIpadDevice, openChat } from "../../utils";
import StatusIconsTD from "./StatusIconsTD";
import AddToCalendar from './AddToCalendar';
import * as constants from "../Constants";
import { isMobile } from 'react-device-detect';
import FileSaver from 'file-saver';
import moment from "moment";
import infoIcon from '../../images/icon-info-blue.svg'
import TimelineMilestone from './TimelineMilestonesTD'
import covidHeaderClose from "../../images/covid-header-close.svg";
import * as analytics from '../../analytics-utils';
import * as utils from '../../utils';
import ReactDOM from 'react-dom';
import { ActionButton } from "@able/react";

class TelstraAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = { showApptPopup: false };
        this.firstTime = true;
    }

    render() {
        const { counter, telstraAppointment, orderCategory } = this.props;
        return orderCategory.toLowerCase() === constants.FIXED ? this.renderFixedTelstraAppointment(telstraAppointment, counter) : this.renderTelstraAppointment(telstraAppointment, counter);
    }

    renderTelstraAppointment(telstraAppointment, counter){
        if(telstraAppointment) {
            return (<TimelineMilestone
                    createdAt={this.renderAppointmentDate()}
                    title={telstraAppointment.title ? telstraAppointment.title : constants.TELSTRA_PROFESSIONAL_INSTALL}
                    icon={this.renderStatusIcon()}
                    lineColor={telstraAppointment.iconStatus && telstraAppointment.iconStatus.toLowerCase() !== 'completed' ? '#d8d8d8' : '#0064D2'}>
                    {telstraAppointment.description  && !['internet', 'voice'].includes(telstraAppointment.description.toLowerCase()) ?
                        <div className="progress-details">
                            <span
                                id={"Telstra-Appointment-Description-" + counter}
                                className="appointment-description">
                                {telstraAppointment.description}
                            </span>
                        </div> : ''}
                    {telstraAppointment.showConnectionDetails && telstraAppointment.status.toLowerCase() !== constants.COMPLETED ?
                        <div>
                            <div id={"Telstra-Appointment-Required-" + counter} className="attendance-label">{constants.APPT_REQUIRED}</div>
                            {telstraAppointment.calendarEvent && this.renderAddToCalendar()}
                            {this.renderRescheduleAppointmentModule()}
                            {this.renderAppointmentComponents()}
                            {/*{this.renderAppointmentComponents(telstraAppointment.appointmentId, telstraAppointment.appointmentCategory)}*/}
                            <div style={{ display: telstraAppointment.professionalInstallLink ? 'block' : 'none' }} >
                                <ActionButton
                                    id={"Telstra-How-To-Prepare-Link-" + counter}
                                    variant="LowEmphasis"
                                    element="button"
                                    label={constants.INSTALLATION_MSG}
                                    onClick={(e) => this.onPIKClick(e)}
                                />
                            </div>
                        </div> : this.renderCableMessage()}
                    {telstraAppointment.status.toLowerCase() === constants.COMPLETED && <p />}
                </TimelineMilestone>
            )
        }
        return null;
    }

    renderFixedTelstraAppointment(telstraAppointment, counter){
        if(telstraAppointment) {
            return (<TimelineMilestone
                    createdAt={this.renderAppointmentDate()}
                    title={constants.TELSTRA_FIXED_APPT_TITLE}
                    icon={this.renderStatusIcon()}
                    lineColor={telstraAppointment.iconStatus && telstraAppointment.iconStatus.toLowerCase() !== 'completed' ? '#d8d8d8' : '#0064D2'}
                    createdAt={
                    <React.Fragment>
                        {telstraAppointment.date && <div className="milestone-date">{telstraAppointment.date}</div>}
                        {telstraAppointment.slot && telstraAppointment.status && telstraAppointment.status.toLowerCase() === constants.IN_PROGRESS && <div className="milestone-date">{telstraAppointment.slot}</div>}
                    </React.Fragment>} >
                    {telstraAppointment.displayStatus && <div id="display-status">{telstraAppointment.displayStatus}</div>}
                    {telstraAppointment.typeText && telstraAppointment.status && telstraAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                    <div id = "Telstra-Appointment-Type-Container">
                        <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                        <div id="Telstra-Appointment-Type" className="attendance-label appointment-type">{telstraAppointment.typeText}</div>
                    </div>}
                    {telstraAppointment.showConnectionDetails && telstraAppointment.status.toLowerCase() !== constants.COMPLETED ?
                        <div>
                            {this.renderRescheduleAppointmentModule()}
                            {telstraAppointment.calendarEvent && this.renderAddToCalendar()}
                            {telstraAppointment.status && telstraAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                                <div id="Telstra-Appointment-Static-Heading">{constants.WHAT_TO_EXPECT_FIXED_TELSTRA_APPT_LABEL}</div>}
                            {telstraAppointment.status && telstraAppointment.status.toLowerCase() === constants.IN_PROGRESS &&
                                <div id="Telstra-Appointment-Static-Body">{constants.WHAT_TO_EXPECT_FIXED_TELSTRA_APPT_TEXT}</div>}
                            {this.renderAppointmentComponents()}
                            <div>
                                <ActionButton
                                    id={"Telstra-How-To-Prepare-Link-" + counter}
                                    variant="LowEmphasis"
                                    element="button"
                                    label="Learn more"
                                    onClick={(e) => this.onPIKClick(e)}
                                />
                            </div>
                        </div> : this.renderCableMessage()}
                    {telstraAppointment.status.toLowerCase() === constants.COMPLETED && <p />}
                </TimelineMilestone>
            )
        }
        return null;
    }

    onPIKClick(e) {
        const { telstraAppointment, orderCategory } = this.props;
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.PIK_CLICK_ANALYTICS);
        const learn_more_link = "https://www.telstra.com.au/support/category/broadband/other/about-the-technician-installing-your-telstra-home-broadband";
        if (orderCategory.toLowerCase() === constants.FIXED) {
            openInNewTab(e, learn_more_link);
        } else {
             openInNewTab(e, telstraAppointment.professionalInstallLink);
        }
    }

    renderAppointmentDate() {
        const { counter, telstraAppointment } = this.props;
        if (telstraAppointment.date) {
            return telstraAppointment.slot && telstraAppointment.status.toLowerCase() !== 'completed' ? telstraAppointment.slot.toLowerCase() + ' ' + telstraAppointment.date : telstraAppointment.date
        }
        else if (
            !telstraAppointment.date &&
            telstraAppointment.type &&
            telstraAppointment.status.toLowerCase() !== 'completed' &&
            telstraAppointment.type.toLowerCase() !== 'cable'
        ) {
            return constants.CONNECTION_DATE_UNAVAILABLE
        } else {
            return ''
        }
    }

    renderCableMessage() {
        const { counter, telstraAppointment } = this.props;
        return telstraAppointment.showCableMessage && (
            <p>Just connect your modem to activate your service</p>
        )
    }

    renderRescheduleAppointmentModule() {
        //TODO: Remove else block once OSTK-138 feature is fully enabled
        if(utils.isApptMgrEnabledForNBNCategory()) {
            const { telstraAppointment, orderCategory } = this.props;
            const { orderDetails: { orderSummary: { enableManageAppointments = false } } } = this.props;
            if ((orderCategory.toLowerCase() === constants.HEADLESS ||
                orderCategory.toLowerCase() === constants.HEADLESS_PREORDER ||
                orderCategory.toLowerCase() === constants.NEXTGEN_OSM ||
                orderCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN) &&
                enableManageAppointments) {
                return this.renderAppointmentsForHeadless();
            } else if (telstraAppointment.showConnectionDetails) {
                return this.renderTAMRescheduleLink();
            }
        }
        else {
            const {telstraAppointment, orderCategory} = this.props;
            if ((orderCategory.toLowerCase() === constants.HEADLESS ||
                orderCategory.toLowerCase() === constants.HEADLESS_PREORDER ||
                orderCategory.toLowerCase() === constants.NEXTGEN_OSM) &&
                telstraAppointment.status &&
                (telstraAppointment.status.toLowerCase() !== constants.COMPLETED &&
                    telstraAppointment.status.toLowerCase() !== constants.CANCELLED)) {
                return this.renderAppointmentsForHeadless();
            } else {
                return this.renderTAMRescheduleLink();
            }
        }
    }


    renderAppointmentsForHeadless() {

        const { telstraAppointment, orderId } = this.props;
        let appointmentType = telstraAppointment.appointmentCategory ? telstraAppointment.appointmentCategory : null;
        let appointmentId = telstraAppointment.appointmentId ? telstraAppointment.appointmentId : null;

        localStorage.setItem('telstraAppointmentIdOst', appointmentId);
        localStorage.setItem('orderIdOst', orderId)
        if (window.env.REACT_APP_DEV_ENV === 'true') {
            return this.renderWithoutLogin();
        } else {
            return this.renderAppointmentRescheduleLink(appointmentId, appointmentType);
        }
    }


    renderWithoutLogin() {
        return (
            <>
                {(isMobileDevice() || isIpadDevice()) ? (
                    <ActionButton
                        variant="LowEmphasis"
                        element="button"
                        label="Reschedule appointment"
                        onClick={(event) => this.showApptDetailsMobile()}
                    />
                ) : (
                    <ActionButton
                        variant="LowEmphasis"
                        element="button"
                        label="Reschedule appointment"
                        onClick={this.togglePopup.bind(this)}
                    />
                )}
            </>
        )
    }


    confirmAlert() {
        if (window.confirm("You haven’t finished rescheduling your appointment. Are you sure you want to leave?")) {
            this.togglePopup();
        }
    }

    togglePopup() {
        this.props.closeLightbox();
        this.setState({ showApptPopup: !this.state.showApptPopup });
    }

    renderTAMRescheduleLink() {
        const { counter, telstraAppointment, orderId, isUnauthorized, isAuthenticated, contactUsUrl, orderDetails, orderCategory } = this.props;
        return !isUnauthorized && telstraAppointment.reschedulable ?
            <div className="appointment-box-td flex-row message-flex">
                {orderCategory.toLowerCase() === !constants.FIXED && <img src={infoIcon} className="small-icon-td" alt="Info Icon" />}
                <span>
                    {orderCategory.toLowerCase() === !constants.FIXED && <p style={{ margin: 0 }}>{constants.RESCHEDULE_APPT_MSG}</p>}
                    <ActionButton
                        variant="LowEmphasis"
                        onClick={(e) => this.onTAMRescheduleApptClick(e)}
                        element="button"
                        label={constants.RESCHEDULE_APPT}
                    />
                </span>
            </div>
            :
            <div className="appointment-box-td reschedule-message">
                <div id={"Telstra-Appointment-Unable-To-Reschedule-Text-" + counter} className="progress-label">Unable to reschedule</div>
                <div id={"Telstra-Appointment-Unable-To-Reschedule-Message-" + counter} className="reschedule-message-content">
                    {isUnauthorized && telstraAppointment.reschedulable ? (
                        <div>
                            <div>This order number is not linked to your account OR you are not authorised to make changes to this order. </div>
                            <div> Please contact us using the link below for more information.</div>
                            <ActionButton
                                variant="LowEmphasis"
                                onClick={(e) => this.onTAMRescheduleApptClickError(e, contactUsUrl)}
                                label="Contact us"
                                element="button"
                            />
                        </div>
                    ) : (
                        orderDetails.orderSummary.orderCategory === "NBN" && orderDetails.orderSummary.planName.includes("Move") ? (
                            <span style={{ display: 'flex', marginBottom: '0.75rem' }}>
                                    <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                                    <p style={{ margin: 0 }}>
                                        Unfortunately we’re unable to reschedule your appointment online.
                                        Please call us on <a href="tel:1800834273">1800 834 273</a> and
                                        follow the Check Order prompt. We’re available from 7am to 9pm
                                        AEST.
                                    </p>
                                </span>
                        ) : (
                            <span style={{ display: 'flex', marginBottom: '0.75rem'}}>
                                <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                                <div>
                                    <p style={{ margin: 0 }}>To change your appointment, please contact us using the link below</p>
                                    {isMobileDevice() || isIpadDevice() ? (<ActionButton
                                        variant="LowEmphasis"
                                        label="Message us"
                                        element="button"
                                        onClick={(e) => this.onTAMRescheduleApptClickError(e, contactUsUrl)}
                                    />) :
                                    (<ActionButton
                                        variant="LowEmphasis"
                                        onClick={() => openChat('message us', ["telstra", "dtq", "gbs", "nbn", "activation", "ost"], orderDetails)}
                                        label="Message us"
                                        element="button"
                                    />)}
                                </div>
                            </span>
                        )
                    )
                    }
                </div>
            </div>
    }


    onTAMRescheduleApptClick(e) {
        const { orderId } = this.props;
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.TAM_CLICK_ANALYTICS);
        openInNewTab(e, 'https://www.telstra.com.au/appointments#/?orderid=' + orderId);
    }

    onTAMRescheduleApptClickError(e, contactUsUrl) {
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.MESSAGE_US_ANALYTICS);
        openInNewTab(e, contactUsUrl);
    }

    renderAppointmentRescheduleLink(appointmentId, appointmentType) {
        const { telstraAppointment, isUnauthorized, isAuthenticated } = this.props;
        localStorage.setItem('telstraAccessToken', this.props.accessToken);

        return this.renderAppointmentLinksAndMessages();

    }

    renderAppointmentLinksAndMessages() {
        const { isAuthenticated } = this.props;
        if (isAuthenticated) {
            return this.renderAuthenticatedLinksAndMessages();
        } else {
            return this.renderUnauthenticatedLinksAndMessages();
        }
    }


    renderAuthenticatedLinksAndMessages() {
        const { telstraAppointment, isUnauthorized, contactUsUrl, orderDetails, orderCategory } = this.props;
        if (!isUnauthorized && telstraAppointment.reschedulable) {
            return (
                <div>
                    {(window.env.REACT_APP_SHOW_RESCHEDULE_APPT === 'false') ? (
                        <div className="appointment-box-td flex-row">
                            <div id="Reschedule-Appointment-Message-Headless" className="appointment-info progress-details">
                                {constants.HEADLESS_RESCHEDULE_APPT}
                            </div>
                        </div>
                    ) : (
                        <div>
                            {(isMobileDevice() || isIpadDevice()) ? (
                                <ActionButton
                                    variant="LowEmphasis"
                                    onClick={(event) => this.showApptDetailsMobile()}
                                    label="Reschedule appointment"
                                    element="button"
                                />
                            ) : (
                                <ActionButton
                                    variant="LowEmphasis"
                                    onClick={this.togglePopup.bind(this)}
                                    label="Reschedule appointment"
                                    element="button"
                                />
                            )}
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div className="appointment-box-td reschedule-message">
                    <div id="Unable-To-Reschedule-Appointment-Label" className="progress-label">{constants.UNABLE_TO_RESCHEDULE}</div>
                    <div id="Unable-To-Reschedule-Appointment-Message" style={{ display: 'flex', marginBottom: '0.75rem' }}>
                        <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                        {isUnauthorized ? (
                            <p style={{ margin: 0 }}>
                                This order number is not linked to your account OR you need to be the authorised account holder to make changes, please call <a href="tel:1800931877">1800 931 877</a> and follow the nbn activation prompt. As our contact centres are currently operating on reduced hours, we’re only available from 11:00am – 3:00pm AEST Monday to Friday.
                            </p>
                        ) : (orderCategory && orderCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN && telstraAppointment.PONRReached ? (
                                <div style={{ margin: 0 }}>
                                    <div> To change your appointment please contact us using the link below</div>
                                        {isMobileDevice() || isIpadDevice() ? (<ActionButton
                                            variant="LowEmphasis"
                                            label="Message us"
                                            element="button"
                                            onClick={(e) => openInNewTab(e, contactUsUrl)}
                                        />) :
                                        (<ActionButton
                                            variant="LowEmphasis"
                                            label="Message us"
                                            element="button"
                                            onClick={() => openChat('message us', ["telstra", "dtq", "gbs", "nbn", "activation", "ost"], orderDetails)}
                                        />)}
                                </div>
                            ) : (telstraAppointment.reschedulable == false || telstraAppointment.reschedulable == null) ? (
                                <p style={{ margin: 0 }}>
                                    To reschedule, please call <a href="tel:1800931877">1800 931 877</a> and follow the nbn activation prompt. As our contact centres are currently operating on reduced hours, we’re only available from 11:00am – 3:00pm AEST Monday to Friday.
                                </p>
                            ) : (
                                <p style={{ margin: 0 }}>
                                    To reschedule, please call <a href="tel:1800931877">1800 931 877</a> and follow the nbn activation prompt. As our contact centres are currently operating on reduced hours, we’re only available from 11:00am – 3:00pm AEST Monday to Friday.
                                </p>
                            )
                        )}
                    </div>
                </div>
            )
        }
    }


    renderUnauthenticatedLinksAndMessages() {
        const { telstraAppointment, loginEligibility, orderCategory } = this.props;
        let showRescheduleLink = true;
        if (orderCategory.toLowerCase() === constants.HEADLESS || orderCategory.toLowerCase() === constants.HEADLESS_PREORDER || orderCategory.toLowerCase() === constants.NEXTGEN_OSM) {
            showRescheduleLink = loginEligibility;
        }

        return (
            <div>
                {!telstraAppointment.reschedulable || window.env.REACT_APP_SHOW_RESCHEDULE_APPT === 'false' ?
                    this.returnErrorMessageForUnauthenticatedFlow()
                    :
                    showRescheduleLink ? (
                        <ActionButton
                            variant="LowEmphasis"
                            label="Reschedule appointment"
                            element="button"
                            onClick={(e) => utils.handleSignInSignOut(e,this.props.location.pathname)}
                        />
                    ) : this.returnErrorMessageForUnauthenticatedFlow()
                }
            </div>
        )
    }


    returnErrorMessageForUnauthenticatedFlow() {
        const { orderCategory, telstraAppointment, contactUsUrl, orderDetails }= this.props;
        return (
            <div className="appointment-box-td reschedule-message">
                <div id="Unable-To-Reschedule-Appointment-Label" className="progress-label">{constants.UNABLE_TO_RESCHEDULE}</div>
                <div id="Unable-To-Reschedule-Appointment-Message" className="reschedule-message-content message-flex">
                    {orderCategory && orderCategory.toLowerCase() === constants.ORDER_CATEGORY_NBN && telstraAppointment.PONRReached ?
                    (<span style={{ display: 'flex', marginBottom: '0.75rem'}}>
                        <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                            <p style={{ margin: 0 }}>
                                To change your appointment, please contact us using the link below
                            </p>
                            {isMobileDevice() || isIpadDevice() ? (<ActionButton
                                variant="LowEmphasis"
                                label="Message us"
                                element="button"
                                onClick={(e) => openInNewTab(e, contactUsUrl)}
                            />) :
                            (<ActionButton
                                variant="LowEmphasis"
                                label="Message us"
                                element="button"
                                onClick={() => openChat('message us', ["telstra", "dtq", "gbs", "nbn", "activation", "ost"], orderDetails)}
                            />)}
                    </span>)
                    : (<span>
                        <img src={infoIcon} className="small-icon-td" alt="Info Icon" />
                        To reschedule, please call <a href="tel:1800931877">1800 931 877</a> and follow the nbn activation prompt. As our contact centres are currently operating on reduced hours, we’re only available from 11:00am – 3:00pm AEST Monday to Friday.
                </span>)}
                </div>
            </div>
        )
    }


    getNodes(equalizerComponent, equalizerElement) {
        return [
            this.refs.node1,
            this.refs.node2
        ]
    }

    renderStatusIcon() {
        const { counter, telstraAppointment, reasonForUnHealthyData } = this.props;

        let customAction;
        if (reasonForUnHealthyData && reasonForUnHealthyData.unhealthyCustomAction) {
            customAction = reasonForUnHealthyData.unhealthyCustomAction;
        }

        return telstraAppointment.iconStatus &&
            <StatusIconsTD
                uniqueIdentifier={"Telstra-Appointment-" + counter}
                status={telstraAppointment.iconStatus}
                customAction={customAction} />
    }

    renderAddToCalendar() {
        const { counter, telstraAppointment } = this.props;
        let calendarForMobile = null;
        var startTime = moment(telstraAppointment.calendarEvent.startTime).format('YYYYMMDDThhmmssZ');
        var endTime = moment(telstraAppointment.calendarEvent.endTime).format('YYYYMMDDThhmmssZ');

        let icon = { textOnly: 'none' };

        if (isMobile) {
            return telstraAppointment.calendarEvent && (
                <div id={"Telstra-Add-to-Calander-" + counter} className="flex-row add-to-calender-td">
                    <a href='#' onClick={(e) => {
                        var icsCalendar = "BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nURL:" + e + "\nDTSTART:" + startTime + "Z" + "\nDTEND:" + endTime + "Z" + "\nLOCATION:" + telstraAppointment.calendarEvent.location + "\nSUMMARY:" + telstraAppointment.calendarEvent.title + "\nDESCRIPTION:" + telstraAppointment.calendarEvent.description + "\nEND:VEVENT\nEND:VCALENDAR";
                        var blob = new Blob([icsCalendar], { type: 'text/plain;charset=utf-8' })
                        FileSaver.saveAs(blob, 'Appointment.ics')
                    }}>
                        <AddToCalendar listItems={calendarForMobile} />
                    </a>
                </div>
            )
        }

        return telstraAppointment.calendarEvent && (
            <div id={"Telstra-Add-to-Calander-" + counter} className="flex-row add-to-calender-td">
                <AddToCalendar event={telstraAppointment.calendarEvent} buttonLabel={constants.CALENDAR_LABEL} buttonTemplate={icon} />
            </div>
        )
    }

    renderAppointmentComponents() {
        const {orderId, planName} = this.props;
        let hardwareDeliveryDate = this.props.hardwareDeliveryDate;
        let iframeUrl = window.env.REACT_APP_API_APPT_URL + "/appointments?orderId=" + orderId + "&callbackURL=ORDER_STATUS_TRACKER&planName=" +planName;

        if (this.state.showApptPopup) {
            analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.MANAGE_YOUR_APPOINTMENTS_ANALYTICS);
            if(this.props.isAuthenticated) {
                localStorage.setItem('hardwareDeliveryDate', hardwareDeliveryDate);
                hardwareDeliveryDate = localStorage.getItem('hardwareDeliveryDate');
                return ReactDOM.createPortal(
                    <div className="iframe-container">
                        <div className="iframe-parent">
                            <iframe
                                id="IframePopup"
                                className="iframe-popup-td"
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                src={iframeUrl}/>
                        </div>
                        <div id="closeBtn" className="close-btn-td" onClick={this.confirmAlert.bind(this)}>
                            <img className="close-appt-manager-btn" src={covidHeaderClose}
                                 alt="COVID 19 MESSAGE"/>
                        </div>
                        <div id="popupBack" className="popup-td" onClick={this.confirmAlert.bind(this)}/>
                    </div>,
                    document.getElementById('iframe-portal')
                )
            } else {
                let redirectUrl = window.env.REACT_APP_HOST_NAME + '/orderdetails';
                utils.goToSignIn('', redirectUrl, false);
            }
        } else {
            return null;
        }
    }

    showApptDetailsMobile() {
        const {orderId} = this.props;
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.MANAGE_YOUR_APPOINTMENTS_ANALYTICS);
        window.location.href = window.env.REACT_APP_API_APPT_URL + "/appointments?orderId="+ orderId + "&callbackURL=" +  process.env.REACT_APP_API_APPT_CALLBACK_URL;
    }

}

export default TelstraAppointment;
