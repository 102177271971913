import React from "react";
import IllustrationTD from '../../presenters/components/OrderDetailsIllustrationTD';
import defaultDesktop from '../../images/illustrations/default_desktop.svg';
import defaultTablet from '../../images/illustrations/default_tab.svg';
import defaultMobile from '../../images/illustrations/default_mobile.svg';
import { ILLUSTRATION_DEFAULT_ALT_TEXT } from "../../presenters/Constants";


export default function Illustration({orderCategory, illustration}) {
  return (
    <div id="page-illustration">
      <IllustrationTD orderDetails={transformProp(orderCategory, illustration)} defaultImages={{
          desktop: defaultDesktop,
          tabPort: defaultTablet,
          tabLand: defaultTablet,
          mobile: defaultMobile,        
      }} defaultAltText={ILLUSTRATION_DEFAULT_ALT_TEXT} />
    </div>
  );
}

function transformProp(orderCategory, illustration) {
  const orderDetails = {
    orderCategory: orderCategory,
    orderSummary: {
      illustration: illustration
    }
  };
  return orderDetails;
}