import React, { useEffect } from 'react';
import GlobalFooter from './GlobalFooter/GlobalFooter';


export default () => {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    const str = '<a href="https://www.telstra.com.au/thanks">Telstra Thanks Rewards</a>'
    const Obj = document.querySelector('[href="https://www.telstra.com.au/thanks"]');

    if(Obj !== null && Obj.outerHTML) {
      Obj.outerHTML = str;
    }

    const listItem = document.createElement("li");
    listItem.setAttribute('role', 'menuitem')
    listItem.setAttribute('itemprop', 'url')

    /*const anchorElement = document.createElement('a')
    anchorElement.setAttribute('href', 'https://www.telstra.com.au/aboutus/community-environment/community-programs/adversity-financial-hardship')
    anchorElement.textContent = "Financial hardship"

    listItem.appendChild(anchorElement)*/

    const parentUL = document.querySelector("#heading-help + ul")

    if(parentUL !== null && typeof parentUL !== 'undefined')
      parentUL.appendChild(listItem)

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])


  const handleScroll = () => {
    const dockButtonContainer = document.querySelector('.footer-dock');
    const footerHeight = document.querySelector('.globalFooter') !== null ? document.querySelector('.globalFooter').offsetHeight : 513;
    if ((window.innerHeight + window.scrollY >= document.body.scrollHeight - footerHeight) && dockButtonContainer != null) {
      dockButtonContainer.classList.add('docked');
      dockButtonContainer.style.opacity = 1;
    } else if (window.scrollY <= 10 && dockButtonContainer != null) {
      dockButtonContainer.style.opacity = 0;
      setTimeout(() => {
        dockButtonContainer.classList.remove('docked');
      }, 350);
    }
  }

  return <GlobalFooter/>;
}
