import React, {Component} from "react";


class Callback extends Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div></div>
        );
    }
}

export default Callback;