import React from "react";
import Breadcrums from './Breadcrums';
import BannerMessage from './BannerMessage';
import OrderDetailsHeader from './OrderDetailsHeader';
import TabsGroups from './TimelineGroupTabs';
import GroupTimeline from './GroupTimeline';
import StripBannerFooter from './StripBannerFooter';


export default class OrderDetailsBody extends React.Component {
    

    constructor(props) {
        super(props);
        this.analyticsObj = null;
    }

    render() {
        const orderDetails = this.props.orderDetails;
        const { banner, page, header, timelineGroups, footers } = orderDetails;
        const activeTimelineGroup = timelineGroups?.find(timeline => timeline.isSelected);
        const headerIcon = (timelineGroups.length == 1) ? (activeTimelineGroup?.timelineGroupIcon) : '';
        this.analyticsObj = this.props.analyticsObj;

        return (
            <div className="inner-container inner-container--edgar">
                <Breadcrums />

                {banner && <BannerMessage bannerMessage={banner} orderDetails={orderDetails} accessToken={this.props.accessToken} page={page} analyticsObj={this.analyticsObj}/>}

                {header && <OrderDetailsHeader header={header} headerIcon={headerIcon} loggedIn={this.props.accessToken ? true : false}/>}

                {timelineGroups && <TabsGroups tabsGroups={timelineGroups} handleTabChange={this.props.handleTabChange}/>}

                {activeTimelineGroup && <GroupTimeline accessToken={this.props.accessToken} location={this.props.location} timelineGroup={activeTimelineGroup} page={page} header={header} orderDetails={orderDetails} />}

                {footers && <StripBannerFooter footers={footers}/>}
            </div>
        );
    }
}