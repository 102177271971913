import React, { Component } from "react";
import { openInNewTab, isMobileDevice, isIpadDevice } from "../../utils";
import StatusIconTD from "./StatusIconsTD";
import AddToCalendar from './AddToCalendar';
import * as constants from "../Constants";
import { isMobile } from 'react-device-detect';
import FileSaver from 'file-saver';
import covidHeaderClose from "../../images/covid-header-close.svg";
import TimelineMilestone from './TimelineMilestonesTD'
import infoIcon from '../../images/icon-info-blue.svg';
import errorIcon from '../../images/icon-error.svg';
import * as analytics from '../../analytics-utils';
import * as utils from '../../utils';
import ReactDOM from 'react-dom';

class ASIKAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = { showApptPopup: false };
    this.isReschedulable = true;
  }

  render() {
    const { telstraAppointment } = this.props;

    if(telstraAppointment) {
      return (
        <TimelineMilestone
          title={telstraAppointment.title || constants.ASIK_DETAILS_TITLE}
          createdAt={this.getDateAndSlot(telstraAppointment)}
          lineColor={this.getLineColor(telstraAppointment.iconStatus)}
          icon={this.renderStatusIcon()}
        >
          {
            telstraAppointment.showConnectionDetails &&
            <div id="NBN-Appointment-Type" className="attendance-label appointment-type">
              {telstraAppointment.typeText}
            </div>
          }
          {
            telstraAppointment.showConnectionDetails &&
            telstraAppointment.calendarEvent &&
            this.renderAddToCalendar(telstraAppointment.calendarEvent)
          }
          {this.renderReschedulableMessage(
            telstraAppointment.rescheduleMessage,
            telstraAppointment.subLabelIcon
          )}
        </TimelineMilestone>
      )
    }
    return null;
  }

  renderReschedulableMessage = (message, type) => {
    if (!message) return null;
    let icon = infoIcon;
    if (type && type.toLowerCase() === 'alert') {
      icon = errorIcon;
    }
    return (
      <div className="appointment-box-td reschedule-message">
        <span style={{ display: 'flex', marginBottom: '0.75rem'}}>
          <img src={icon} className="small-icon-td" alt="Info Icon" />
          <p style={{ margin: 0 }}>
            {message}
          </p>
      </span>
      </div>
    )
  }

  getDateAndSlot = (telstraAppointment) => {
    if (telstraAppointment.date === null && telstraAppointment.slot === null) return '';
    else if (telstraAppointment.slot === null) return telstraAppointment.date;

    return telstraAppointment.slot.toLowerCase() +' '+  telstraAppointment.date;
  }

  getLineColor = (iconStatus) => {
    return iconStatus && iconStatus.toLowerCase() !== 'completed'  ? '#d8d8d8': '#0064D2'
  }

  onClickOfHowToPrepareForYourNbnAppt(e) {
      const {telstraAppointment} = this.props;
      analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, constants.HOW_TO_PREPARE_NBN_ANALYTICS);
      openInNewTab(e, telstraAppointment.nbnAppointmentLink);
  }
  confirmAlert() {
      if (window.confirm("You haven’t finished rescheduling your appointment. Are you sure you want to leave?")) {
          this.togglePopup();
      }
  }

  togglePopup() {
      this.props.closeLightbox();
      this.setState({ showApptPopup: !this.state.showApptPopup });
  }

  renderStatusIcon() {
      const { telstraAppointment, reasonForUnHealthyData } = this.props;
      let customAction;
      if (reasonForUnHealthyData && reasonForUnHealthyData.unhealthyCustomAction) {
          customAction = reasonForUnHealthyData.unhealthyCustomAction;
      }
      return telstraAppointment.iconStatus ?
          <StatusIconTD uniqueIdentifier="ASIK-Appointment" status={telstraAppointment.iconStatus} customAction={customAction}/> : '';
  }


  renderAddToCalendar(event) {
      let icon = { textOnly: 'none' };
      let calendarForMobile = null;

      if (isMobile) {
          return (
              <div id="Add-To-Calender" className="flex-row add-to-calender-td">
                  <a href="javascript:window.location.reload(false)" onClick={(e) => {
                      var icsCalendar = "BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nURL:" + e + "\nDTSTART:" + event.startTime + "Z" + "\nDTEND:" + event.endTime + "Z" + "\nLOCATION:" + event.location + "\nSUMMARY:" + event.title + "\nDESCRIPTION:" + event.description + "\nEND:VEVENT\nEND:VCALENDAR";
                      var blob = new Blob([icsCalendar], { type: 'text/plain;charset=utf-8' })
                      FileSaver.saveAs(blob, 'Appointment.ics')
                  }}>
                      <AddToCalendar listItems={calendarForMobile} />
                  </a>
              </div>
          );
      }
      return (
          <div id="Add-To-Calender" className="flex-row add-to-calender-td">
              <AddToCalendar event={event} buttonLabel="Add to your calendar" buttonTemplate={icon} />
          </div>
      );
  }
}

export default ASIKAppointment;
