import * as apiFetch from "./apiFetch";


export function fetchAuthOrderDetails(dispatch, receiveOrderDetails, receiveOrderDetailsError, orderNumber, accessToken, bypassCache, overrideAppointment, fetcher = apiFetch) {
    fetcher.fetchFromAuthApi(dispatch, '/v1/order-tracker/orders/' + orderNumber, receiveOrderDetails, receiveOrderDetailsError, accessToken, bypassCache, overrideAppointment);
    //dispatch(receiveOrderDetailsError(403));
}

export function fetchUnauthOrderDetails(dispatch, receiveOrderDetails, receiveOrderDetailsError, orderNumber, fetcher = apiFetch) {

    fetcher.fetchFromUnauthApi(dispatch, '/v1/order-tracker/order-summary/' + orderNumber, receiveOrderDetails, receiveOrderDetailsError);
    //dispatch(receiveOrderDetailsError(500));
}

export function fetchOrders(dispatch, receiveOrders, receiveOrdersError, accessToken, fetcher = apiFetch) {
    //fetcher.fetchFromAuthApi(dispatch, '/v1/order-tracker/orders', receiveOrders, receiveOrdersError, accessToken, false);
    fetcher.fetchDashboardFromAuthApi(dispatch, '/v1/order-tracker/orders', receiveOrders, receiveOrdersError, accessToken);
    //dispatch(receiveOrdersError());
}

export function fetchOrder(dispatch, receiveOrder, receiveOrderError, siebelOrderNumber, fetcher = apiFetch) {
    fetcher.fetchFromUnauthApi(dispatch, '/v1/order-tracker/order-summary/' + siebelOrderNumber, receiveOrder, receiveOrderError);
    //dispatch(receiveOrderError(500));
}