import React, {Component} from "react";
import * as utils from "../../utils";
import {isIpadDevice, isMobileDevice, openInNewTab, addClickEventToOmnitureObject} from "../../utils";
import * as constants from "../Constants";
import headerClose from "../../images/covid-header-close.svg";
import {ActionButton} from '@able/react';
import * as analytics from '../../analytics-utils';
import ReactDOM from 'react-dom';

class LinkBehaviour extends Component {
    constructor(props) {
        super(props);
        this.state = { showApptPopup: false };
    }

    componentDidMount() {
        window.addEventListener('message', this.closeAppointmentIframe);
    }

    closeAppointmentIframe = (e) => {
          let listenerMsg = e.data;
          if(typeof listenerMsg === "string" && listenerMsg.toLowerCase() === constants.APPT_RESCHEDULED_LISTENER){
           this.setState({ showApptPopup: false });
          }
    };


    render() {
            return (
                <div>
                    {this.renderLinks()}
                    {this.renderAppointmentComponents()}
                </div>
            )
    }

    confirmAlert() {
        if (window.confirm("You haven’t finished rescheduling your appointment. Are you sure you want to leave?")) {
            this.togglePopup();
        }
    }

    togglePopup() {
        this.setState({ showApptPopup: !this.state.showApptPopup });
    }

    renderLinks() {
        if((isMobileDevice() || isIpadDevice())) {
            if(this.props.linkBehaviourMobile === 'SAME_TAB') {
                return (<ActionButton element="button" variant="LowEmphasis" onClick={() => this.openLinkInSameTab()} label={this.props.linkLabelText} />);
            } else {
                return (
                <div className="timeline-section">
                    <ActionButton
                        element="button"
                        variant="LowEmphasis"
                        onClick={e => {
                            openInNewTab(e, this.props.linkLabelURL)
                            addClickEventToOmnitureObject(this.props.linkLabelText)
                        }}
                        label={this.props.linkLabelText}
                        tabIndex="0"
                    />
                </div>);
            }
        } else {
            if(this.props.linkBehaviourDesktop === 'SAME_TAB') {
                return (<ActionButton element="button" variant="LowEmphasis" onClick={() => this.openLinkInSameTab()} label={this.props.linkLabelText} />);
            } else if(this.props.linkBehaviourDesktop === 'AUTH_I_FRAME') {
                if(this.props.isAuthenticated) {
                    return (<ActionButton element="button" variant="LowEmphasis" onClick={this.togglePopup.bind(this)}
                                          label={this.props.linkLabelText}/>);
                } else {
                    return this.renderUnauthenticatedLinks();
                }
            } else {
                return (
                    <div className="timeline-section">
                        <ActionButton
                            element="button"
                            variant="LowEmphasis"
                            onClick={e => {
                                openInNewTab(e, this.props.linkLabelURL)
                                addClickEventToOmnitureObject(this.props.linkLabelText)
                            }}
                            label={this.props.linkLabelText}
                            tabIndex="0"
                        />
                    </div>);
            }
        }
    }

    renderUnauthenticatedLinks() {
        return(
            <ActionButton
                variant="LowEmphasis"
                id="Reschedule-Appt-For-Dev-Orders"
                onClick={(e) => this.subscribeBeforeLogin(e)}
                element="button"
                label={this.props.linkLabelText}
            />
        );
    }

    subscribeBeforeLogin(e) {
        utils.handleSignInSignOut(e, this.props.location);
    }

    renderAppointmentComponents() {
        let iframeUrl = this.props.linkLabelURL + "&callbackURL=ORDER_STATUS_TRACKER";
        if (this.state.showApptPopup) {
            analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, this.props.linkLabelText);
            if(this.props.isAuthenticated) {
                return ReactDOM.createPortal(
                    <div className="iframe-container">
                        <div className="iframe-parent">
                            <iframe
                                id="IframePopup"
                                className="iframe-popup-td"
                                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                src={iframeUrl}/>
                        </div>
                        <div id="closeBtn" className="close-btn-td" onClick={this.confirmAlert.bind(this)}>
                            <img className="close-appt-manager-btn" src={headerClose}
                                 alt="close"/>
                        </div>
                        <div id="popupBack" className="popup-td" onClick={this.confirmAlert.bind(this)}/>
                    </div>,
                    document.getElementById('iframe-portal')
                )
            } else {
                let redirectUrl = window.env.REACT_APP_HOST_NAME + '/orderdetails';
                utils.goToSignIn('', redirectUrl, false);
            }
        } else {
            return null;
        }
    }

    openLinkInSameTab() {
        analytics.addOmnitureObjectForEventClick(constants.ORDER_DETAILS_PAGE_NAME_ANALYTICS, this.props.linkLabelText);
        window.location.href = this.props.linkLabelURL + "&callbackURL=" + process.env.REACT_APP_API_APPT_CALLBACK_URL;
    }


}

export default LinkBehaviour;
