import React from "react";
import Illustration from "./Illustration";

export default function OrderDetailsTop({orderDetails}) {
  const { illustration } = orderDetails?.page;
  const { orderCategory } = orderDetails?.meta;
  return (
    <div>
      {illustration && <Illustration  orderCategory={orderCategory} illustration={illustration}/>}
    </div>
  );
}