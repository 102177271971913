import * as client from "../adapters/client";
import * as constants from "../presenters/Constants";
import { RETRIEVE_ERRORS } from '../constants';
import {isDynamicTimelineEnabled, isEdgarEnabled} from "../utils";
export const REQUEST_ORDER_DETAILS = 'REQUEST_ORDER_DETAILS';
export const RECEIVE_ORDER_DETAILS = 'RECEIVE_ORDER_DETAILS';
export const RECEIVE_ORDER_DETAILS_ENERGY = 'RECEIVE_ORDER_DETAILS_ENERGY';
export const RECEIVE_ORDER_DETAILS_DYNAMIC = 'RECEIVE_ORDER_DETAILS_DYNAMIC';
export const RECEIVE_ORDER_DETAILS_ERROR = 'RECEIVE_ORDER_DETAILS_ERROR';
export const RECEIVE_ORDER_DETAILS_UNSUPPORTED = 'RECEIVE_ORDER_DETAILS_UNSUPPORTED';
export const RECEIVE_ORDER_DETAILS_TOKEN_EXPIRED = 'RECEIVE_ORDER_DETAILS_TOKEN_EXPIRED';
export const RECEIVE_ORDER_DETAILS_UNAUTHORIZED = 'RECEIVE_ORDER_DETAILS_UNAUTHORIZED';
export const AUTH_TOKEN_AVAILABLE = 'AUTH_TOKEN_AVAILABLE';
export const AUTH_TOKEN_UNAVAILABLE = 'AUTH_TOKEN_UNAVAILABLE';

export function requestOrderDetails() {
    return {
        type: REQUEST_ORDER_DETAILS
    }
}

function isEnergyOrder(response) {
  if(response.data?.meta && response.data.meta?.orderCategory === 'Energy') {
    return true;
  }
  return false;
}

function isDynamicOrder(response) {
    if(response.data?.timelineGroupsV2) {
      return true;
    }
    return false;
  }

export function receiveOrderDetails(json) {

    if(isDynamicTimelineEnabled() && isDynamicOrder(json)) {
        return {
            type: RECEIVE_ORDER_DETAILS_DYNAMIC,
            payload: json.data
        }
    }
    // Check for Energy orders and create corresponding action.
    if(isEdgarEnabled() && isEnergyOrder(json)) {
        return {
            type: RECEIVE_ORDER_DETAILS_ENERGY,
            payload: json.data
        }
    }

    let actionObj = {
        type: RECEIVE_ORDER_DETAILS,
        orderSummary: {
            orderId: json.data.orderId,
            externalOrderId: json.data.externalOrderId,
            orderCategory: json.data.orderCategory,
            technologyType: json.data.technologyType ? json.data.technologyType: null,
            orderHeaderData: json.data.orderHeaderData,
            installType: json.data.installType,
            planName: json.data.orderDescription,
            orderStatus: json.data.orderStatus,
            orderSubStatus: json.data.orderSubStatus,
            showLogin: json.data.showLogin,
            contactUsUrl: json.data.contactUsUrl,
            showViewAllOrders: json.data.showViewAllOrders,
            internetConnectionDate: json.data.internetConnectionDate,
            orderSummaryDate: json.data.orderSummaryDate,
            orderSummaryDateLabel:json.data.orderSummaryDateLabel,
            changeDateLabel:json.data.changeDateLabel,
            changeDateURL:json.data.changeDateURL,
            hideExpectedConnectionDate: json.data.hideExpectedConnectionDate,
            isFoxtelAvailable: json.data.isFoxtelAvailable,
            queryOrderId: json.data.queryOrderId,
            loginEligibility: json.data.loginEligibility,
            milestones:json.data.milestoneTimelines,
            illustration: json.data.illustration,
            enableManageAppointments: json.data.enableManageAppointments,
            referenceCode: json.data.referenceCode,
            isErrorProvisioningOrder: json.data.isErrorProvisioningOrder ? json.data.isErrorProvisioningOrder : false
        },
        orderProcessedDate: json.data.orderCreatedDate,
        orderFlowSequence: json.data.orderFlowSequence,
        orderCompletionDate: json.data.completionDate,
        internetConnectionDate: json.data.internetConnectionDate,
        overallOrderHealth: json.data.overallOrderHealth,
        reasonForUnHealthy: json.data.reasonForUnHealthy,
        displayRules: json.data.displayRules,
        footer: json.data.footer,
        updatePlanTimeline:json.data.updatePlanTimeline ? json.data.updatePlanTimeline : null,
        transferRequestDetail:json.data.transferRequestDetail ? json.data.transferRequestDetail : null,
        numberTransferDetail: json.data.numberTransferDetail ? json.data.numberTransferDetail : null,
        modemPlugInDetail: json.data.modemPlugInDetail,
        iddPackDetail: json.data.iddPackDetail,
        showFooter: json.data.showFooter
    }

    if (json.data.showServiceUpgrade) {
        actionObj.serviceUpgradeDetail = {
            showServiceUpgrade: json.data.showServiceUpgrade,
            serviceUpgradeTimelineStatus: json.data.showServiceUpgradeTimeline.serviceUpgradeTimelineStatus,
            serviceUpgradeLabel: json.data.showServiceUpgradeTimeline.serviceUpgradeLabel,
            serviceUpgradeSubstatus: json.data.showServiceUpgradeTimeline.serviceUpgradeSubstatus,
            serviceUpgradeStatusMessage: json.data.showServiceUpgradeTimeline.serviceUpgradeStatusMessage || null,
            oldPlanText:json.data.showServiceUpgradeTimeline.oldPlanText,
            newPlanText:json.data.showServiceUpgradeTimeline.newPlanText
        }
    }

    if (json.data.showCHOWNTimeline) {
        actionObj.chownTimelineDetail = {
            showCHOWNTimeline: json.data.showCHOWNTimeline,
            chownTimelineLabel: json.data.chownTimelineLabel,
            chownTimelineStatus : json.data.chownTimelineStatus ,
            chownTimelineStatusLabel: json.data.chownTimelineStatusLabel,
            chownTimelineStatusSubLabel: json.data.chownTimelineStatusSubLabel,
            chownTimelineText:json.data.chownTimelineText,
            chownTimelineMessageICON:json.data.chownTimelineMessageICON,
            chownTimelineMessage:json.data.chownTimelineMessage
        }
    }

    if (json.data.nbnHealthCheckLinkLabel && json.data.nbnHealthCheckMessage) {
        actionObj.nbnHealthCheckDetail = {
            nbnHealthCheckLinkLabel: json.data.nbnHealthCheckLinkLabel,
            nbnHealthCheckMessage: json.data.nbnHealthCheckMessage,
            nbnHealthCheckUrl: json.data.nbnHealthCheckUrl
        }
    }

    if (json.data.guaranteeMessage && json.data.guaranteeLinkLabel) {
        actionObj.guaranteeMessageDetail = {
            guaranteeMessage: json.data.guaranteeMessage,
            guaranteeLinkLabel: json.data.guaranteeLinkLabel,
            guaranteeLinkURL: json.data.guaranteeLinkURL
        }
    }

    if (json.data.reasonForUnHealthyData) {

        actionObj.reasonForUnHealthyData = {
            unHealthyHeader: json.data.reasonForUnHealthyData.unHealthyHeader,
            unHealthyMessage: json.data.reasonForUnHealthyData.unHealthyMessage,
            unHealthyColorCode: json.data.reasonForUnHealthyData.unHealthyColorCode,
            unhealthyCustomAction: json.data.reasonForUnHealthyData.unhealthyCustomAction,
            unhealthyErrorCode: json.data.reasonForUnHealthyData.unhealthyErrorCode,
            unhealthyMessageUrl: json.data.reasonForUnHealthyData.unhealthyMessageUrl,
            unhealthyMessageMobileUrl: json.data.reasonForUnHealthyData.unhealthyMessageMobileUrl,
            contactUsUrl: json.data.reasonForUnHealthyData.contactUsUrl,
            bannerLinkText: json.data.reasonForUnHealthyData.bannerLinkText,
            bannerLinkMobileUrl: json.data.reasonForUnHealthyData.bannerLinkMobileUrl,
            chatGroupTags: json.data.reasonForUnHealthyData.chatGroupTags,
            bannerLinkUrl: json.data.reasonForUnHealthyData.bannerLinkUrl

        }
    }

    if (json.data.address) {
        actionObj.orderSummary.connectionAddress = {
            addressLine1: json.data.address.addressLine1,
            addressLine2: json.data.address.addressLine2,
            addressLine3: json.data.address.addressLine3
        }
    }

    if (json.data.shipmentDetails) {
        actionObj.hardwareDelivery = json.data.shipmentDetails;
        // selfInstallLink: selfInstallLink,
        actionObj.hardwareDelivery.map(function (element) {
            element.selfInstallLink = json.data.customerInstructionUrl ? json.data.customerInstructionUrl : '';
            return element;
        });
    }

    if (json.data.appointments) {
        let professionalInstallLink = telstraAppointmentProfessionalInstallLink(json);
        let appointments = json.data.appointments;
        let telstraApptList = [];
        appointments.map(function (appointmentElement) {
            const appointmentCategory = appointmentElement.appointmentCategory?.toLowerCase()
            if ([constants.ORDER_CATEGORY_NBN, constants.ORDER_CATEGORY_UNITI].includes(appointmentCategory)) {
                actionObj.nbnAppointment = appointmentElement;
                actionObj.nbnAppointment.selfInstallLink = nbnAppointmentCompleteSelfInstallLink(appointmentElement);;
                actionObj.nbnAppointment.nbnAppointmentLink = nbnAppointmentHowToPrepareForNbnAppointmentLink(appointmentElement);
                actionObj.nbnAppointment.isUniti = appointmentCategory == constants.ORDER_CATEGORY_UNITI
            } else if (
                appointmentElement.appointmentCategory &&
                appointmentElement.appointmentCategory.toLowerCase() === constants.APPT_CATEGORY_ASIK_TELSTRA
            ) {
                actionObj.asikTelstraAppointment = appointmentElement;
            } else {
                telstraApptList.push(appointmentElement);
            }
        });
        actionObj.telstraAppointment = telstraApptList;
        //  set professional link for all objects in the telstraAppointment array
        actionObj.telstraAppointment.map(function (element) {
            element.professionalInstallLink = professionalInstallLink;
            return element;
        });

    }

    if (json.data.orderTimeline) {
        actionObj.orderTimeline = json.data.orderTimeline;
    }

    if (json.data.disconnectionDetails) {
        actionObj.disconnect = {
            date: json.data.disconnectionDetails.date,
            status: json.data.disconnectionDetails.status,
            iconStatus: json.data.disconnectionDetails.iconStatus
        }
    }

    if (json.data.orderCategory && (json.data.orderCategory.toLowerCase() === constants.ORDER_CATEGORY_SEMI_SUPPORTED
        || json.data.orderCategory.toLowerCase().startsWith(constants.ORDER_CATEGORY_SEMI_SUPPORTED_NBNSIEBEL_PREFIX))) {
        actionObj.isSemiSupported = true;
    }

    if (json.data.footer) {
        actionObj.footer = json.data.footer;
    }
    console.log('FINAL ACTION OBJ', actionObj)
    return actionObj;
}

export function receiveOrderDetailsError(status, orderId=null) {
    console.log(status);
    switch (status) {
        case 401:
            return { type: RECEIVE_ORDER_DETAILS_TOKEN_EXPIRED, status }
        case 403:
            return { type: RECEIVE_ORDER_DETAILS_UNAUTHORIZED, status }
        case 500:
            return { type: RETRIEVE_ERRORS.DOWNSTREAM_ERROR, status }
        case 400:
        case 404:
        case 304:
            return { type: RECEIVE_ORDER_DETAILS_ERROR, status }
        case 422:
            return { type: RECEIVE_ORDER_DETAILS_UNSUPPORTED, status }
        default:
            return { type: RETRIEVE_ERRORS.DOWNSTREAM_ERROR, status }
    }
}

export function authTokenAvailable(accessToken) {
  return {
    type: AUTH_TOKEN_AVAILABLE,
    accessToken
  }
}

export function authTokenUnAvailable() {
  return {
    type: AUTH_TOKEN_UNAVAILABLE
  }
}

export function fetchAuthOrderDetails(orderNumber, accessToken, bypassCache, overrideAppointment, fetcher = client.fetchAuthOrderDetails) {
    return (dispatch) => {
        dispatch(requestOrderDetails());
        dispatch(authTokenAvailable(accessToken));
        fetcher(dispatch, receiveOrderDetails, receiveOrderDetailsError, orderNumber, accessToken, bypassCache, overrideAppointment);
    }
}

export function fetchUnauthOrderDetails(orderNumber,fetcher = client.fetchUnauthOrderDetails) {
    return (dispatch) => {
        dispatch(requestOrderDetails());
        dispatch(authTokenUnAvailable());
        fetcher(dispatch, receiveOrderDetails, receiveOrderDetailsError, orderNumber);
    }
}

export default function reducer(state = {}, action) {
    switch (action.type) {
        case AUTH_TOKEN_UNAVAILABLE:
          return Object.assign({}, state, {
            accessToken: null
          });
        case AUTH_TOKEN_AVAILABLE:
          return Object.assign({}, state, {
            accessToken: action.accessToken
          });
        case REQUEST_ORDER_DETAILS:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                isNotSupported: false,
                isExpired: false,
                isUnauthorized: false
            });
        case RECEIVE_ORDER_DETAILS_ENERGY:
            const { meta, page, banner, header, timelineGroups, footers, referenceCode } = action.payload;
            return {
                ...state,
                isFetching: false,
                isEnergyOrder: true,
                meta,
                page,
                banner,
                header,
                timelineGroups,
                footers,
                referenceCode
            }
        case RECEIVE_ORDER_DETAILS_DYNAMIC:
            return {
                ...state,
                isFetching: false,
                isEnergyOrder: false,
                isDynamicOrder: true,
                meta: action.payload.meta,
                page: action.payload.page,
                banner: action.payload.banner,
                header: action.payload.header,
                timelineGroups: action.payload.timelineGroupsV2,
                footers: action.payload.footers,
                referenceCode: action.payload.referenceCode
            }
        case RECEIVE_ORDER_DETAILS:
            return Object.assign({}, state, {
                isFetching: false,
                orderSummary: action.orderSummary,
                hardwareDelivery: action.hardwareDelivery,
                telstraAppointment: action.telstraAppointment,
                orderProcessedDate: action.orderProcessedDate,
                nbnAppointment: action.nbnAppointment,
                asikTelstraAppointment: action.asikTelstraAppointment,
                orderFlowSequence: action.orderFlowSequence,
                orderCompletionDate: action.orderCompletionDate,
                internetConnectionDate: action.internetConnectionDate,
                overallOrderHealth: action.overallOrderHealth,
                reasonForUnHealthy: action.reasonForUnHealthy,
                nbnHealthCheckDetail: action.nbnHealthCheckDetail,
                guaranteeMessageDetail: action.guaranteeMessageDetail,
                reasonForUnHealthyData: action.reasonForUnHealthyData,
                orderTimeline: action.orderTimeline,
                disconnect: action.disconnect,
                orderCategory: action.orderSummary.orderCategory,
                technologyType: action.orderSummary.technologyType,
                orderStatus: action.orderSummary.orderStatus,
                orderSubStatus: action.orderSummary.orderSubStatus,
                showLogin: action.orderSummary.showLogin,
                contactUsUrl: action.orderSummary.contactUsUrl,
                showViewAllOrders: action.orderSummary.showViewAllOrders,
                footer:action.footer,
                isSemiSupported: action.isSemiSupported ? action.isSemiSupported : null,
                loginEligibility: action.orderSummary.loginEligibility,
                displayRules: action.displayRules,
                updatePlanTimeline:action.updatePlanTimeline ? action.updatePlanTimeline: null,
                transferRequestDetail: action.transferRequestDetail ? action.transferRequestDetail : null,
                numberTransferDetail: action.numberTransferDetail ? action.numberTransferDetail : null,
                modemPlugInDetail: action.modemPlugInDetail,
                serviceUpgradeDetail: action.serviceUpgradeDetail,
                iddPackDetail: action.iddPackDetail,
                showFooter: action.showFooter,
                chownTimelineDetail: action.chownTimelineDetail

            });
        case RECEIVE_ORDER_DETAILS_ERROR:
        case RETRIEVE_ERRORS.DOWNSTREAM_ERROR:
        case RETRIEVE_ERRORS.NOT_FOUND_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                showLogin: true,
                showViewAllOrders: true,
                isNotSupported: false,
                isUnauthorized: false,
                // isUnauthorized: true,
                orderId: action.orderId,
                errorCode: action.status
            });
        case RECEIVE_ORDER_DETAILS_UNSUPPORTED:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                showLogin: true,
                showViewAllOrders: true,
                isNotSupported: true,
                isUnauthorized: false,
                errorCode: action.status
            });
        case RECEIVE_ORDER_DETAILS_TOKEN_EXPIRED:
            return Object.assign({}, state, {
                isFetching: false,
                isExpired: true,
                isUnauthorized: false
            });
        case RECEIVE_ORDER_DETAILS_UNAUTHORIZED:
            console.log('action', action);
            return Object.assign({}, state, {
                isFetching: false,
                isExpired: false,                
                isUnauthorized: true,
                orderId: action.orderId,
                errorCode: action.status
            });
        default:
            return state
    }
}

//OST-177 AC1
export function nbnAppointmentCompleteSelfInstallLink(json) {
    return '';
}

//OST-177 AC2
// export function shipmentDetailsSelfInstallLink(json){
//     return selfInstallLink(json);
// }

//OST-124 AC1, AC2
export function nbnAppointmentHowToPrepareForNbnAppointmentLink(appointmentElement) {
    let isNbnAppointment = appointmentElement && appointmentElement.date;
    if (isNbnAppointment) {
        return 'https://www.telstra.com.au/support/category/broadband/nbn/what-happens-when-the-nbn-co-technician-comes-to-set-up-my-nbn-connection';
    }
    return '';
}

export function telstraAppointmentProfessionalInstallLink(json) {
    if (json.data.installType &&
        json.data.installType.toUpperCase() === 'PIK') {
        if (json.data.orderCategory && json.data.orderCategory.toLowerCase() === constants.PSTN) {
            return 'https://www.telstra.com.au/support/category/broadband/adsl-cable-velocity/professional-installation';
        }
        else {
            return 'https://www.telstra.com.au/support/category/broadband/other/about-the-technician-installing-your-telstra-home-broadband';
        }
    }
    return '';
}

